<!-- Assigned Template -->
<dx-popup (onHidden)="this.onHidden()"
          [closeOnOutsideClick]="false"
          [dragEnabled]="true"
          [height]="380"
          [resizeEnabled]="true"
          [showTitle]="true"
          [visible]="this.visible"
          [width]="480"
          title="Assign Templates"
>
  <div #assignTemplatesContainer *dxTemplate="let data of 'content'" class="assign-templates">
    
    <div class="body">
      <div class="col templates">
        <h1 class="header">Templates</h1>
        <div class="section">
          <dx-scroll-view>
            <div class="template-list">
              <div class="template" *ngFor="let tpl of this.templateList">
                <div (click)="this.setSelectedTemplate(tpl)" [class.selected]=" tpl === this.selectedTemplate ">
                  {{ tpl.templateName }}
                </div>
              </div>
            </div>
          </dx-scroll-view>
        </div>
      </div>
      <div class="col clients">
        <h1 class="header">Clients</h1>
        <div class="section">
          <dx-scroll-view>
            <div class="client-list">
              <div class="client" *ngFor="let clnt of this.selectedTemplate?.clients">
                <div class="checkbox">
                  <dx-check-box [(value)]="clnt.selected"></dx-check-box>
                </div>
                <div class="name">
                  {{ clnt.clientName }}
                </div>
              </div>
            </div>
          </dx-scroll-view>
        </div>
      </div>
    </div>
    
    <div class="footer">
      <div class="btn">
        <dx-button
          (onClick)="this.saveChanges()"
          width="100%"
          text="Save Changes">
        </dx-button>
      </div>
      <div class="btn">
        <dx-button width="100%" (onClick)="this.cancelChanges()" text="Cancel"></dx-button>
      </div>
    </div>
    
    <dx-load-panel
      [position]="{my: 'center', at: 'center', of: assignTemplatesContainer}"
      [visible]="this.isLoading">
    </dx-load-panel>
  
  </div>
</dx-popup>
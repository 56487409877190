import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    FullAdjustmentsList,
    PackageComparisonDto,
    PackageComparisonModel
} from "./model/PackageComparisonModel";
import {
    DetectMethodChanges, DetectSetterChanges,
    DxValueChanged, isApgPortfolioSetting,
    isOptionExpired,
    isValidNumber,
    isVoid,
    makeFullExpirationDate,
    makeGuiFriendlyExpirationDateDte, wrapInPromise
} from "../utils";
import {CashFlowPortfoliosService} from "../adjustment-pricing-grid/services/cashflow-portfolios.service";
import {ServiceConfiguration} from "../adjustment-pricing-grid/services/ServiceConfiguration";
import {SessionService} from "../authentication/session-service.service";
import {ApgPortfolio} from "../adjustment-pricing-grid/model/ApgPortfolio";
import {UserDto} from "../authentication/dtos/auth-result-dto.inteface";
import {
    CashFlowStrategyTemplatesService
} from "../adjustment-pricing-grid/services/cashflow-strategy-templates.service";
import {
    ICashFlowAdjustmentSettingsTemplate
} from "../adjustment-pricing-grid/model/ICashFlowAdjustmentSettingsTemplate";
import {ShellClientService} from "../shell-communication/shell-client.service";
import {
    CalculatePackageComparison, CalculatePackageComparisonBulk,
    CalculatePackageComparisonResult,
    CalculateRollDates,
    CalculateRollDatesReply
} from "../shell-communication/shell-operations-protocol";
import {
    OpgTemplate,
    OptionPricingGridTemplatesService
} from "../options-pricing-grid/option-pricing-grid-templates.service";
import * as Enumerable from "linq";
import {GenericConfirmationDialogComponent} from "../generic-confirmation-dialog/generic-confirmation-dialog.component";
import {PackageComparisonSettingsComponent} from "./settings/package-comparison-settings.component";
import {
    HedgeBoardSettings,
    PackageComparisonSettings,
    PackageComparisonSettingsService
} from "./settings/package-comparison-settings.service";
import {ToastrService} from "ngx-toastr";
import {ApplicationSettingsService, AppSettingsUpdatedMessageTopic} from "../app-settings/application-settings.service";
import {QuoteDto} from "../shell-communication/dtos/quote-dto.class";
import {StrategyPriceDto} from "../shell-communication/shell-dto-protocol";
import {LastQuoteCacheService} from "../last-quote-cache.service";
import {MessageBusService} from "../message-bus.service";
import {
    AdjustmentSolutionPopupComponent
} from "../adjustment-pricing-grid/solution-popup/adjustment-solution-popup.component";
import {CopyOrdersToService} from "../adjustment-pricing-grid/services/copy-orders-to.service";
import {PositionsAfterStateService} from "../adjustment-pricing-grid/services/positions-after-state.service";
import {QuoteIndex} from "../adjustment-pricing-grid/model/QuoteIndex";
import {StrategyCodeIndex} from "../adjustment-pricing-grid/model/StrategyCodeIndex";
import {HedgePositionsService} from "../hedging-grid/positions-section/hedge-positions/hedge-positions.service";
import {HgTransactionPopupComponent} from "../hedging-grid/transaction-popup/hg-transaction-popup.component";
import {AtmStrikeService} from "../common-services/atm-strike-service/atm-strike.service";
import {interval, Observable, Observer, Subscription} from "rxjs";
import {BeforePositionDto} from "../adjustment-pricing-grid/model/BeforePositionDto";
import {parseOptionTicker} from "../options-common/options.model";
import {DashboardComparisonModel} from "./model/DashboardComparisonModel";
import {RowFilter} from "./model/row.filter";
import {SortingAttribute} from "./model/sorting.attribute";
import {SortingRule} from "./model/sorting.rule";
import {DashboardViewModelGroup} from "./model/dashboard-view-model.group";
import {RowFilterAttribute} from "./model/RowFilterAttribute";
import {DashboardFilterConfig, DashboardFilterItem} from "./dashboard-filter-popup/DashboardFilterConfig";
import {DashboardFilterPopupComponent} from "./dashboard-filter-popup/dashboard-filter-popup.component";
import {HedgesPricingService} from "./services/hedges-pricing.service";
import {PricingGridStrategyDescriptor} from "../options-pricing-grid/model/pricing-grid-strategy.descriptor";
import {UserSettingObject, UserSettingsService} from "../user-settings.service";
import {filter, takeUntil} from "rxjs/operators";
import {PackageComparisonFiltersService} from "./package-comparison-filters.service";
import {PackageComparisonSortingService} from "./package-comparison-sorting.service";
import {ApgDataService} from "../adjustment-pricing-grid/services/apg-data.service";
import {PackageMovingFloaterComponent} from "./package-moving-floater/package-moving-floater.component";
import {AccessControlService} from "../access-control-service.class";
import {HedgeBoardComparisonModel} from "./hedge-board/HedgeBoardComparisonModel";
import {HedgeBoardViewModelGroup} from "./hedge-board/HedgeBoardViewModelGroup";
import {offsetSegment} from "@angular/compiler-cli/src/ngtsc/sourcemaps/src/segment_marker";
import {CashFlowAdjustmentLogicalId} from "../adjustment-control-panel/CashFlowAdjustmentId";
import {HedgeBoardPinnedService} from "./hedge-board/hedge-board-pinned.service";
import {OverrideAtmDialogComponent} from "../override-atm-dialog/override-atm-dialog.component";
import {PackageNameEditorComponent} from "./name-editor/package-name-editor.component";

export const PackagesStorageKey: string = 'pkg-cmprsn.packages';
export const DashboardPinnedKey: string = 'pkg-cmprsn.dashboard.pinned';

interface MiscSettings {
    atm?: number;
    numOfExp?: number;
}

@Component({
    selector: 'ets-package-comparison',
    templateUrl: 'package-comparison.component.html',
    styleUrls: ['package-comparison.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [HedgePositionsService]
})

export class PackageComparisonComponent implements OnInit, OnDestroy {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _portfolioService: CashFlowPortfoliosService,
        private readonly _sessionService: SessionService,
        private readonly _cashflowTplService: CashFlowStrategyTemplatesService,
        private readonly _shellService: ShellClientService,
        private readonly _opgTemplatesService: OptionPricingGridTemplatesService,
        private readonly _pkgCmprsnSettings: PackageComparisonSettingsService,
        private readonly _toastr: ToastrService,
        private readonly _applicationSettingsService: ApplicationSettingsService,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _messageBus: MessageBusService,
        private readonly _atmStrikeService: AtmStrikeService,
        private readonly _copyOrdersToService: CopyOrdersToService,
        private readonly _positionsAfterStateService: PositionsAfterStateService,
        private readonly _hedgePositionsService: HedgePositionsService,
        private readonly _userSettingsService: UserSettingsService,
        private readonly _filtersService: PackageComparisonFiltersService,
        private readonly _sortingService: PackageComparisonSortingService,
        private readonly _apgDataService: ApgDataService,
        private readonly _accessControlService: AccessControlService,
        private readonly _pinnedHedgeBoardService: HedgeBoardPinnedService
    ) {

        this._quoteIndex = new QuoteIndex(this._lastQuoteCache);
        this._strategyCodeIndex = new StrategyCodeIndex(this._lastQuoteCache);

        this._dashboardQuoteIndex = new QuoteIndex(this._lastQuoteCache);
        this._dashboardStrategyCodeIndex = new StrategyCodeIndex(this._lastQuoteCache);

        this._hedgeBoardQuoteIndex = new QuoteIndex(this._lastQuoteCache);
        this._hedgeBoardStrategyCodeIndex = new StrategyCodeIndex(this._lastQuoteCache);

        this._hedgesPricingService = new HedgesPricingService(
            _hedgePositionsService,
            _lastQuoteCache,
            _messageBus,
            _apgDataService
        );

        this._filtersService.filtersChanged$.subscribe(x => {
            this.onFiltersChanged();
        });

        this._sortingService.sortingChanged$.subscribe(x => {
            this.onSortingChanged();
        });

    }

    private _subscriptions: Subscription[] = [];

    private _lastAppliedState: { [ix: string]: string } = {};

    private _miscSettingsState: MiscSettings = {};

    private _expirationsCache: { qry: CalculateRollDates, expirations: any[] }[] = [];

    private _quoteIndex: QuoteIndex;

    private _strategyCodeIndex: StrategyCodeIndex;

    private _dashboardQuoteIndex: QuoteIndex;

    private _dashboardStrategyCodeIndex: StrategyCodeIndex;

    private _hedgeBoardQuoteIndex: QuoteIndex;

    private _hedgeBoardStrategyCodeIndex: StrategyCodeIndex;

    private readonly _hedgesPricingService: HedgesPricingService;

    private _unappliedHedgeBoardSettings = false;

    private _loadingCounter = 0;

    get isLoading(): boolean {
        return this._loadingCounter > 0;
    }

    @DetectSetterChanges()
    set isLoading(value: boolean) {
        if (value) {
            this._loadingCounter += 1;
        } else {
            this._loadingCounter -= 1;
        }
    }

    get isDashboard() : boolean {
        return this.mode === 'dashboard';
    }

    get isHedgeBoard() :boolean {
        return this.mode === 'hedgeboard';
    }

    get isPackagesView() : boolean {
        return this.mode === 'packages';
    }

    mode : 'packages' | 'dashboard' | 'hedgeboard' = 'packages';



    get isDashboardEmpty() : boolean {
        return this.isDashboard && isVoid(this.packageComparisons);
    }

    get filteredPackages(): PackageComparisonModel[] {

        let originalPackages = this.packageComparisons
            .slice();

        let packages = originalPackages;

        if (!isVoid(this.rowFilters)) {
            this.rowFilters.forEach(f => {
                switch (f.attribute) {
                    case "portfolio":
                        packages = packages
                            .filter(x => {
                                const key = x.portfolio?.userId + x.portfolio?.id;
                                return key === f.value;
                            });
                        break;
                    case "template":
                        packages = packages.filter(x => x.template?.templateId === f.value);
                        break;
                    case "expiration":
                        packages = packages.filter(x => x.expiration?.seqNo === f.value);
                        break;
                    case "adj_call":
                        packages = packages.filter(x => x.adjustmentOne?.name === f.value);
                        break;
                    case "adj_put":
                        packages = packages.filter(x => x.adjustmentTwo?.name === f.value);
                        break;
                    case 'adjustment':
                        packages = packages.filter(x => {
                            const parts = f.value.split('+').map(x => x.trim() );

                            if (!isVoid(parts) && parts.length == 2) {

                                const callAdj = parts[0];
                                const putAdj = parts[1];

                                const adj1 = callAdj.startsWith(x.adjustmentOne?.name);
                                const adj2 = putAdj.startsWith(x.adjustmentTwo?.name);

                                return adj1 && adj2;
                            }
                        });
                        break;
                    case "hedge_call":
                        packages = packages.filter(x => x.callHedge?.strategyId === f.value);
                        break;
                    case "hedge_put":
                        packages = packages.filter(x => x.putHedge?.strategyId === f.value);
                        break;
                    case "hedge_call_family":
                        packages = packages.filter(x => x.callHedge?.templateId === f.value);
                        break;
                    case "hedge_put_family":
                        packages = packages.filter(x => x.putHedge?.templateId === f.value);
                        break;
                    case "offset_call":
                        packages = packages.filter(x => x.callHedgeOffset === f.value);
                        break;
                    case "offset_put":
                        packages = packages.filter(x => x.putHedgeOffset === f.value);
                        break;
                }
            });
        }

        if (!isVoid(this.sortingRules)) {
            if (packages.length > 1) {
                let enumerable = Enumerable.from(packages);
                for (let sortingRule of this.sortingRules) {
                    switch (sortingRule.attribute) {
                        case "portfolio":
                            enumerable = sortingRule.order === 'asc'
                                ? enumerable.orderBy(x => x.portfolio?.name)
                                : enumerable.orderByDescending(x => x.portfolio?.name);
                            break;
                        case "template":
                            enumerable = sortingRule.order === 'asc'
                                ? enumerable.orderBy(x => x.template?.templateName)
                                : enumerable.orderByDescending(x => x.template?.templateName);
                            break;
                        case "hedge_call":
                            enumerable = sortingRule.order === 'asc'
                                ? enumerable.orderBy(x => x.getCallHedgePrice())
                                : enumerable.orderByDescending(x => x.getCallHedgePrice());
                            break;
                        case "hedge_put":
                            enumerable = sortingRule.order === 'asc'
                                ? enumerable.orderBy(x => x.getPutHedgePrice())
                                : enumerable.orderByDescending(x => x.getPutHedgePrice());
                            break;
                        case "total":
                            enumerable = sortingRule.order === 'asc'
                                ? enumerable.orderBy(x => x.getPackagePrice() || Number.MAX_SAFE_INTEGER)
                                : enumerable.orderByDescending(x => x.getPackagePrice() || Number.MIN_SAFE_INTEGER);
                            break;
                        case "expiration":
                            enumerable = sortingRule.order === 'asc'
                                ? enumerable.orderBy(x => x.expiration?.seqNo)
                                : enumerable.orderByDescending(x => x.expiration?.seqNo);
                            break;
                        case "adjustment":
                            enumerable = sortingRule.order === 'asc'
                                ? enumerable.orderBy(x => x.getAdjustmentsPrice() || Number.MAX_SAFE_INTEGER)
                                : enumerable.orderByDescending(x => x.getAdjustmentsPrice() || Number.MIN_SAFE_INTEGER);
                            break;
                        case "offset_call":
                            enumerable = sortingRule.order === 'asc'
                                ? enumerable.orderBy(x => x.callHedgeOffset || Number.MAX_SAFE_INTEGER)
                                : enumerable.orderByDescending(x => x.callHedgeOffset || Number.MIN_SAFE_INTEGER);
                            break;
                        case "offset_put":
                            enumerable = sortingRule.order === 'asc'
                                ? enumerable.orderBy(x => x.putHedgeOffset || Number.MAX_SAFE_INTEGER)
                                : enumerable.orderByDescending(x => x.putHedgeOffset || Number.MIN_SAFE_INTEGER);
                            break;
                    }
                }
                packages = enumerable.toArray();
            }
        }

        packages = packages.sort((a, b) => {
            const aPin = a?.isPinned ? 1 : 0;
            const bPin = b?.isPinned ? 1 : 0;
            return bPin - aPin;
        });

        if (packages.length === 0) {
            return originalPackages;
        }

        return packages;
    }

    get showClientsSelector(): boolean {
        return this._sessionService.isSuperUser;
    }

    portfolioList: { key: string, userId: string, items: ApgPortfolio[] }[];

    clientList: UserDto[] = [];

    selectedClient: UserDto;

    hedgeBoardPackages : HedgeBoardComparisonModel[] = [];

    invertHedgeBoardPrices = false;

    private _hedgeBoardViewModelGroups : HedgeBoardViewModelGroup[];

    get hedgeBoardViewModelGroups() : HedgeBoardViewModelGroup[] {

        if (this.hedgeBoardPackages.length === 0) {
            return [];
        }

        if (isVoid(this._hedgeBoardViewModelGroups)) {
            const groups = Enumerable
                .from(this.hedgeBoardPackages)
                .groupBy(x => `${x.portfolio.id}${x.portfolio.userId}${x.template.templateId}`)
                .select(x => {
                    const isInverted = () => this.invertHedgeBoardPrices;
                    const gvm = new HedgeBoardViewModelGroup(this._changeDetector, isInverted);
                    gvm.pinnedChanged$.subscribe(() => this.onHedgeBoardPinnedChanged());
                    const calls = x.where(x => x.isCalls).toArray();
                    const puts = x.where(x => !x.isCalls).toArray();
                    calls.forEach(x => x.isPinned = this._pinnedHedgeBoardService.isPinned(x.uniqueId));
                    puts.forEach(x => x.isPinned = this._pinnedHedgeBoardService.isPinned(x.uniqueId));
                    gvm.calls = calls;
                    gvm.puts = puts;
                    return gvm;
                }).toArray();

            this._hedgeBoardViewModelGroups = groups;
        }

        return this._hedgeBoardViewModelGroups;
    }

    dashboardPackages: DashboardComparisonModel[] = [];

    private _dashboardPackagesViewModelGroups: DashboardViewModelGroup[];
    get dashboardPackagesViewModelGroups(): DashboardViewModelGroup[] {

        if (isVoid(this._dashboardPackagesViewModelGroups)) {
            const groups = Enumerable
                .from(this.dashboardPackages)
                .groupBy(x => x.startDate)
                .select(grp => {
                    return {
                        key: grp.key(),
                        items: grp.toArray()
                    };
                }).orderBy(x => x.key).toArray();

            const dashboardViewModelGroups = groups
                .map(x => new DashboardViewModelGroup(
                    this._changeDetector,
                    x.key,
                    this._toastr,
                    this._hedgesPricingService,
                    x.items,
                    this._filtersService,
                    this._sortingService
                ));

            this._dashboardPackagesViewModelGroups = dashboardViewModelGroups;
        }

        return this._dashboardPackagesViewModelGroups;
    }

    dashboardExpirations = 5;

    packageComparisons: PackageComparisonModel[] = [];

    @ViewChild(GenericConfirmationDialogComponent)
    confirmationDlg: GenericConfirmationDialogComponent;

    @ViewChild(PackageComparisonSettingsComponent)
    settingsCmp: PackageComparisonSettingsComponent;

    @ViewChild(AdjustmentSolutionPopupComponent)
    solutionPopup: AdjustmentSolutionPopupComponent;

    @ViewChild(HgTransactionPopupComponent)
    hedgesPopup: HgTransactionPopupComponent;

    @ViewChild(DashboardFilterPopupComponent)
    dashboardFilterDialog: DashboardFilterPopupComponent;

    @ViewChild(PackageMovingFloaterComponent)
    packageMovingFloater: PackageMovingFloaterComponent;

    @ViewChild(OverrideAtmDialogComponent)
    overrideAtmDialog : OverrideAtmDialogComponent;

    get sortingRules() : ReadonlyArray<SortingRule>  {
        return this._sortingService.sortingRules;
    };

    get rowFilters() : ReadonlyArray<RowFilter> {
        return this._filtersService.filters;
    }

    canShowFloater() {
        return this._accessControlService.isSecureElementAvailable('c3f43637-3004-4fed-ba94-4ff6cf9451b9');
    }

    canShowHedgesDashboard() : boolean {
        return this._accessControlService.isSecureElementAvailable('a1615263-e886-4fc7-a2ca-e235e3fcdcd8');
    }

    hasFloaterDistance = new Observable<boolean>((obs: Observer<boolean>) => {
        setInterval(async () => {
            const selectedPortfolio = this.packageMovingFloater.selectedPortfolio;
            const packageComparisonModel = this.packageComparisons.find(x => x.portfolio === selectedPortfolio);
            if (isVoid(packageComparisonModel)) {
                obs.next(false);
                return;
            }

            if (isVoid(packageComparisonModel.cashFlowAdjustments)) {
                obs.next(false);
                return;
            }
            const shortOption = packageComparisonModel.cashFlowAdjustments[0].beforeState.find(x => x.role === 'ShortOption');
            if (isVoid(shortOption)) {
                obs.next(false);
                return;
            }
            const soTicker = parseOptionTicker(shortOption.ticker);
            if (isVoid(soTicker)) {
                obs.next(false);
                return;
            }
            const pfSoTicker = await this._apgDataService.getShortOptionTicker(selectedPortfolio);
            if (isVoid(pfSoTicker)) {
                obs.next(false);
                return;
            }
            const has = pfSoTicker?.strike !== soTicker?.strike;
            obs.next(has);
            this._changeDetector.detectChanges();
        }, 250);
    });

    overrideAtm: number;

    isLiveMode = false;

    get isPriceAdjusterActiveInHedgeBoard() : boolean {
        return this.isHedgeBoard && (this.packageMovingFloater?.visible ||
            isValidNumber(this.packageMovingFloater?.distance, true));
    }

    get viewSwitchButtonText(): string {
        if (!this.isPackagesView) {
            return 'Packages';
        }
        return 'Dashboard';
    }

    get overrideAtmStyle() : string {
        let style = 'text-align: center;';

        if (isValidNumber(this.overrideAtm)) {
            return style;
        }

        const mode = this._applicationSettingsService.adjustmentPricingGrid.atmWarningMode;

        if (mode !== 'Yellow Background') {
            return style;
        }

        style += 'background: yellow; color: black';

        return style;
    }

    @ViewChild(PackageNameEditorComponent)
    nameEditorDialog: PackageNameEditorComponent;

    @DetectMethodChanges()
    async ngOnInit() {

        await this.initPortfolioService();

        await this.afterPortfolioServiceInitialized();

        this._subscriptions.push(
            this._messageBus.of<QuoteDto[]>('QuoteDto')
                .subscribe(msg => this.onQuote(msg.payload))
        );

        this._subscriptions.push(
            this._messageBus.of<StrategyPriceDto[]>('StrategyPriceDto')
                .subscribe(msg => this.onStrategyPrice(msg.payload))
        );

        this._subscriptions.push(
            this._messageBus.of<CalculatePackageComparisonResult>('CalculatePackageComparisonResult')
                .subscribe(msg => this.onCalculatePackageComparisonResult(msg.payload))
        );

        this._subscriptions.push(
            this._messageBus.of<any>('Hg.SaveAfterState')
                .subscribe(msg => this.onHgSaveAfterState(msg.payload))
        );

        this._subscriptions.push(
            this._pkgCmprsnSettings.settingsUpdated
                .subscribe(data => this.onSettingsUpdated(data))
        );

        this._subscriptions.push(
            this._pkgCmprsnSettings.hedgeboardSettingsUpdated
                .subscribe(data => this.onHedgeBoardSettingsUpdated(data))
        );

        this._subscriptions.push(
            this._messageBus.of<any>('Opg.TemplatesChanged')
                .subscribe((data) => this.onHedgesUpdated(data.payload))
        );

        this._subscriptions.push(
            this._messageBus.of('Apg.TemplatesChanged')
                .subscribe(x => this.onStrategyTemplatesUpdated())
        );

        this._subscriptions.push(
            this._hedgesPricingService.hedgePriceChanged$.subscribe(() => this._changeDetector.detectChanges())
        );

        this._subscriptions.push(
            this._userSettingsService.userSettingChanged$
                .pipe(
                    filter(x => isApgPortfolioSetting(x.key))
                )
                .subscribe(x => this.onUserSettingsChanged(x))
        );

        this._subscriptions.push(
            this._messageBus.of<{portfolios: ApgPortfolio[]}>('Apg.PortfoliosUpdated')
                .subscribe((msg) => this.onPortfoliosUpdated(msg.payload))
        );

        this._subscriptions.push(
            interval(1000).subscribe(() => {
                if (this.isDashboard) {
                    if (!isVoid(this.dashboardPackagesViewModelGroups)) {
                        this.dashboardPackagesViewModelGroups.forEach(x => x.resetSorting());
                    }
                }
            })
        );

        this._subscriptions.push(
            this._messageBus.of(AppSettingsUpdatedMessageTopic)
                .subscribe(_ => this._changeDetector.detectChanges())
        );

        // Configure APG related services that will affect APG positions state
        const config: ServiceConfiguration = {
            userId: this._sessionService.sessionData.userId,
            userName: this._sessionService.sessionData.userEmail,
            orientation: undefined
        };

        this._copyOrdersToService.configure(config);

        this._positionsAfterStateService.configure(config);

        this.packageMovingFloater.closed$.subscribe(() => {
            this.invertHedgeBoardPrices = false;
        });
    }

    ngOnDestroy() {
        this._hedgesPricingService.dispose();
        this._subscriptions.forEach(x => x.unsubscribe());
        this._quoteIndex.reset();
        this._strategyCodeIndex.reset();
    }

    @DetectMethodChanges()
    addPackageComparison(model?: PackageComparisonModel) {
        if (isVoid(model)) {
            model = new PackageComparisonModel();
        } else {
            model = model.clone();
        }

        this.packageComparisons.push(model);
    }

    getPnLCssClass(value: number) {

        if (value === Number.NEGATIVE_INFINITY) {
            return 'debit';
        }


        if (!isValidNumber(value, true)) {
            return undefined;
        }

        if (value > 0) {
            return 'credit';
        }

        return 'debit';
    }


    @DetectMethodChanges({isAsync: true})
    async onPortfolioSelected(event: DxValueChanged<ApgPortfolio>, pkg: PackageComparisonModel, isMulti?: boolean) {

        if (isVoid(event) || isVoid(event.value)) {

            pkg.templateList = [];
            pkg.callHedgeList = [];
            pkg.putHedgeList = [];
            pkg.template = undefined;
            pkg.callHedge = undefined;
            pkg.putHedge = undefined;

            return;
        }

        if (isVoid(event.event)) {
            return;
        }

        this.isLoading = true;

        try {

            const defaultQty = await this._apgDataService.getDefaultQtyForPortfolio(event.value);
            pkg.setPortfolio(event.value, defaultQty);

            await this.setAvailableTemplates(pkg);
            this.setAvailableAdjustments(pkg);
            await this.setAvailableCallHedges(pkg);
            await this.setAvailablePutHedges(pkg);
            await this.setShortOptionTickerOnPackage(pkg);
            if (!isMulti) {
                const items = this.inspectPackageForIncorrectFilters(pkg);
                this.showErrorMessageForMissingItems(items);
            }
        } finally {
            await this.applyLiveModeIfPossible();
            this.isLoading = false;
        }

    }

    private async setShortOptionTickerOnPackage(pkg: PackageComparisonModel) {
        const soTicker = await this._apgDataService.getShortOptionTicker(pkg.portfolio);
        pkg.shortOptionStrike = (soTicker?.strike + '') || 'N/A';
    }

    private async setAvailablePutHedges(pkg: PackageComparisonModel, refresh?: true) {
        const userId = pkg.portfolio?.userId;

        if (isVoid(userId)) {
            return;
        }

        const filters = this._pkgCmprsnSettings.get(pkg.portfolio);

        const underlying = await this._apgDataService
            .getUnderlyingOfPortfolio(pkg.portfolio);

        const putHedgeTemplates = this._opgTemplatesService
            .getPutTemplates(userId)
            .filter(x => x.underlying === underlying);

        const putGroups = Enumerable.from(putHedgeTemplates)
            .where(tpl => {
                const putHedgeFilters = filters?.hedgesPut || [];
                const ix = tpl.descriptors
                    .findIndex(d => putHedgeFilters.indexOf(d.strategyId) >= 0);

                return ix >= 0;
            })
            .groupBy(x => x.templateName)
            .select(x => {
                const key = x.key();
                const items = x
                    .selectMany(y => y.descriptors)
                    .where(d => {
                        const putHedgeFilters = filters?.hedgesPut;

                        if (isVoid(putHedgeFilters)) {
                            return true;
                        }

                        const ix = putHedgeFilters.indexOf(d.strategyId);
                        return ix >= 0;
                    })
                    .toArray();

                return {
                    key,
                    items
                };
            }).toArray();

        pkg.putHedgeList = putGroups as any;

        if (isVoid(pkg.putHedge)) {
            return;
        }

        const existingHedge = pkg.putHedgeList
            .flatMap(x => x.items)
            .find(x => x.strategyId === pkg.putHedge.strategyId);
        pkg.putHedge = existingHedge;
    }

    private async setAvailableCallHedges(pkg: PackageComparisonModel, refresh?: true) {
        const userId = pkg.portfolio?.userId;

        if (isVoid(userId)) {
            return;
        }

        const filters = this._pkgCmprsnSettings.get(pkg.portfolio);

        const underlying = await this._apgDataService
            .getUnderlyingOfPortfolio(pkg.portfolio);

        const callHedgeTemplates = this._opgTemplatesService
            .getCallTemplates(userId)
            .filter(x => x.underlying === underlying);

        const callGroups = Enumerable.from(callHedgeTemplates)
            .where(tpl => {
                const callHedgeFilters = filters?.hedgesCall || [];
                const ix = tpl.descriptors
                    .findIndex(d => callHedgeFilters.indexOf(d.strategyId) >= 0);

                return ix >= 0;
            })
            .groupBy(x => x.templateName)
            .select(x => {

                const key = x.key();

                const items: PricingGridStrategyDescriptor[] = x
                    .selectMany(y => y.descriptors)
                    .where(d => {
                        const callHedgeFilters = filters?.hedgesCall;

                        if (isVoid(callHedgeFilters)) {
                            return true;
                        }

                        const ix = callHedgeFilters.indexOf(d.strategyId);
                        return ix >= 0;
                    })
                    .toArray();

                return {
                    key,
                    items
                };
            }).toArray();

        pkg.callHedgeList = callGroups as any;

        if (isVoid(pkg.callHedge)) {
            return;
        }

        const existingHedge = pkg.callHedgeList
            .flatMap(x => x.items)
            .find(x => x.strategyId === pkg.callHedge.strategyId);
        pkg.callHedge = existingHedge;
    }

    private async setAvailableTemplates(pkg: PackageComparisonModel) {
        const portfolio = pkg.portfolio;

        if (isVoid(portfolio)) {
            return;
        }

        const userDto = this
            .getUserObject(portfolio.userId);

        const cfg: ServiceConfiguration = {
            userId: userDto.userId,
            userName: userDto.userName,
            orientation: null
        };

        await this._cashflowTplService.configure(cfg);

        let templates: ICashFlowAdjustmentSettingsTemplate[] = [];

        const lut = this._cashflowTplService.getLastUsedTemplate(portfolio.id);
        const defaultTemplate = this._cashflowTplService.getOrCreateDefaultTemplate(
            lut.underlying,
            lut.strategy
        );
        templates = this._cashflowTplService
            .getTemplates()
            .filter(tpl => tpl.underlying === lut?.underlying);
        if (templates.findIndex(x => x.templateId.startsWith('----')) < 0) {
            templates.push(defaultTemplate);
        }

        this._atmStrikeService.watch(lut.underlying);

        const filters = this._pkgCmprsnSettings.get(pkg.portfolio);

        templates = Enumerable.from(templates)
            .where(x => {
                const tplFilters = filters?.templates || [];
                return tplFilters.indexOf(x.templateId) >= 0;
            })
            .orderBy(x => x.templateId)
            .toArray();

        pkg.templateList = templates;

        if (isVoid(pkg.template)) {
            return;
        }

        const existing = pkg.templateList.find(x => x.templateId === pkg.template.templateId);
        pkg.template = existing;
    }

    @DetectMethodChanges({isAsync: true})
    async onTemplateSelected(event: DxValueChanged<ICashFlowAdjustmentSettingsTemplate>,
                             pkg: PackageComparisonModel) {

        const selectedTemplate = pkg.template;
        const expirationSettings = selectedTemplate?.expirationSettings;

        if (isVoid(expirationSettings)) {
            pkg.expirationList = [];
            pkg.expiration = undefined;
            return;
        }

        const proposedTemplate = event.value?.templateId;
        const previousTemplate = event.previousValue?.templateId;

        if (proposedTemplate === previousTemplate) {
            return;
        }

        await this.calculateExpirations(pkg);

        await this.applyLiveModeIfPossible();
    }

    private async calculateExpirations(pkg: PackageComparisonModel, days?: number) {
        try {

            const selectedTemplate = pkg.template;
            const expirationSettings = selectedTemplate?.expirationSettings;

            if (isVoid(expirationSettings)) {
                pkg.expirationList = [];
                pkg.expiration = undefined;
                return;
            }

            this.isLoading = true;

            const ul = selectedTemplate.underlying;
            const daysToLookForward = days || expirationSettings.expirationsToLookForward;
            const customDates = isVoid(expirationSettings.customDates)
                ? []
                : expirationSettings.customDates.map(x => x.value);

            const ticker = await this._apgDataService
                .getShortOptionTicker(pkg.portfolio);

            const startDate = ticker?.expiration;

            if (isVoid(startDate)) {
                this._toastr.error('Unable to determine short option for the selected portfolio');
                return;
            }

            const qry = new CalculateRollDates(
                ul,
                daysToLookForward,
                customDates,
                startDate
            );

            const cashedQuery = this._expirationsCache.find(x => {
                const ulSame = x.qry.underlying === qry.underlying;
                const daysSame = x.qry.daysToLookForward === qry.daysToLookForward;
                const startDateSame = x.qry.startDate === qry.startDate;

                return ulSame && daysSame && startDateSame;
            });

            if (isVoid(cashedQuery)) {
                const reply =
                    await this._shellService.processQuery<CalculateRollDatesReply>(qry);

                const dates = reply.dates || [];

                const expirations = dates.map(((value, index) => {
                    const friendly = makeFullExpirationDate(value);

                    const strings = friendly.split(' ');
                    const dow = strings[0];
                    const dte = strings[strings.length - 1];

                    const ix = index + 1;
                    return {
                        seqNo: ix,
                        textSeqNo: `Exp ${ix}`,
                        friendlyDate: friendly,
                        date: value,
                        dow,
                        dte
                    };
                }));

                pkg.expirationList = expirations;

                const cacheItem = {
                    qry,
                    expirations
                };
                this._expirationsCache.push(cacheItem);

            } else {
                pkg.expirationList = cashedQuery.expirations;
            }

            if (!isVoid(pkg.expiration)) {
                pkg.expiration = pkg.expirationList.find(x => x.seqNo === pkg.expiration.seqNo);
            }

            const portfolioPositions =
                await this._apgDataService.getPortfolioPositions(pkg.portfolio);

            const stngs = new CalculatePackageComparison(
                pkg.packageId,
                pkg.portfolio,
                portfolioPositions,
                null,
                JSON.stringify(pkg.template),
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
            );

            pkg.setLatestSettings(stngs);

        } finally {
            this.isLoading = false;
        }
    }


    private async refreshExpirationsBulk() {

        const groups = Enumerable.from(this.packageComparisons)
            .groupBy(x => {
                const pfId = x.portfolio?.id;
                const tplId = x.template?.templateId;
                const key = pfId + '@' + tplId;
                return key;
            })
            .toArray();

        for (const group of groups) {

            const templateOfGroup = group.first().template;

            const expirationSettings = templateOfGroup?.expirationSettings;

            const portfolioOfGroup = group.first().portfolio;

            if (isVoid(expirationSettings)) {

                group.forEach(g => {
                    g.expirationList = [];
                    g.expiration = undefined;
                });

                return;
            }

            const ul = templateOfGroup?.underlying;
            const daysToLookForward = expirationSettings.expirationsToLookForward;
            const customDates = isVoid(expirationSettings.customDates)
                ? []
                : expirationSettings.customDates.map(x => x.value);

            const ticker = await this._apgDataService.getShortOptionTicker(portfolioOfGroup);

            const startDate = ticker?.expiration;

            if (isVoid(startDate)) {
                return;
            }

            const qry = new CalculateRollDates(
                ul,
                daysToLookForward,
                customDates,
                startDate
            );

            const expirationRequestsCache : Record<string, Promise<any[]>> = {};

            const requestKey = qry.underlying + qry.daysToLookForward + qry.startDate;

            const cachedQryPromise = expirationRequestsCache[requestKey];

            if (isVoid(cachedQryPromise)) {

                const request = this._shellService.processQuery<CalculateRollDatesReply>(qry)
                    .then(data => {

                        const dates = data.dates || [];

                        const expirations = dates.map(((value, index) => {
                            const friendly = makeFullExpirationDate(value);

                            const strings = friendly.split(' ');
                            const dow = strings[0];
                            const dte = strings[strings.length - 1];

                            const ix = index + 1;
                            return {
                                seqNo: ix,
                                textSeqNo: `Exp ${ix}`,
                                friendlyDate: friendly,
                                date: value,
                                dow,
                                dte
                            };
                        }));

                        return expirations;
                    });

                expirationRequestsCache[requestKey] = request;


                const expirations = await request;

                group.forEach(g => {
                    g.expirationList = expirations;
                });

            } else {
                const expirations :  any[] = await cachedQryPromise;

                group.forEach(g => {
                    g.expirationList = expirations;
                });
            }

            const packages = group.toArray();

            for (const pkg of packages) {
                if (!isVoid(pkg.expiration)) {
                    pkg.expiration = pkg.expirationList.find(x => x.seqNo === pkg.expiration.seqNo);
                }

                const portfolioPositions =
                    await this._apgDataService.getPortfolioPositions(pkg.portfolio);

                const stngs = new CalculatePackageComparison(
                    pkg.packageId,
                    pkg.portfolio,
                    portfolioPositions,
                    null,
                    JSON.stringify(pkg.template),
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                );

                pkg.setLatestSettings(stngs);
            }


        }
    }

    private setAvailableAdjustments(pkg: PackageComparisonModel) {

        if (isVoid(pkg.portfolio)) {
            pkg.adjustmentListCall = [];
            pkg.adjustmentListPut = [];
            return;
        }

        const callAdjustments = this._pkgCmprsnSettings.get(pkg.portfolio)?.adjustmentCall || [];
        const putAdjustments = this._pkgCmprsnSettings.get(pkg.portfolio)?.adjustmentPut || [];

        pkg.adjustmentListCall = FullAdjustmentsList
            .filter(x => callAdjustments.indexOf(x.name) >= 0);
        pkg.adjustmentListPut = FullAdjustmentsList
            .filter(x => putAdjustments.indexOf(x.name) >= 0);

        if (!isVoid(pkg.adjustmentOne)) {

            const adj1 = pkg.adjustmentListCall.find(x => x.name === pkg.adjustmentOne.name)

            pkg.adjustmentOne = adj1;

        }

        if (!isVoid(pkg.adjustmentTwo)) {
            const adj2 = pkg.adjustmentListPut.find(x => x.name === pkg.adjustmentTwo.name);

            pkg.adjustmentTwo = adj2;

        }
    }

    private async initPortfolioService() {

        this.isLoading = true;

        try {

            const users = this._sessionService.sessionData.users
                .sort((a, b) => a.userName.localeCompare(b.userName));

            this.clientList = users;

            const grps = [];

            for (const user of users) {
                const grp = {
                    key: user.userName,
                    userId: user.userId,
                    items: []
                };

                const cfg: ServiceConfiguration = {
                    orientation: undefined,
                    userId: user.userId,
                    userName: user.userName
                };

                this._portfolioService.configure(cfg);

                const pfs = this._portfolioService.getPortfolios()
                    .sort((a,b) => a.name.localeCompare(b.name));

                const defaultPortfolios = pfs.filter(x => x.id.startsWith('----'));

                for (const defaultPortfolio of defaultPortfolios) {
                    const ul = await this._apgDataService.getUnderlyingOfPortfolio(defaultPortfolio);
                    defaultPortfolio.asset = ul;
                }

                grp.items.push(...pfs);

                grps.push(grp);
            }

            const ownUser = this.getUserObject(this._sessionService.sessionData.userId);

            this._portfolioService.configure({
                orientation: undefined,
                userId: ownUser.userId,
                userName: ownUser.userName
            });

            const pfs = this._portfolioService.getPortfolios();

            const defaultPortfolios = pfs.filter(x => x.id.startsWith('----'));

            for (const defaultPortfolio of defaultPortfolios) {
                const ul = await this._apgDataService.getUnderlyingOfPortfolio(defaultPortfolio);
                defaultPortfolio.asset = ul;
            }

            const ownGrp = {
                key: ownUser.userName,
                userId: ownUser.userId,
                items: pfs
            }

            grps.unshift(ownGrp);

            this.portfolioList = grps;

        } finally {

            this.isLoading = false;

        }
    }

    private getUserObject(userId: string): UserDto {

        if (this._sessionService.sessionData.userId === userId) {

            const ownUserName = this._sessionService.sessionData.firstName + ' ' + this._sessionService.sessionData.lastName[0] + '.';

            return {
                userId,
                userName: ownUserName
            };

        } else {

            return this._sessionService.sessionData.users.find(x => x.userId === userId);
        }
    }

    private async afterPortfolioServiceInitialized() {
        this._hedgesPricingService.init();
        await this.restoreState();
        this.packageMovingFloater.movePackages$
            .subscribe(x => this.onMovePackageRequest(x));
    }

    @DetectMethodChanges({isAsync: true})
    async onExpirationSelected($event: DxValueChanged<any>, pkg: PackageComparisonModel) {
        if (this.isApplyLivePossible) {
            if (this.canApply()) {
                await this.onApplyClicked();
            }
        }
    }

    @DetectMethodChanges({isAsync: true})
    async removePackage(pkg: PackageComparisonModel) {

        try {

            await this.confirmationDlg.show(['Are you sure you want to remove comparison package?']);
        } catch {
            return;
        }

        const ix = this.packageComparisons.indexOf(pkg);
        if (ix < 0) {
            return;
        }
        const deleted = this.packageComparisons.splice(ix, 1);
        this.saveState();
        setTimeout(() => this.releasePackageResources(deleted));

        const dbPkgIx = this.dashboardPackages.findIndex(x => x.templatePackageId === pkg.packageId);
        const removeDshboardPackages = this.dashboardPackages.splice(dbPkgIx, 1);
        setTimeout(() => {
            const models = removeDshboardPackages.flatMap(x => x.models);
            this.releasePackageResources(models);
        });
    }

    @DetectMethodChanges()
    duplicatePackage(pkg: PackageComparisonModel) {
        const cloned = pkg.clone();
        const ix = this.packageComparisons.indexOf(pkg);
        if (ix < 0 || ix >= this.packageComparisons.length) {
            this.packageComparisons.push(cloned);
        } else {
            this.packageComparisons.splice(ix + 1, 0, cloned);
        }
    }

    overrideAtmLiveMode : 'market' | 'custom' = undefined

    get shouldRequestOverrideAtm() : boolean {
        const mode = this._applicationSettingsService.adjustmentPricingGrid.atmWarningMode;

        if (mode !== 'Popup') {
            return false;
        }

        if (this.isLiveMode) {
            if (this.overrideAtmLiveMode === 'market') {
                return false;
            }

            return !isValidNumber(this.overrideAtm);

        } else {

            return !isValidNumber(this.overrideAtm);

        }
    }

    @DetectMethodChanges({isAsync: true})
    async onApplyClicked(priceAdjusterConfig?: any) {
        this.isLoading = true;
        try {
            if (this.shouldRequestOverrideAtm) {
                try {
                    const overrideAtm = await this.overrideAtmDialog.show();

                    if (!isValidNumber(overrideAtm)) {
                        this._toastr.error('Incorrect Override Atm', 'Error');
                        throw new Error();
                    }

                    this.overrideAtm = overrideAtm;

                } catch {

                    if (this.isLiveMode) {
                        this.overrideAtmLiveMode = 'market';
                    }

                } finally {
                    if (this.isLiveMode) {
                        if (isValidNumber(this.overrideAtm)) {
                            this.overrideAtmLiveMode = 'custom';
                        }
                    }
                }
            } else {
                if (this.isLiveMode) {
                    if (isValidNumber(this.overrideAtm)) {
                        this.overrideAtmLiveMode = 'custom';
                    }
                }
            }

            priceAdjusterConfig = await this.packageMovingFloater.getMove();

            await this.calculatePackageComparisons(priceAdjusterConfig);

            this.makeStateSnapshot();

            this.saveState();
        } catch (e) {
            if (e.message) {
                this._toastr.error(isVoid(e.url) ? e?.message : 'Operation Completed With Errors');
            }
        } finally {
            this.isLoading = false;
        }
    }

    hasChanges(pkg: PackageComparisonModel): boolean {
        if (isVoid(pkg)) {
            return false;
        }

        const state = this._lastAppliedState[pkg.packageId];

        if (isVoid(state)) {
            return true;
        }

        const isSame = state === pkg.toStateString();

        const hasDataChanges = this.hasDataChanges(pkg, '*');

        const hasStateChanges = !isSame || hasDataChanges;

        const miscSettingsState = this.getMiscSettingsState();
        const hasAtmChanges = (miscSettingsState?.atm || null) !== (this._miscSettingsState?.atm || null);
        const hasExpChanges = (miscSettingsState?.numOfExp !== this._miscSettingsState?.numOfExp) && this.isDashboard;
        const hasMiscChanges = hasAtmChanges || hasExpChanges;

        return hasStateChanges || hasMiscChanges;
    }

    private releasePackageResources(deleted: PackageComparisonModel[]) {
        if (isVoid(deleted)) {
            return;
        }

        deleted.forEach(pkg => {
            delete this._lastAppliedState[pkg.packageId];
        });


        // deleted.forEach(pkg => {
        //
        //     this._strategyCodeIndex.removeData(pkg.cashFlowAdjustments);
        //
        //     pkg.hedgeTransactions.forEach(trans => {
        //         if (!isVoid(trans.positionTransactions)) {
        //             trans.positionTransactions.forEach(pt => {
        //                 if (!isVoid(pt.beforeState)) {
        //                     this._quoteIndex.removeCustomData(pt.beforeState.positions);
        //                 }
        //
        //                 if (!isVoid(pt.afterState)) {
        //                     this._quoteIndex.removeCustomData(pt.afterState.positions);
        //                 }
        //             });
        //         }
        //
        //         if (!isVoid(trans.orderTransactions)) {
        //             trans.orderTransactions.forEach(orderTrans => {
        //                 if (!isVoid(orderTrans.balancedOrdersProto)) {
        //                     const regularGroup = orderTrans.balancedOrdersProto.map(x => x.orderGroup);
        //                     orderTrans.balancedOrders = regularGroup;
        //
        //                     const flatOrders = regularGroup.flatMap(x => x);
        //                     this._quoteIndex.removeCustomData(flatOrders);
        //                 }
        //                 this._quoteIndex.removeCustomData(orderTrans.orders);
        //             });
        //
        //         }
        //     });
        // });

    }

    private makeStateSnapshot() {

        this.packageComparisons.forEach(x => {
            const state = x.toStateString();
            this._lastAppliedState[x.packageId] = state;
        });

        this._miscSettingsState = this.getMiscSettingsState();
    }

    canApply() {

        const hasChanges = this.packageComparisons
            .some(x => this.hasChanges(x) || this.isAtmOutdated(x));

        return hasChanges || this._unappliedHedgeBoardSettings;
    }

    get isApplyLivePossible(): boolean {

        const isValid = this.packageComparisons.every(x => isVoid(x.isValid()));

        return this.isLiveMode /* && allPackagesBeenAppliedBefore*/ && isValid;
    }

    canSwitchView() : boolean {
        return this.isDashboard
            || this.packageComparisons.every(x => !this.hasChanges(x))
            || this.isHedgeBoard;
    }

    canLoadHedgeBoard() : boolean {
        return !this.isHedgeBoard && this.packageComparisons.every(x => !this.hasChanges(x));
    }

    @DetectMethodChanges({isAsync: true})
    async onAdjustmentCallChanged() {
        await this.applyLiveModeIfPossible();
    }

    @DetectMethodChanges({isAsync: true})
    async onAdjustmentPutChanged() {
        await this.applyLiveModeIfPossible();
    }

    @DetectMethodChanges({isAsync: true})
    async onCallHedgeChanged($event: DxValueChanged<PricingGridStrategyDescriptor>) {
        await this.applyLiveModeIfPossible();
    }

    @DetectMethodChanges({isAsync: true})
    async onPutHedgeChanged($event: DxValueChanged<PricingGridStrategyDescriptor>) {
        await this.applyLiveModeIfPossible();
    }

    @DetectMethodChanges()
    showAdjustmentPopup(pkg: PackageComparisonModel) {

        if (pkg.isFaulty) {
            return;
        }

        this.solutionPopup.show(
            pkg.portfolio,
            pkg.cashFlowAdjustments,
            'double',
            'PriceBox'
        );
    }

    private async calculatePackageComparisons(priceAdjusterConfig?: any) {

        let dashboardData : PackageComparisonModel[] = [];

        if (this.isDashboard) {

            this.isLoading = true;

            try {
                dashboardData = await this.buildDashboard();
            } finally {
                this.isLoading = false;
            }
        }

        let defaultViewItems: PackageComparisonModel[];

        if (priceAdjusterConfig) {
             defaultViewItems =
                 this.packageComparisons.filter(pkg => pkg.portfolio === priceAdjusterConfig.portfolio);
        } else {
            defaultViewItems = this.packageComparisons.slice();
                // .filter(pkg => this.hasChanges(pkg) || this.isAtmOutdated(pkg));
        }

        for (const packageComparisonModel of defaultViewItems) {
            const error = packageComparisonModel.isValid();
            if (!isVoid(error)) {
                const ix = this.packageComparisons.indexOf(packageComparisonModel);
                this._toastr.error(error, `Package #${ix + 1}`);
                throw new Error();
            }
        }

        for (let pkg of defaultViewItems) {
            await this.extendedValidatePackage(pkg);
        }

        const toCalc = defaultViewItems.concat(dashboardData);

        const packagesPromise =  this.sendCalculateRequests(toCalc, priceAdjusterConfig);

        let hbPromise = Promise.resolve();

        if (this.isHedgeBoard) {
            hbPromise = this.onHedgeBoardViewClicked();
        }

        await Promise.all([packagesPromise, hbPromise]);
    }

    private async sendCalculateRequests(data: PackageComparisonModel[], priceAdjusterConfig?: any) {

        const promises = data.map(async pkg => {

            pkg.cashFlowAdjustments = [];

            pkg.hedgeTransactions = [];

            const tpl = JSON.stringify(pkg.template);

            const expirationSmartModeSettings = this._applicationSettingsService
                .adjustmentPricingGrid.expirationSmartMode;

            const useMarginEfficientAdjustment = this._applicationSettingsService.adjustmentPricingGrid.useMarginEfficientAdjustment;

            const useCharlesSchwabFix = this._applicationSettingsService.adjustmentPricingGrid.useCharlesSchwabFix;

            const portfolioPositions = await this._apgDataService
                .getPortfolioPositions(pkg.portfolio);

            const hedgePositions =
                await this._hedgePositionsService.getHedgePositions(pkg.portfolio);

            const expiration = pkg.expiration.date;

            const adjustment = `${pkg.adjustmentOne.name}+${pkg.adjustmentTwo.name}`;

            const cmd = new CalculatePackageComparison(
                pkg.packageId,
                pkg.portfolio,
                portfolioPositions,
                hedgePositions,
                tpl,
                expiration,
                adjustment,
                pkg.callHedge,
                pkg.callHedgeOffset,
                pkg.putHedge,
                pkg.putHedgeOffset,
                expirationSmartModeSettings,
                this.overrideAtm,
                useMarginEfficientAdjustment,
                useCharlesSchwabFix
            );

            pkg.setLatestSettings(cmd);

            return cmd;
        });

        const commands = await Promise.all(promises);

        const delta = priceAdjusterConfig?.delta;

        commands.forEach(x => x.delta = delta);

        const bulkCmd = new CalculatePackageComparisonBulk(
            commands,
            delta,
            priceAdjusterConfig?.isHedgesDashboard
        );

        Enumerable.from(data)
            .select(x => x.portfolio)
            .distinct()
            .forEach(x => {
                this._hedgesPricingService.subscribe(x, delta).then(() => {});
            });

        await this._shellService.processCommand(bulkCmd);
    }

    private onCalculatePackageComparisonResult(msg: CalculatePackageComparisonResult) {

        if (this.isHedgeBoard) {
            this.onHedgeBoardCalculatePackageComparisonResult(msg);
        }

        let pkg: PackageComparisonModel = this.packageComparisons.concat(
            this.dashboardPackages.flatMap(x => x.models)
        ).find(x => x.packageId === msg.packageId);

        if (isVoid(pkg)) {
            return;
        }

        pkg.resetChangeFlags();

        pkg.atmStrike = msg.atmStrike;

        const isDashboard = this.packageComparisons.indexOf(pkg) < 0;

        const quoteIndex = isDashboard ? this._dashboardQuoteIndex : this._quoteIndex;
        const strategyIndex = isDashboard
            ? this._dashboardStrategyCodeIndex
            : this._strategyCodeIndex;

        if (msg.type === 'Adjustment') {

            pkg.cashFlowAdjustments = msg.adjustments;

            for (let cashFlowAdjustment of pkg.cashFlowAdjustments) {
                const friendlyDate = makeGuiFriendlyExpirationDateDte(cashFlowAdjustment.expiration);
                cashFlowAdjustment.expirationDatePrettyWithDaysToExpiration = friendlyDate;
            }

            quoteIndex.addData(pkg.cashFlowAdjustments);
            strategyIndex.addData(pkg.cashFlowAdjustments);

        } else if (msg.type === 'Hedges') {

            pkg.hedgeTransactions = msg.hedges || [];

            pkg.hedgeTransactions.forEach(trans => {
                if (!isVoid(trans.positionTransactions)) {
                    trans.positionTransactions.forEach(pt => {
                        if (!isVoid(pt.beforeState)) {
                            quoteIndex.addToQuoteIndex(pt.beforeState.positions || [], false);
                        }

                        if (!isVoid(pt.afterState)) {
                            quoteIndex.addToQuoteIndex(pt.afterState.positions || [], true);
                        }
                    });
                }

                if (!isVoid(trans.orderTransactions)) {
                    trans.orderTransactions.forEach(orderTrans => {
                        if (!isVoid(orderTrans.balancedOrdersProto)) {
                            const regularGroup = orderTrans.balancedOrdersProto.map(x => x.orderGroup);
                            orderTrans.balancedOrders = regularGroup;

                            const flatOrders = regularGroup.flatMap(x => x);
                            quoteIndex.addToQuoteIndex(flatOrders, false);
                        }
                        quoteIndex.addToQuoteIndex(orderTrans.orders || [], false);
                    });
                }
            });
        }

        pkg.isFaulty = msg.error;

        this._messageBus.publishAsync({
            topic: 'Apg.QuotesUpdated',
            payload: {}
        });
    }

    private onHedgeBoardCalculatePackageComparisonResult(msg: CalculatePackageComparisonResult) {

        let pkg: PackageComparisonModel = this.hedgeBoardPackages
            .flatMap(x => x.models)
            .find(x => x.packageId === msg.packageId);

        if (isVoid(pkg)) {
            return;
        }

        pkg.resetChangeFlags();

        pkg.atmStrike = msg.atmStrike;

        const quoteIndex = this._hedgeBoardQuoteIndex;
        const strategyIndex = this._hedgeBoardStrategyCodeIndex;

        if (msg.type === 'Adjustment') {

            pkg.cashFlowAdjustments = msg.adjustments;

            for (let cashFlowAdjustment of pkg.cashFlowAdjustments) {
                const friendlyDate = makeGuiFriendlyExpirationDateDte(cashFlowAdjustment.expiration);
                cashFlowAdjustment.expirationDatePrettyWithDaysToExpiration = friendlyDate;
            }

            quoteIndex.addData(pkg.cashFlowAdjustments);
            strategyIndex.addData(pkg.cashFlowAdjustments);

        } else if (msg.type === 'Hedges') {

            pkg.hedgeTransactions = msg.hedges || [];

            pkg.hedgeTransactions.forEach(trans => {
                if (!isVoid(trans.positionTransactions)) {
                    trans.positionTransactions.forEach(pt => {
                        if (!isVoid(pt.beforeState)) {
                            quoteIndex.addToQuoteIndex(pt.beforeState.positions || [], false);
                        }

                        if (!isVoid(pt.afterState)) {
                            quoteIndex.addToQuoteIndex(pt.afterState.positions || [], true);
                        }
                    });
                }

                if (!isVoid(trans.orderTransactions)) {
                    trans.orderTransactions.forEach(orderTrans => {
                        if (!isVoid(orderTrans.balancedOrdersProto)) {
                            const regularGroup = orderTrans.balancedOrdersProto.map(x => x.orderGroup);
                            orderTrans.balancedOrders = regularGroup;

                            const flatOrders = regularGroup.flatMap(x => x);
                            quoteIndex.addToQuoteIndex(flatOrders, false);
                        }
                        quoteIndex.addToQuoteIndex(orderTrans.orders || [], false);
                    });
                }
            });
        }

        this._messageBus.publishAsync({
            topic: 'Apg.QuotesUpdated',
            payload: {}
        });
    }


    @DetectMethodChanges()
    onQuote(quotes: QuoteDto[]) {
        this._quoteIndex.onQuote(quotes);
        this._dashboardQuoteIndex.onQuote(quotes);
        this._hedgeBoardQuoteIndex.onQuote(quotes);
        this._messageBus.publishAsync({
            topic: 'Apg.QuotesUpdated',
            payload: {}
        });
    }

    @DetectMethodChanges()
    private onStrategyPrice(dtos: StrategyPriceDto[]) {
        const updates = this._strategyCodeIndex.onStrategyPrice(dtos);
        const dashboardUpdates = this._dashboardStrategyCodeIndex.onStrategyPrice(dtos);
        const hedgeUpdates = this._hedgeBoardStrategyCodeIndex.onStrategyPrice(dtos);

        if (isVoid(updates) && isVoid(dashboardUpdates) && isVoid(hedgeUpdates)) {
            return;
        }

        // TODO: provide layout id
        this._messageBus.publishAsync({
            topic: 'Apg.StrategyPriceUpdated',
            payload: updates,
        });

    }

    private saveState() {

        if (!isVoid(this.selectedClient)) {
            return;
        }
        const packageComparisonDtos = this.packageComparisons
            .map(x => x.toDto());

        const userId = this._sessionService.sessionData.userId;

        this._userSettingsService.setValue(PackagesStorageKey, packageComparisonDtos, userId);
    }

    private async restoreState(userId?: string) : Promise<void> {

        if (isVoid(userId)) {
            userId = this._sessionService.sessionData.userId;
        }

        const packages = this._userSettingsService
            .getValue<PackageComparisonDto[]>(PackagesStorageKey, userId) || [];

        this.isLoading = true;

        try {
            await this.restorePackagesInternal(packages);
            const errors = this.packageComparisons.flatMap(x => this.inspectPackageForIncorrectFilters(x))
                .filter( (v, ix, arr) => {
                    return arr.indexOf(v) === ix;
                });
            this.showErrorMessageForMissingItems(errors);
        } catch (e) {
            console.error(e);
            this._toastr.error('"Load Package Comparisons" operation completed with errors');
        } finally {
            await this.applyLiveModeIfPossible();
            this.isLoading = false;
        }
    }

    private restorePackagesInternal(packages: PackageComparisonDto[]) : Promise<void[]> {

        const promises = packages.map( async pkg => {
            const model = new PackageComparisonModel(pkg.packageId);

            const pf = this.portfolioList
                ?.find(x => x.userId === pkg.portfolioUserId)
                ?.items.find(x => x.id === pkg.portfolioId);

            await this.onPortfolioSelected({event: 'ets', value: pf}, model, true);

            const tpl = model.templateList
                .find(x => x.templateId === pkg.templateId);
            model.template = tpl;

            await this.onTemplateSelected({event: 'ets', value: tpl}, model);

            const exp = model.expirationList
                .find(x => x.seqNo === pkg.expirationSeqNo);
            model.expiration = exp;

            const adjustmentOne = model.adjustmentListCall.find(x => x.name === pkg.adjustmentOne);
            model.adjustmentOne = adjustmentOne;

            const adjustmentTwo = model.adjustmentListPut.find(x => x.name === pkg.adjustmentTwo);
            model.adjustmentTwo = adjustmentTwo;

            const callHedge = model.callHedgeList
                .flatMap(x => x.items)
                .find(x => x.strategyId === pkg.callHedgeId);
            model.callHedge = callHedge;

            model.callHedgeOffset = pkg.callHedgeOffset;

            const putHedge = model.putHedgeList.flatMap(x => x.items)
                .find(x => x.strategyId === pkg.putHedgeId);
            model.putHedge = putHedge;

            model.putHedgeOffset = pkg.putHedgeOffset;

            model.isPinned = pkg.isPinned;

            model.name = pkg.name;

            this.packageComparisons.push(model);
        });

        return Promise.all(promises);
    }

    onSettingsClicked() {
        this.settingsCmp.show(this.portfolioList);
    }

    @DetectMethodChanges()
    showHedgesPopup(pkg: PackageComparisonModel) {
        if (pkg.isFaulty) {
            return;
        }

        this.hedgesPopup.show(
            pkg.portfolio,
            pkg.defaultQty,
            pkg.hedgeTransactions, !this.isHedgeBoard,
            null,
            () => this.invertHedgeBoardPrices
        );
    }

    private onHgSaveAfterState(payload: { positions: any[], slot: any, packageId?: string }) {

        const pkg = this.packageComparisons.find(x => x.packageId === payload.packageId);

        if (isVoid(pkg)) {
            return;
        }

        this._hedgePositionsService.saveAfterStatePositions(
            payload.positions,
            payload.slot,
            pkg.portfolio
        );
    }

    isAtmOutdated(pkg: PackageComparisonModel) {

        if (this.isLoading) {
            return false;
        }

        if (!isVoid(this.overrideAtm)) {
            return false;
        }

        const calculatedStrike = pkg?.atmStrike || pkg.getLatestSettings()?.atm;

        if (!isValidNumber(calculatedStrike, true)) {
            return false;
        }

        const underlying = pkg?.template?.underlying;

        if (isVoid(underlying)) {
            return false;
        }

        const currentAtm = this._atmStrikeService.getCurrentAtm(underlying);

        return currentAtm !== calculatedStrike;
    }

    @DetectMethodChanges({isAsync: true})
    async onClientContextChanged($event: DxValueChanged<UserDto>) {

        try {
            this.isLoading = true;

            this.overrideAtm = null;

            this.overrideAtmLiveMode = null;

            this.packageMovingFloater?.reset();

            this._lastAppliedState = {};

            this._quoteIndex.reset();
            this._strategyCodeIndex.reset();

            this._dashboardQuoteIndex.reset();
            this._dashboardStrategyCodeIndex.reset();

            this._hedgeBoardQuoteIndex.reset();
            this._hedgeBoardStrategyCodeIndex.reset();

            this.packageComparisons = [];

            this.dashboardPackages = [];
            this._dashboardPackagesViewModelGroups = [];

            this.hedgeBoardPackages = [];
            this._hedgeBoardViewModelGroups = [];

            const userId = $event.value?.userId || this._sessionService.sessionData.userId;

            await this.restoreState(userId);

            if (this.isDashboard) {
                if (this.isApplyLivePossible) {
                    await this.applyLiveModeIfPossible();
                } else {
                    await this.buildDashboard();
                }
            } else if (this.isHedgeBoard) {
               await this.onHedgeBoardViewClicked();
            }

            if (this.packageMovingFloater.visible) {
                await this.showFloater();
            }

        } catch {
            //
        } finally {
            this.isLoading = false;
        }
    }

    private onSettingsUpdated(data: PackageComparisonSettings) {
        this.packageComparisons
            .filter(pkg => !isVoid(pkg?.portfolio))
            .forEach(pkg => {
                this.setAvailableTemplates(pkg)
                    .then(() => this.setAvailableAdjustments(pkg))
                    .then(() => this.setAvailableCallHedges(pkg))
                    .then(() => this.setAvailablePutHedges(pkg))
                    .finally(() => {
                        this._changeDetector.detectChanges()
                    });
            });
    }

    @DetectMethodChanges({isAsync: true})
    private async onHedgeBoardSettingsUpdated(data: HedgeBoardSettings) {

        if (!this.isHedgeBoard) {
            return;
        }

        if (this.isLiveMode) {
            await this.onHedgeBoardViewClicked();
            return;
        }

        this._unappliedHedgeBoardSettings = true;
    }

    private onHedgesUpdated(payload: { template: OpgTemplate, side: 'calls' | 'puts', userId?: string }) {

        this.packageComparisons.forEach(pkg => {
            if (payload.side === 'calls') {
                this.setAvailableCallHedges(pkg)
                    .then(() => this._changeDetector.detectChanges());
            } else if (payload.side === 'puts') {
                this.setAvailablePutHedges(pkg)
                    .then(() => this._changeDetector.detectChanges());
            }
        });
    }

    private onStrategyTemplatesUpdated() {
        this.packageComparisons.forEach(pkg => {
            this.setAvailableTemplates(pkg)
                .then(() => this._changeDetector.detectChanges());
        });
    }

    private async extendedValidatePackage(pkg: PackageComparisonModel): Promise<boolean> {
        const portfolioCorrect = await this.validatePortfolioPositions(pkg);

        if (!portfolioCorrect) {
            throw new Error('Some Of The Selected Portfolios Have Expired Positions');
        }

        const ul = await this._apgDataService.getUnderlyingOfPortfolio(pkg.portfolio);

        if (ul === 'SPX') {

            const f = (offset: number) => {
                const n = Math.ceil(offset / 5) * 5;
                if (n !== offset) {
                    throw new Error('Selected Offset Doesn\'t Match Underlying Of The Portfolio');
                }
                return true;
            };

            if (isValidNumber(pkg.callHedgeOffset)) {
                if (!f(pkg.callHedgeOffset)) {
                    return false;
                }
            }

            if (isValidNumber(pkg.putHedgeOffset)) {
                if (!f(pkg.putHedgeOffset)) {
                    return false;
                }
            }
        }

        return true;
    }

    private async validatePortfolioPositions(pkg: PackageComparisonModel) {
        const positions = await this._apgDataService
            .getPortfolioPositions(pkg.portfolio);

        const beforePositionDtos = positions.flatMap(x => x);

        const someExpired = beforePositionDtos.some(x => isOptionExpired(x.ticker));

        return !someExpired;
    }

    @DetectMethodChanges({isAsync: true})
    private async onUserSettingsChanged(payload: UserSettingObject) {
        let didSoChanged = this.checkPortfolioDataChanges(payload);
        let didTplExpirationsChanged = this.checkTemplateDataChanges(payload);
        this.checkHedgesDataChanges(payload);

        if (didSoChanged || didTplExpirationsChanged) {
            this.isLoading = true;
            try {
                await this.refreshExpirationsBulk();
            } finally {
                this.isLoading = false;
            }
        }
    }

    private checkPortfolioDataChanges(payload: UserSettingObject) : boolean {

        if (!payload.key.startsWith('apg.saved-positions.')) {
            return;
        }


        const settingKey = payload.key;
        const parts = settingKey.split('.');

        if (parts.length !== 5) {
            return;
        }

        const pfId = parts[2];
        const ul = parts[3];
        const str = parts[4];
        const userId = payload.userId;

        let ulChanged = false;
        let isDefaultPf = pfId === '----';

        let matchingPortfolios = this.packageComparisons
            .filter(x => !isVoid(x.portfolio))
            .filter(x => x.portfolio.userId === userId)
            .filter(x => x.portfolio.id === pfId);

        if (isDefaultPf) {
            const sameUlAndStr = matchingPortfolios
                .filter(x => !isVoid(x.template))
                .filter(x => x.template.underlying === ul)
                .filter(x => x.template.strategyName === str);

            if (sameUlAndStr.length > 0) {
                matchingPortfolios = sameUlAndStr;
            } else {
                ulChanged = true;
            }
        }

        let didSoChanged = false;

        if (matchingPortfolios.length > 0) {
            matchingPortfolios.forEach(x => {
                const cmd: CalculatePackageComparison = x.getLatestSettings();
                if (!isVoid(cmd?.positions)) {
                    const ourState = JSON.stringify(cmd.positions);
                    const theirState = JSON.stringify(payload.value);
                    if (ourState !== theirState) {
                        x.setChangeFlag('portfolio');
                        didSoChanged = this.didShortOptionChanged(payload.value, cmd.positions);
                        if (didSoChanged) {
                            x.setChangeFlag('expiration');
                        }
                    } else {
                        x.removeChangeFlag('portfolio');
                    }
                }

                if (ulChanged) {
                    x.setPortfolio(null, null);
                }

                this.setShortOptionTickerOnPackage(x).then(()=>{});
            });
        }

        return didSoChanged;
    }

    private checkTemplateDataChanges(payload: UserSettingObject) : boolean {

        if (!payload.key.startsWith('apg.templates')) {
            return;
        }

        let didExpirationsChanged = false;

        const templates = payload.value as ICashFlowAdjustmentSettingsTemplate[];

        for (let template of templates) {
            const matching = this.packageComparisons
                .filter(x => !isVoid(x.template))
                .filter(x => x.template.templateId === template.templateId)
                .filter(x => x.template.underlying === template.underlying)
                .filter(x => x.template.strategyName === template.strategyName);

            if (matching.length > 0) {
                matching.forEach(x => {
                    const cmd: CalculatePackageComparison = x.getLatestSettings();
                    if (!isVoid(cmd?.template)) {
                        const current = JSON.stringify(template);
                        if (current !== cmd.template) {
                            x.setChangeFlag('template');
                            didExpirationsChanged = this.didExpirationsChanged(cmd.template, current);
                            if (didExpirationsChanged) {
                                x.setChangeFlag('expiration');
                            }
                        } else {
                            x.removeChangeFlag('template');
                        }
                    }
                });
            }
        }

        return didExpirationsChanged;
    }

    private checkHedgesDataChanges(payload: UserSettingObject) {

        if (!payload.key.startsWith('opg.templates')) {
            return;
        }

        const settingKey = payload.key;
        const parts = settingKey.split('.');

        if (parts.length !== 3) {
            return;
        }

        const type = parts[2];
        const userId = payload.userId;

        const templates = payload.value as OpgTemplate[];

        if (isVoid(templates)) {
            return;
        }

        const descriptors = templates.flatMap(x => x.descriptors);

        for (let hedge of descriptors) {
            const matching = this.packageComparisons
                .filter(x => x.portfolio?.userId === userId)
                .filter(x => {
                    if (type === 'call') {
                        return x.callHedge?.strategyId === hedge.strategyId;
                    } else if (type === 'put') {
                        return x.putHedge?.strategyId === hedge.strategyId;
                    }
                    return false;
                })
                .filter(x => !isVoid(x));

            if (matching.length > 0) {
                matching.forEach(x => {
                    const cmd: CalculatePackageComparison = x.getLatestSettings();

                    if (!isVoid(cmd)) {
                        const pkgHedge = type === 'call'
                            ? cmd.callHedge
                            : cmd.putHedge;

                        if (!isVoid(pkgHedge)) {

                            const flag: RowFilterAttribute = type === 'call'
                                ? 'hedge_call'
                                : 'hedge_put';

                            const existing = JSON.stringify(pkgHedge);

                            const current = JSON.stringify(hedge);

                            if (current !== existing) {
                                x.setChangeFlag(flag);
                            } else {
                                x.removeChangeFlag(flag);
                            }
                        }
                    }
                });
            }
        }
    }

    hasDataChanges(pkg: PackageComparisonModel, filter: RowFilterAttribute) {
        if (isVoid(filter)) {
            return false;
        }
        let hasDataChanges = false;
        if (filter === '*') {
            hasDataChanges = pkg.hasDataChanges();
        } else {
            hasDataChanges = pkg.hasChangeFlag(filter);
        }
        return hasDataChanges;
    }

    @DetectMethodChanges()
    pinPackage(pkg: PackageComparisonModel) {
        pkg.isPinned = !pkg.isPinned;
        this.saveState();
    }


    getPackageTitle(pkg: PackageComparisonModel): string {
        return isVoid(pkg.name)
            ? `Package #${this.packageComparisons.indexOf(pkg) + 1}`
            : pkg.name;
    }

    private didShortOptionChanged(theirs: BeforePositionDto[][], ours: BeforePositionDto[][]) {

        const theirsSo = theirs
            .flatMap(x => x).find(x => x.role === 'ShortOption');

        if (isVoid(theirsSo)) {
            return true;
        }

        const oursSo = ours
            .flatMap(x => x).find(x => x.role === 'ShortOption');

        if (isVoid(oursSo)) {
            return true;
        }

        const theirsSoTicker = parseOptionTicker(theirsSo.ticker);
        const oursSoTicker = parseOptionTicker(oursSo.ticker);

        return theirsSoTicker?.expiration !== oursSoTicker?.expiration;
    }

    isFilterEnabled(attribute: RowFilterAttribute) {
        return this.isFilterEnabledInternal(this.rowFilters, attribute);
    }

    private isFilterEnabledInternal(filters: ReadonlyArray<RowFilter>, attribute: RowFilterAttribute) {
        if (isVoid(attribute)) {
            return false;
        }

        if (isVoid(filters)) {
            return false;
        }

        let isEnabled = filters.findIndex(x => x.attribute === attribute) >= 0;

        if (!isEnabled && attribute.startsWith('hedge_')) {
            const attr = attribute + '_family';
            isEnabled = filters.findIndex(x => x.attribute === attr) >= 0;
        } else if (!isEnabled && attribute.startsWith('adj')) {
            const dbAdjustmentFilter = filters.find(x => x.attribute === 'adjustment');
            isEnabled = !isVoid(dbAdjustmentFilter);
        }

        return isEnabled;
    }

    @DetectMethodChanges()
    onFilterRequest(filter: RowFilterAttribute, pkg: PackageComparisonModel) {

        if (isVoid(pkg)) {
            return;
        }

        if (isVoid(filter)) {
            return;
        }

        const rowFilter: RowFilter = {attribute: filter};

        switch (filter) {
            case "portfolio":
                rowFilter.value = pkg.portfolio?.userId + pkg.portfolio?.id;
                break;
            case "template":
                rowFilter.value = pkg.template?.templateId;
                break;
            case "expiration":
                rowFilter.value = pkg.expiration?.seqNo;
                break;
            case "adj_call":
                rowFilter.value = pkg.adjustmentOne?.name;
                break;
            case "adj_put":
                rowFilter.value = pkg.adjustmentTwo?.name;
                break;
            case 'adjustment':
                break;
            case "hedge_call":
                const existingCallHedge = this.rowFilters
                    .findIndex(x => x.attribute === 'hedge_call');

                const existingCallHedgeFamily = this.rowFilters
                    .findIndex(x => x.attribute === 'hedge_call_family');

                if (existingCallHedge >= 0) {
                    this._filtersService.removeAt(existingCallHedge);
                    // this.rowFilters.splice(existingCallHedge, 1);
                    this.onFilterRequest('hedge_call_family', pkg);
                    return;
                } else if (existingCallHedgeFamily >= 0) {
                    rowFilter.attribute = 'hedge_call_family'; // this will remove filter
                    rowFilter.value = this.rowFilters[existingCallHedgeFamily].value;
                } else {
                    rowFilter.value = pkg.callHedge?.strategyId;
                }
                break;
            case "hedge_put":
                const existingPutHedge = this.rowFilters
                    .findIndex(x => x.attribute === 'hedge_put');

                const existingPutHedgeFamily = this.rowFilters
                    .findIndex(x => x.attribute === 'hedge_put_family');

                if (existingPutHedge >= 0) {
                    this._filtersService.removeAt(existingPutHedge);
                    this.onFilterRequest('hedge_put_family', pkg);
                    return;
                } else if (existingPutHedgeFamily >= 0) {
                    rowFilter.attribute = 'hedge_put_family'; // this will remove filter
                    rowFilter.value = this.rowFilters[existingPutHedgeFamily].value;
                } else {
                    rowFilter.value = pkg.putHedge?.strategyId;
                }
                break;
            case "hedge_call_family":
                rowFilter.value = pkg.callHedge?.templateId;
                break;
            case "hedge_put_family":
                rowFilter.value = pkg.putHedge?.templateId;
                break;
            case 'offset_call':
                rowFilter.value = pkg.callHedgeOffset;
                break;
            case 'offset_put':
                rowFilter.value = pkg.putHedgeOffset;
                break;
            default:
                this._toastr.warning('Unknown Filter Attribute');
                return;
        }

        if (isVoid(rowFilter.value)) {
            this._toastr.warning('Filtering By Empty Filed Is Not Supported');
            return;
        }

        if (filter.startsWith('adj')) {
            const dbAdjustment = this.rowFilters.findIndex(x => x.attribute === 'adjustment');
            if (dbAdjustment >= 0) {
                this._filtersService.removeAt(dbAdjustment);
                return;
            }
        }

        const ix = this.rowFilters.findIndex(x => x.attribute === rowFilter.attribute);

        if (ix < 0) {
            this._filtersService.addFilter(rowFilter);
        } else {
            const existing = this.rowFilters[ix];
            if (existing.value === rowFilter.value) {
                this._filtersService.removeAt(ix);
            } else {
                this._filtersService.replaceAt(ix, rowFilter);
            }
        }
    }

    private isFamilyFilterEnabledInternal(filters: ReadonlyArray<RowFilter>, filter: RowFilterAttribute) {
        if (filter === 'hedge_call') {
            return filters.findIndex(x => x.attribute === 'hedge_call_family') >= 0;
        }

        if (filter === 'hedge_put') {
            return filters.findIndex(x => x.attribute === 'hedge_put_family') >= 0;
        }

        return false;
    }

    isFamilyFilterEnabled(filter: RowFilterAttribute) {
        return this.isFamilyFilterEnabledInternal(this.rowFilters, filter);
    }

    private changeSortingAttributeInternal(rules: ReadonlyArray<SortingRule>, attribute: SortingAttribute) {
        const sortingRule = rules.find(x => x.attribute === attribute);
        if (isVoid(sortingRule)) {
            const rule: SortingRule = {
                attribute,
                order: 'asc'
            };
            this._sortingService.addSortingRule(rule);
        } else if (sortingRule.order === 'asc') {
            sortingRule.order = 'desc';
        } else if (sortingRule.order === 'desc') {
            const ix = rules.indexOf(sortingRule);
            this._sortingService.removeAt(ix);
        }

        this._sortingService.sortRules();
    }

    @DetectMethodChanges()
    changeSortingAttribute(attribute: SortingAttribute) {
        this.changeSortingAttributeInternal(this.sortingRules, attribute);
    }

    private getSortingStateInternal(rules: ReadonlyArray<SortingRule>, attribute: SortingAttribute) {
        const sortingRule = rules
            .find(x => x.attribute === attribute);
        return sortingRule?.order;
    }

    getSortingState(attribute: SortingAttribute) {
        return this.getSortingStateInternal(this.sortingRules, attribute);
    }

    @DetectMethodChanges()
    clearFilters() {
        this._filtersService.clearFilters();
        if (this.isHedgeBoard) {
            this.hedgeBoardViewModelGroups.forEach(x => x.clearFilters());
        }
    }

    @DetectMethodChanges()
    clearSorting() {
        if (this.isDashboard) {
            this.dashboardPackagesViewModelGroups.forEach(x => x.clearSorting());
        } else if (this.isHedgeBoard) {
            this.hedgeBoardViewModelGroups.forEach(x => x.clearSorting());
        } else if (this.isPackagesView) {
            this._sortingService.clearSortingRules();
        }
    }

    private didExpirationsChanged(template: string, current: string) {
        const existingTpl = JSON.parse(template) as ICashFlowAdjustmentSettingsTemplate;
        const currentTpl = JSON.parse(current) as ICashFlowAdjustmentSettingsTemplate;

        const diff = existingTpl?.expirationSettings?.expirationsToLookForward
            !== currentTpl?.expirationSettings?.expirationsToLookForward;

        return diff;
    }

    @DetectMethodChanges({isAsync: true})
    async onOffsetChanged(side: 'call' | 'put') {
        await this.applyLiveModeIfPossible();
    }

    @DetectMethodChanges({isAsync: true})
    async onOverrideAtmChanged($event: DxValueChanged<number>) {
        await this.applyLiveModeIfPossible();
    }

    async onChangeViewClick() {
        this._unappliedHedgeBoardSettings = false;

        if (this.mode === 'packages') {
            this.mode = 'dashboard'
        } else {
            this.mode = 'packages';
            this.resetDashboard();
            this.resetHedgeBoard();
            this.packageMovingFloater?.reset();
        }

        if (this.packageMovingFloater.visible) {
            await this.showFloater();
        }

        await this.onApplyClicked()
    }

    private async buildDashboard(): Promise<PackageComparisonModel[]> {

       this.resetDashboard();

        if (!this.isDashboard) {
            return [];
        }

        const distinctPackages = Enumerable.from(this.packageComparisons)
            .distinct(element => element.getUniqueId())
            .toArray();

        const data = distinctPackages.flatMap(tplPkg => {
            const clones = Enumerable.range(0, this.dashboardExpirations)
                .select(exp => {
                    const clone = tplPkg.clone();
                    return clone;
                }).toArray();
            return new DashboardComparisonModel(tplPkg.packageId, clones);
        });

        this.dashboardPackages = data;

        const userId = this.selectedClient?.userId || this._sessionService.sessionData.userId;

        const pinned = this._userSettingsService.getValue<string[]>(DashboardPinnedKey, userId) || [];

        const promises = this.dashboardPackages.map(async x => {

            const anyModel = x.models[0];

            await this.calculateExpirations(anyModel, this.dashboardExpirations);

            for (let i = 0; i < x.models.length; i++) {
                const model = x.models[i];
                model.expiration = anyModel.expirationList[i];
            }

            x.isPinned = pinned.indexOf(x.uniqueId) >= 0;

        });

        await Promise.all(promises);

        const dbModels = data.flatMap(x => x.models);

        return dbModels;
    }

    private async loadDashboard(models: PackageComparisonModel[], priceAdjusterConfig?: any) : Promise<void> {
        await this.sendCalculateRequests(models, priceAdjusterConfig);
    }

    @DetectMethodChanges()
    async onDashboardModelPinChanged(data: DashboardComparisonModel) {

        this.isLoading = true;

        try {
            await wrapInPromise(() => {
                const pinned = this.dashboardPackages
                    .filter(x => x.isPinned)
                    .map(x => x.uniqueId);

                const userId = this.selectedClient?.userId || this._sessionService.sessionData.userId;

                this._userSettingsService.setValue(DashboardPinnedKey, pinned, userId);

                const viewGroup = this.dashboardPackagesViewModelGroups
                    .find(grp => grp.items.indexOf(data) >= 0);

                if (isVoid(viewGroup)) {
                    return;
                }

                viewGroup.resetSorting();
            });
        } finally {
            this.isLoading = false;
            this._changeDetector.detectChanges();
        }
    }

    @DetectMethodChanges()
    showDashboardFilter(filter: RowFilterAttribute, grp: DashboardViewModelGroup) {

        let items: DashboardFilterItem[];
        let attributeName: string;

        if (grp.isDashboardFilterEnabled(filter)) {
            grp.disableFilter(filter);
            return;
        }

        const filteredItems = grp.filteredItems;

        switch (filter) {
            case "portfolio":
                attributeName = 'Portfolio';
                items = Enumerable.from(filteredItems)
                    .select(x => x.portfolio)
                    .select(x => {
                        return {
                            text: `${x.userName} | ${x.name}`,
                            value: `${x.userId}@${x.id}`
                        } as DashboardFilterItem
                    })
                    .distinct(x => x.value)
                    .toArray();
                break;
            case "template":
                attributeName = 'Template';
                items = Enumerable.from(filteredItems)
                    .select(x => x.template)
                    .select(x => {
                        return {
                            text: x.templateName,
                            value: x.templateId
                        } as DashboardFilterItem
                    })
                    .distinct(x => x.value)
                    .toArray();
                break;
            case "adjustment":
                attributeName = 'Adjustment';
                items = Enumerable.from(filteredItems)
                    .select(x => x.adjustment)
                    .select(x => {
                        return {
                            text: x,
                            value: x
                        } as DashboardFilterItem
                    })
                    .distinct(x => x.value)
                    .toArray();
                break;
            case "hedge_call":
                attributeName = 'Call Hedge';
                items = Enumerable.from(filteredItems)
                    .where(x => !isVoid(x.callHedge))
                    .select(x => x.callHedge)
                    .select(x => {
                        return {
                            text: x.strategyName,
                            value: x.strategyId
                        } as DashboardFilterItem
                    })
                    .distinct(x => x.value)
                    .toArray();
                break;
            case "hedge_put":
                attributeName = 'Put Hedge';
                items = Enumerable.from(filteredItems)
                    .where(x => !isVoid(x.putHedge))
                    .select(x => x.putHedge)
                    .select(x => {
                        return {
                            text: x.strategyName,
                            value: x.strategyId
                        } as DashboardFilterItem
                    })
                    .distinct(x => x.value)
                    .toArray();
                break;
            case 'offset_call':
                attributeName = 'Call Offset';
                items = Enumerable.from(filteredItems)
                    .where(x => isValidNumber(x.callHedgeOffset))
                    .select(x => x.callHedgeOffset)
                    .distinct()
                    .select(x => {
                        return {
                            text: `$${x}`,
                            value: x
                        }
                    })
                    .toArray();
                break;
            case 'offset_put':
                attributeName = 'Put Offset';
                items = Enumerable.from(filteredItems)
                    .where(x => isValidNumber(x.putHedgeOffset))
                    .select(x => x.putHedgeOffset)
                    .distinct()
                    .select(x => {
                        return {
                            text: `$${x}`,
                            value: x
                        }
                    })
                    .toArray();
                break;
        }

        if (isVoid(items)) {
            this._toastr.warning('Filtering Is Not Supported For This Field');
            return;
        }

        if (items.length === 1) {
            this._toastr
                .info('Filtering Is Not Applicable: Column Data Is Identical');
            return;
        }

        const cfg: DashboardFilterConfig = {
            attributeName,
            attribute: filter,
            items
        }

        this.dashboardFilterDialog.show(cfg)
            .then((result) => {
                grp.onDashboardFilterRequest(result);
            })
            .catch(e => {
                console.error(e);
            }).finally(() => this._changeDetector.detectChanges());
    }

    @DetectMethodChanges()
    showHedgeBoardFilter(side: 'calls' | 'puts', filter: RowFilterAttribute, grp: HedgeBoardViewModelGroup) {

        let items: DashboardFilterItem[];
        let attributeName: string;

        if (grp.isFilterEnabled(side,filter)) {
            grp.disableFilter(side,filter);
            return;
        }

        const filteredItems = side === 'calls' ? grp.filteredCalls : grp.filteredPuts;

        switch (filter) {
            case "portfolio":
                attributeName = 'Portfolio';
                items = Enumerable.from(filteredItems)
                    .select(x => x.portfolio)
                    .select(x => {
                        return {
                            text: `${x.userName} | ${x.name}`,
                            value: `${x.userId}@${x.id}`
                        } as DashboardFilterItem
                    })
                    .distinct(x => x.value)
                    .toArray();
                break;
            case "template":
                attributeName = 'Template';
                items = Enumerable.from(filteredItems)
                    .select(x => x.template)
                    .select(x => {
                        return {
                            text: x.templateName,
                            value: x.templateId
                        } as DashboardFilterItem
                    })
                    .distinct(x => x.value)
                    .toArray();
                break;
            case "adjustment":
                attributeName = 'Adjustment';
                items = Enumerable.from(filteredItems)
                    .select(x => x.adjustment)
                    .select(x => {
                        return {
                            text: x,
                            value: x
                        } as DashboardFilterItem
                    })
                    .distinct(x => x.value)
                    .toArray();
                break;
            case "hedge_call":
                attributeName = 'Call Hedge';
                items = Enumerable.from(filteredItems)
                    .where(x => !isVoid(x.hedge))
                    .select(x => x.hedge)
                    .select(x => {
                        return {
                            text: x.strategyName,
                            value: x.strategyId
                        } as DashboardFilterItem
                    })
                    .distinct(x => x.value)
                    .toArray();
                break;
            case "hedge_put":
                attributeName = 'Put Hedge';
                items = Enumerable.from(filteredItems)
                    .where(x => !isVoid(x.hedge))
                    .select(x => x.hedge)
                    .select(x => {
                        return {
                            text: x.strategyName,
                            value: x.strategyId
                        } as DashboardFilterItem
                    })
                    .distinct(x => x.value)
                    .toArray();
                break;
            case 'offset_call':
                attributeName = 'Call Offset';
                items = Enumerable.from(filteredItems)
                    .where(x => isValidNumber(x.offset))
                    .select(x => x.offset)
                    .distinct()
                    .select(x => {
                        return {
                            text: `$${x}`,
                            value: x
                        }
                    })
                    .toArray();
                break;
            case 'offset_put':
                attributeName = 'Put Offset';
                items = Enumerable.from(filteredItems)
                    .where(x => isValidNumber(x.offset))
                    .select(x => x.offset)
                    .distinct()
                    .select(x => {
                        return {
                            text: `$${x}`,
                            value: x
                        }
                    })
                    .toArray();
                break;
        }

        if (isVoid(items)) {
            this._toastr.warning('Filtering Is Not Supported For This Field');
            return;
        }

        if (items.length === 1) {
            this._toastr
                .info('Filtering Is Not Applicable: Column Data Is Identical');
            return;
        }

        const cfg: DashboardFilterConfig = {
            attributeName,
            attribute: filter,
            items
        }

        this.dashboardFilterDialog.show(cfg)
            .then((result) => {
                grp.onFilterRequest(side, result);
            })
            .catch(e => {
                console.error(e);
            }).finally(() => this._changeDetector.detectChanges());
    }


    getHedgesCost(model: DashboardComparisonModel, type: 'calls' | 'puts' | 'total'): number {
        if (isVoid(model)) {
            return undefined;
        }

        if (isVoid(type)) {
            return undefined;
        }

        const key = `${model.portfolio?.userId}@${model.portfolio?.id}`;

        let delta = model.delta;

        const cost = this._hedgesPricingService.getHedgesCost(key, type, delta);

        return cost;
    }

    @DetectMethodChanges({isAsync: true})
    async onDashboardExpirationsChanged() {
        if (this.isDashboard || this.isHedgeBoard) {
            if (this.isHedgeBoard) {
                this._unappliedHedgeBoardSettings = true;
            }
            await this.applyLiveModeIfPossible();
        }
    }

    @DetectMethodChanges({isAsync: true})
    async removeAllPackages() {

        try {
            await this.confirmationDlg.show(['Are you sure you want to delete all packages?']);
        } catch {
            return;
        }

        this.isLoading = true;

        setTimeout(() => {
            try {
                this.packageComparisons = [];
                this.dashboardPackages = [];
                this._dashboardPackagesViewModelGroups = [];
                this._strategyCodeIndex.reset();
                this._quoteIndex.reset();
                this._dashboardStrategyCodeIndex.reset();
                this._dashboardQuoteIndex.reset();
                this._lastAppliedState = {};
                this._miscSettingsState = {};
                this.saveState();
            } finally {
                this.isLoading = false;
                this._changeDetector.detectChanges();
            }
        });
    }

    private getMiscSettingsState(): MiscSettings {
        return {
            atm: this.overrideAtm,
            numOfExp: this.dashboardExpirations
        };
    }

    async onLiveModeChanged($event: DxValueChanged<boolean>) {

        if (!$event.value) {
            this.overrideAtmLiveMode = undefined;
            return;
        }

        await this.applyLiveModeIfPossible();

    }

    private async applyLiveModeIfPossible() {

        if (!this.isLiveMode) {
            return;
        }

        if (this.isApplyLivePossible) {
            if (this.canApply()) {
                await this.onApplyClicked();
            }
        }
    }

    @DetectMethodChanges()
    private onFiltersChanged() {

    }

    @DetectMethodChanges()
    private onSortingChanged() {

    }

    @DetectMethodChanges({isAsync: true})
    private async onPortfoliosUpdated(payload: { portfolios: ApgPortfolio[] }) {

        try {

            this.isLoading = true;

            await this.initPortfolioService();

            const apgPortfolios = this.portfolioList.flatMap(x => x.items);

            for (const pkgModel of this.packageComparisons) {
                const pfKey = pkgModel.portfolio?.id + pkgModel.portfolio?.userId;

                if (isVoid(pfKey)) {
                    return;
                }

                const selected = apgPortfolios.find(x => {
                    const key = x.id + x.userId;
                    return pfKey === key;
                });

                if (selected) {
                    const defaultQty = await this._apgDataService.getDefaultQtyForPortfolio(selected);
                    pkgModel.setPortfolio(selected, defaultQty)
                }
            }

        } catch(e)  {
            console.log(e);
        } finally {
            this.isLoading = false;
        }
    }

    async showFloater() {

        const portfolioSelector = () => {
            const selectedPortfolios =  this.packageComparisons
                .map(x => x.portfolio)
                .filter(x => !isVoid(x))
                .filter((val, ix, arr) => arr.indexOf(val) === ix);

            const pfs = Enumerable.from(selectedPortfolios)
                .groupBy(x => x.userId)
                .select(grp => {
                    const items = grp.toArray();
                    const key = items[0].userName;
                    const userId = grp.key()
                    const g = {
                        key,
                        userId,
                        items,
                    };
                    return g;
                }).toArray();

            return pfs;
        }

        const distanceSelector = async (portfolio: ApgPortfolio) => {
            if (isVoid(portfolio)) {
                return null;
            }
            if (this.isHedgeBoard) {
                return 0;
            }
            const soTicker = await this._apgDataService.getShortOptionTicker(portfolio);
            const strike = soTicker?.strike;
            const atm = this._atmStrikeService.getCurrentAtm(soTicker?.underlying);
            const distance = atm - strike;
            return distance;
        };

        const priceInverter = () => {
            this.invertHedgeBoardPrices = !this.invertHedgeBoardPrices;
            this.hedgeBoardViewModelGroups.forEach(x => x.resetSortingState());
            this._changeDetector.detectChanges();
        }

        const isInverted = () => this.invertHedgeBoardPrices;

        const config = {
            portfolioSelector,
            distanceSelector,
            priceInverter,
            isInverted
        }

        await this.packageMovingFloater.show(config);
    }

    private async onMovePackageRequest(x: {portfolio: ApgPortfolio, delta: number}) {
        await this.onApplyClicked(x);
    }

    private inspectPackageForIncorrectFilters(pkg: PackageComparisonModel) : ('Templates' | 'Hedges' | 'Adjustments')[] {

        let hasMissingHedges = false;

        if (pkg.callHedgeList.length === 0) {
            hasMissingHedges = true;
        }

        if (pkg.putHedgeList.length === 0) {
            hasMissingHedges = true;
        }

        let hasMissingTemplates = false;
        if (pkg.templateList.length === 0) {
            hasMissingTemplates = true;
        }

        let hasMissingAdjustments = false;

        if (pkg.adjustmentListCall.length === 0) {
            hasMissingAdjustments = true;
        }


        if (pkg.adjustmentListPut.length === 0) {
            hasMissingAdjustments = true;
        }

        if (!hasMissingTemplates && !hasMissingAdjustments && !hasMissingHedges) {
            return [];
        }

        const result = [];

        if (hasMissingTemplates) result.push('Templates');

        if (hasMissingAdjustments) result.push('Adjustments');

        if (hasMissingHedges) result.push('Hedges');

        return result;
    }

    private showErrorMessageForMissingItems(items: string[]) {
        if (isVoid(items)) {
            return;

        }

        let message = 'Selected Package or multiple Packages has no available items:<br>';

        if (items.indexOf('Templates')) {
            message += '&nbsp;&nbsp;&nbsp;&nbsp; - Templates<br>';
        }

        if (items.indexOf('Adjustments')) {
            message += '&nbsp;&nbsp;&nbsp;&nbsp; - Adjustments<br>';
        }

        if (items.indexOf('Hedges')) {
            message += '&nbsp;&nbsp;&nbsp;&nbsp; - Hedges<br>';
        }

        message += '<br> Please check your portfolio settings to adjust list of available items';

        this._toastr.warning(message, 'Check Portfolio Settings', {closeButton: true, disableTimeOut: true, enableHtml: true});
    }

    onPanelActiveStateChanged(isActive: boolean) {
        if (!isActive) {
            this.packageMovingFloater.hide();
        } else {
            this.packageMovingFloater.unHide();
        }
    }

    private resetDashboard() {
        this._dashboardQuoteIndex.reset();
        this._dashboardStrategyCodeIndex.reset();
        this.dashboardPackages = [];
        this._dashboardPackagesViewModelGroups = [];
    }

    private resetHedgeBoard() {
        this._hedgeBoardQuoteIndex.reset();
        this._hedgeBoardStrategyCodeIndex.reset();
        this.hedgeBoardPackages = [];
        this._hedgeBoardViewModelGroups = [];

        this._unappliedHedgeBoardSettings = false;
    }

    @DetectMethodChanges({isAsync: true})
    async onHedgeBoardViewClicked() {

        this.resetHedgeBoard();

        if (!this.isHedgeBoard) {
            this.resetDashboard();
            this.packageMovingFloater?.reset();
        }

        this.mode = 'hedgeboard';

        this.isLoading = true;

        try {
            const hdb = await this.buildHedgeBoard();

            for (const packageComparisonModel of hdb) {
                const error = packageComparisonModel.isValid();
                if (!isVoid(error)) {
                    const ix = this.packageComparisons.indexOf(packageComparisonModel);
                    this._toastr.error(error, `Package #${ix + 1}`);
                   return;
                }
            }

            for (let pkg of hdb) {
                await this.extendedValidatePackage(pkg);
            }

            const priceAdjusterConfig = await this.packageMovingFloater.getMove(0);

            if (!isVoid(priceAdjusterConfig)) {
                priceAdjusterConfig.isHedgesDashboard = true;
            }

            await this.sendCalculateRequests(hdb, priceAdjusterConfig);

            if (this.packageMovingFloater.visible) {
                await this.showFloater();
            }
        } catch (e) {
            console.error(e);
            this._toastr.error(`"Calculate Hedge Board" operation completed with errors`);
        }
        finally {
            this.isLoading = false;
        }

    }

    private async buildHedgeBoard(): Promise<PackageComparisonModel[]> {

        this.hedgeBoardPackages = [];
        this._hedgeBoardViewModelGroups = [];
        this._hedgeBoardQuoteIndex.reset();
        this._hedgeBoardStrategyCodeIndex.reset();

        if (!this.isHedgeBoard) {
            return [];
        }

        const apgPortfolios = Enumerable.from(this.packageComparisons)
            .select(x=>x.portfolio)
            .distinct()
            .toArray();

        if (isVoid(apgPortfolios)) {
            return [];
        }

        const dataByPortfolio = [];

        for (const apgPortfolio of apgPortfolios) {

            const hedgeBoardSettings = this._pkgCmprsnSettings
                .getHedgeBoardSettings(apgPortfolio);

            if (isVoid(hedgeBoardSettings)) {
                const msg = `Cannot find "Hedge Board" settings for portfolio "${apgPortfolio.name}"`;
                this._toastr.warning(msg, 'Warning', { enableHtml: false });
                continue;
            }

            let defaultQty: number;
            try {
                defaultQty = await this._apgDataService.getDefaultQtyForPortfolio(apgPortfolio);
            } catch {
                this._toastr.error(`Cannot determine default quantity for portfolio ${apgPortfolio.name}`, 'Error', { enableHtml: false });
                continue;
            }

            let underlying: string;
            try {
                underlying = await this._apgDataService.getUnderlyingOfPortfolio(apgPortfolio);
            } catch  {
                this._toastr.error(`Cannot determine underlying for portfolio '${apgPortfolio.name}`, 'Error', { enableHtml: false });
                continue;
            }

            const templatesForPortfolio = await this.getTemplatesForPortfolio(apgPortfolio);

            if (isVoid(templatesForPortfolio)) {
                this._toastr.error(`Cannot find available templates for portfolio '${apgPortfolio.name}'`);
                continue;
            }

            const templates = hedgeBoardSettings.templates || [];

            if (isVoid(templates)) {
                this._toastr.error(`No templates selected for portfolio '${apgPortfolio.name}'`, 'Error', {enableHtml: false});
                continue;
            }

            if (isVoid(hedgeBoardSettings.adjustmentPut) && isVoid(hedgeBoardSettings.adjustmentCall)) {
                this._toastr.error(`No adjustments selected for portfolio '${apgPortfolio.name}'`, 'Error', {enableHtml: false});
                continue;
            }

            if (isVoid(hedgeBoardSettings.hedgesCall) && isVoid(hedgeBoardSettings.hedgesCall)) {
                this._toastr.error(`No hedges selected for portfolio '${apgPortfolio.name}'`, 'Error', {enableHtml: false});
                continue;
            }

            if (isVoid(hedgeBoardSettings.offsetsCall) && isVoid(hedgeBoardSettings.offsetsPut)) {
                this._toastr.error(`No offsets selected for portfolio '${apgPortfolio.name}'`, 'Error', {enableHtml: false});
                continue;
            }

            const offsetsCall = hedgeBoardSettings.offsetsCall || [];

            const offsetsPut = hedgeBoardSettings.offsetsPut || [];

            const packages = [];

            for (const tpl of hedgeBoardSettings.templates) {

                const template = templatesForPortfolio
                    .find(x => x.templateId === tpl);


                for (const callHedge of hedgeBoardSettings.hedgesCall) {

                    for (const adj of hedgeBoardSettings.adjustmentCall) {

                        const callHedgeTemplates = this._opgTemplatesService
                            .getCallTemplates(apgPortfolio.userId)
                            .filter(x => x.underlying === underlying);

                        const existingHedge = callHedgeTemplates
                            .flatMap(x => x.descriptors)
                            .find(x => x.strategyId === callHedge);

                        for (const offset of offsetsCall) {
                            const p = new PackageComparisonModel();
                            p.setPortfolio(apgPortfolio, defaultQty);
                            p.template = template;
                            p.callHedge = existingHedge;
                            p.callHedgeOffset = offset;
                            p.adjustmentOne = {name: adj as any};
                            p.adjustmentTwo = {name: adj as any};

                            packages.push(p);
                        }
                    }
                }

                for (const putHedge of hedgeBoardSettings.hedgesPut) {

                    for (const adj of hedgeBoardSettings.adjustmentPut) {

                        const putHedgeTemplates = this._opgTemplatesService
                            .getPutTemplates(apgPortfolio.userId)
                            .filter(x => x.underlying === underlying);

                        const existingHedge = putHedgeTemplates
                            .flatMap(x => x.descriptors)
                            .find(x => x.strategyId === putHedge);

                        for (const offset of offsetsPut) {
                            const p = new PackageComparisonModel();
                            p.setPortfolio(apgPortfolio, defaultQty);
                            p.template = template;
                            p.putHedge = existingHedge;
                            p.putHedgeOffset = offset;
                            p.adjustmentOne = {name: adj as any};
                            p.adjustmentTwo = {name: adj as any};

                            packages.push(p);
                        }
                    }
                }
            }

            const promises = packages.map(async x => await this.setShortOptionTickerOnPackage(x))

            await Promise.all(promises);

            const data = packages.map(tplPkg => {
                const clones = Enumerable.range(0, this.dashboardExpirations)
                    .select(exp => {
                        const clone = tplPkg.clone();
                        return clone;
                    }).toArray();
                return new HedgeBoardComparisonModel(tplPkg.packageId, clones);
            });

            dataByPortfolio.push(data);
        }


        this.hedgeBoardPackages = dataByPortfolio.flatMap(x => x);

        const promises = this.hedgeBoardPackages.map(async x => {

            const anyModel = x.models[0];

            await this.calculateExpirations(anyModel, this.dashboardExpirations);

            for (let i = 0; i < x.models.length; i++) {
                const model = x.models[i];
                model.expiration = anyModel.expirationList[i];
            }

        });

        await Promise.all(promises);

        const dbModels = this.hedgeBoardPackages.flatMap(x => x.models);

        return dbModels;
    }

    private async getTemplatesForPortfolio(apgPortfolio: ApgPortfolio) {
        const userDto = this
            .getUserObject(apgPortfolio.userId);

        const cfg: ServiceConfiguration = {
            userId: userDto.userId,
            userName: userDto.userName,
            orientation: null
        };

        await this._cashflowTplService.configure(cfg);

        let templates: ICashFlowAdjustmentSettingsTemplate[] = [];

        const lut = this._cashflowTplService.getLastUsedTemplate(apgPortfolio.id);

        const defaultTemplate = this._cashflowTplService.getOrCreateDefaultTemplate(
            lut.underlying,
            lut.strategy
        );

        templates = this._cashflowTplService
            .getTemplates()
            .filter(tpl => tpl.underlying === lut?.underlying);

        if (templates.findIndex(x => x.templateId.startsWith('----')) < 0) {
            templates.push(defaultTemplate);
        }

        return templates;
    }

    private onHedgeBoardPinnedChanged() {
        const pinnedHedgeBoard = this.hedgeBoardPackages.filter(x => x.isPinned).map(x => x.uniqueId);
        this._pinnedHedgeBoardService.save(pinnedHedgeBoard);
    }

    async showNameEditor(pkg: PackageComparisonModel, target: any) {
        const self = this;
        const validator = (pkgName: string) => {
            return self.packageComparisons.findIndex(x => x.name === pkgName) >= 0;
        };

        const cfg = {
            target,
            package: pkg,
            validator
        };

        try {
            await this.nameEditorDialog.show(cfg);
        } catch {
            //
        } finally {
            this.saveState();
            this._changeDetector.detectChanges();
        }
    }

    getCorrectHedgePrice(price: number) {
        if (!isValidNumber(price)) {
            return null;
        }

        return price * (this.invertHedgeBoardPrices ? -1 : 1)
    }
}
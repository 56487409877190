<div class="root-container">

   <fieldset class="order-legs">

      <legend>{{this.header}}</legend>

      <table [width]="'99%'">

         <thead>
            <tr>
               <th>Action</th>
               <th>Qty</th>
               <th>Asset</th>
               <th>Type</th>
               <th>Expiration</th>
               <th>Strike</th>
               <th *ngIf="!this.readOnly">Price</th>
               <th *ngIf="!this.readOnly">Total</th>
            </tr>

         </thead>

         <tbody>

            <tr class="order" *ngFor="let orderLeg of this.orders; even as isEven; index as i">

               <td class="action" [ngClass]="this.getActionClass(orderLeg, isEven)" [class.duplicate]="this.isDuplicate(orderLeg.ticker)">
                 {{orderLeg.action}}
               </td>

               <td class="qty" [ngClass]="this.getQtyClass(orderLeg)" [class.duplicate]="this.isDuplicate(orderLeg.ticker)">
                  {{ (orderLeg.qty / this.hcf) * this.qtyMultiplier }}
               </td>

               <td [ngClass]="this.getPositionRoleClass(orderLeg)" class="underlying" [class.duplicate]="this.isDuplicate(orderLeg.ticker)">
                 {{orderLeg.underlying}}
               </td>

               <td [ngClass]="this.getPositionRoleClass(orderLeg)" class="type" [class.duplicate]="this.isDuplicate(orderLeg.ticker)">
                 {{orderLeg.type}}
               </td>

               <td class="expiration" [ngClass]="this.getPositionRoleClass(orderLeg)" [class.duplicate]="this.isDuplicate(orderLeg.ticker)">
                 {{orderLeg.expiration}}
               </td>

               <td [class.duplicate]="this.isDuplicate(orderLeg.ticker)" [ngClass]="this.getPositionRoleClass(orderLeg)" class="strike">
                  {{this.getOrderLegStrike(orderLeg)}}
               </td>

               <td *ngIf="!this.readOnly" [class.duplicate]="this.isDuplicate(orderLeg.ticker)"
                   [ngClass]="this.getPriceClass(orderLeg)" class="price"
                    [class.theo]="this.isTheoPrice(orderLeg)"
               >
                  {{ this.getOrderLegPrice(orderLeg) | currency:'USD':'symbol':'1.2-2' }}
               </td>

               <td *ngIf="!this.readOnly && isEven" [ngClass]="this.getPriceClassForSubTotal(orderLeg, i)" class="total" [rowSpan]="2">
                  {{ this.getSubTotalPrice(orderLeg, i) | currency:'USD':'symbol':'1.2-2'}}
               </td>

            </tr>

         </tbody>

         <tfoot>
            <tr>
               <td>&nbsp;</td>
               <td>&nbsp;</td>
               <td>&nbsp;</td>
               <td>&nbsp;</td>
               <td>&nbsp;</td>
               <td>&nbsp;</td>
               <td *ngIf="!this.readOnly" class="total grand" [ngClass]="this.getPriceClassForTotal()">
                  {{ this.getTotalPrice() | currency:'USD':'symbol':'1.2-2'}}
               </td>
               <td *ngIf="!this.readOnly">&nbsp;</td>
            </tr>
         </tfoot>

      </table>

   </fieldset>
</div>

import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {getShortUUID, isValidNumber, isVoid} from "../../utils";
import {
    ICashFlowAdjustmentSettingsTemplate
} from "../../adjustment-pricing-grid/model/ICashFlowAdjustmentSettingsTemplate";
import {CashFlowAdjustmentLogicalId} from "../../adjustment-control-panel/CashFlowAdjustmentId";
import {CashFlowAdjustment} from "../../adjustment-pricing-grid/model/CashFlowAdjustment";
import {HedgeStateTransaction} from "../../hedging-grid/data-model/hedge-state-transaction";
import {CalculatePackageComparison} from "../../shell-communication/shell-operations-protocol";
import {RowFilterAttribute} from "./RowFilterAttribute";
import {PricingGridStrategyDescriptor} from "../../options-pricing-grid/model/pricing-grid-strategy.descriptor";
import {getOrderGroupTotal} from "../../hedging-grid/hg-totals-calculators";

export const FullAdjustmentsList: Adjustment[] = [
    {name: '#1'},
    {name: '#2A'},
    {name: '#2B'},
    {name: '#3'}
];

export interface PortfolioGroup {
    key?: string;
    items?: ApgPortfolio[];
}

export interface Adjustment {
    name?: CashFlowAdjustmentLogicalId;
    price?: number;
}

export interface Hedge {
    strategyName?: string;
    strategyId?: string;
    price?: number;
}

export interface PackageComparisonDto {
    packageId: string;
    name: string;
    portfolioId: string;
    portfolioUserId: string;
    templateId: string;
    expirationSeqNo: number;
    adjustmentOne: CashFlowAdjustmentLogicalId;
    adjustmentTwo: CashFlowAdjustmentLogicalId;
    callHedgeId: string;
    putHedgeId: string;
    putHedgeOffset: number;
    callHedgeOffset: number;
    isPinned: boolean;
}

export interface ExpirationDescriptor {
    seqNo?: number;
    date?: string;
    dow?: string;
    dte?: string;
}

export class PackageComparisonModel {

    constructor(packageId?: string) {
        this.packageId = packageId || getShortUUID();
    }

    private readonly _changeFlags: Set<RowFilterAttribute> = new Set<RowFilterAttribute>();
    private _latestSettings: CalculatePackageComparison;
    private _portfolio : ApgPortfolio;
    private _defaultQty : number;


    readonly packageId: string;

    name: string;

    get portfolio() : ApgPortfolio {
        return this._portfolio;
    }

    get defaultQty() : number {
        return this._defaultQty;
    }

    templateList: ICashFlowAdjustmentSettingsTemplate[];

    template: ICashFlowAdjustmentSettingsTemplate = {} as any;

    expirationList: { date: string; seqNo: number }[] = [];

    expiration: ExpirationDescriptor = {};

    adjustmentListCall: Adjustment[] = [];

    adjustmentListPut: Adjustment[] = [];

    adjustmentOne: Adjustment = {};

    adjustmentTwo: Adjustment = {};

    callHedgeList: { key: string, items: PricingGridStrategyDescriptor[] }[] = [];

    putHedgeList: { key: string, items: PricingGridStrategyDescriptor[] }[] = [];

    callHedgeOffset: number;

    callHedge: PricingGridStrategyDescriptor;

    putHedgeOffset: number;

    putHedge: PricingGridStrategyDescriptor;

    cashFlowAdjustments: CashFlowAdjustment[];

    hedgeTransactions: HedgeStateTransaction[];

    atmStrike: number;

    isPinned: boolean;

    shortOptionStrike : string;

    isFaulty = false;

    setPortfolio(portfolio: ApgPortfolio, defaultQty: number) {
        this._portfolio = portfolio;
        this._defaultQty = defaultQty;
    }

    getPackagePrice(): number {

        if (this.isFaulty) {
            return Number.NEGATIVE_INFINITY;
        }

        const adj = this.getAdjustmentsPrice();

        let cPrice = this.getCallHedgePrice() ;

        if (cPrice === null) {
            cPrice = 0;
        }

        let pPrice = this.getPutHedgePrice();

        if (pPrice === null) {
            pPrice = 0;
        }

        if (isNaN(pPrice)) {
            return NaN;
        }

        if (!isValidNumber(cPrice) || !isValidNumber(pPrice) || !isValidNumber(adj)) {
            return undefined;
        }

        const hedges = (cPrice || 0) + (pPrice || 0);

        return adj + hedges;
    }

    getAdjustmentsPrice(): number | undefined {

        if (this.isFaulty) {
            return Number.NEGATIVE_INFINITY;
        }

        if (isVoid(this.cashFlowAdjustments)) {
            return undefined;
        }

        const prices = this.cashFlowAdjustments
            .map(x => x.price);

        if (prices.some(x => !isValidNumber(x))) {
            return undefined;
        }

        const sum = prices.reduce((a, b) => a + b);

        return sum;
    }

    clone(): PackageComparisonModel {
        const model = new PackageComparisonModel();
        model.setPortfolio(this.portfolio, this._defaultQty);
        model.shortOptionStrike = this.shortOptionStrike;
        model.templateList = this.templateList;
        model.template = this.template;
        model.expirationList = this.expirationList;
        model.expiration = this.expiration;
        model.adjustmentListCall = this.adjustmentListCall;
        model.adjustmentOne = this.adjustmentOne;
        model.adjustmentListPut = this.adjustmentListPut;
        model.adjustmentTwo = this.adjustmentTwo;
        model.callHedgeList = this.callHedgeList;
        model.putHedgeList = this.putHedgeList;
        model.callHedge = this.callHedge;
        model.putHedge = this.putHedge;
        model.callHedgeOffset = this.callHedgeOffset;
        model.putHedgeOffset = this.putHedgeOffset;
        return model;
    }

    //@ts-ignore implicitly called
    getCallAdjTypeDisplayValue() {
        return this.adjustmentOne?.name ? this.adjustmentOne.name + '(C)' : undefined;
    }

    //@ts-ignore implicitly called
    getPutAdjTypeDisplayValue() {
        return this.adjustmentTwo?.name ? this.adjustmentTwo.name + '(P)' : undefined;
    }

    //@ts-ignore implicitly called
    hasHedges() {
        return !isVoid(this.hedgeTransactions)
            && this.hedgeTransactions.some(tr => !isVoid(tr.orderTransactions));
    }

    //@ts-ignore implicitly called
    hasAdjustment() {
        return !isVoid(this.cashFlowAdjustments);
    }

    toStateString() {
        const x =
            `id=${this.packageId}` +
            `pf=${this.portfolio?.id}@${this.portfolio?.userId};tpl=${this.template?.templateId};` +
            `exp=${this.expiration?.seqNo};adj1=${this.adjustmentOne?.name};adj2=${this.adjustmentTwo?.name};` +
            `ch=${this.callHedge?.strategyId}cho=${this.callHedgeOffset};ph=${this.putHedge?.strategyId};` +
            `pho=${this.putHedgeOffset}`;

        return x;
    }

    toDto() {
        const dto: PackageComparisonDto = {
            packageId: this.packageId,
            portfolioUserId: this.portfolio?.userId,
            portfolioId: this.portfolio?.id,
            templateId: this.template?.templateId,
            expirationSeqNo: this.expiration?.seqNo,
            adjustmentOne: this.adjustmentOne?.name,
            adjustmentTwo: this.adjustmentTwo?.name,
            callHedgeId: this.callHedge?.strategyId,
            callHedgeOffset: this.callHedgeOffset,
            putHedgeId: this.putHedge?.strategyId,
            putHedgeOffset: this.putHedgeOffset,
            isPinned: this.isPinned,
            name: this.name
        };

        return dto;
    }

    getUniqueId(): string {
        const x =
            `pf=${this.portfolio?.id}@${this.portfolio?.userId};tpl=${this.template?.templateId};` +
            `adj1=${this.adjustmentOne?.name};adj2=${this.adjustmentTwo?.name};` +
            `ch=${this.callHedge?.strategyId}cho=${this.callHedgeOffset};ph=${this.putHedge?.strategyId};` +
            `pho=${this.putHedgeOffset}`;

        return x;
    }

    getCallHedgePrice(): number {

        if (isVoid(this.hedgeTransactions)) {
            return null;
        }

        const hedgeStateTransaction = this.hedgeTransactions.find(x => x.side === 'Calls');

        const hedgePrice = this.getHedgePrice(hedgeStateTransaction);

        return hedgePrice;
    }

    getPutHedgePrice(): number {
        if (isVoid(this.hedgeTransactions)) {
            return null;
        }
        const hedgeStateTransaction = this.hedgeTransactions
            .find(x => x.side === 'Puts');

        const hedgePrice = this.getHedgePrice(hedgeStateTransaction);

        return hedgePrice;
    }

    setChangeFlag(filter: RowFilterAttribute) {
        this._changeFlags.add(filter);
    }

    removeChangeFlag(filter: RowFilterAttribute) {
        this._changeFlags.delete(filter);
    }

    hasChangeFlag(filter: RowFilterAttribute): boolean {
        return this._changeFlags.has(filter);
    }

    isValid(): string {
        if (isVoid(this.portfolio)) {
            return 'Portfolio Not Set';
        }

        if (isVoid(this.template)) {
            return 'Template Not Set';
        }

        if (!isValidNumber(this.expiration?.seqNo, true)) {
            return 'Expiration Not Set';
        }

        if (isVoid(this.adjustmentOne?.name)) {
            return 'Call Adjustment Not Set';
        }

        if (isVoid(this.adjustmentTwo?.name)) {
            return 'Put Adjustment Not Set';
        }

        if (isVoid(this.callHedge?.strategyId)) {
            if (isValidNumber(this.callHedgeOffset)) {
                return 'Call Hedge Offset Not Applicable';
            }
        } else {
            if (!isValidNumber(this.callHedgeOffset)) {
                return 'Call Hedge Offset Not Set';
            }
        }

        if (isVoid(this.putHedge?.strategyId)) {
            if (isValidNumber(this.putHedgeOffset)) {
                return 'Put Hedge Offset Not Applicable';
            }
        } else {
            if (!isValidNumber(this.putHedgeOffset)) {
                return 'Put Hedge Offset Not Set';
            }
        }

        return undefined;
    }

    resetChangeFlags() {
        this._changeFlags.clear();
    }

    hasDataChanges() {
        return this._changeFlags.size != 0;
    }

    setLatestSettings(cmd: CalculatePackageComparison) {
        this._latestSettings = cmd;
    }

    getLatestSettings() {
        return this._latestSettings;
    }

    private getHedgePrice(hedgeStateTransaction: HedgeStateTransaction) {

        if (this.isFaulty) {
            return Number.NEGATIVE_INFINITY;
        }


        if (isVoid(hedgeStateTransaction)) {
            return null;
        }

        if (isVoid(hedgeStateTransaction.orderTransactions)) {
            return null;
        }

        const hedgeTotal = hedgeStateTransaction.orderTransactions
            .map(ot => {
                const total = getOrderGroupTotal(ot, this._defaultQty);
                return total;
            })
            .reduce((x, y) => {
                if (!isValidNumber(x) || !isValidNumber(y)) {
                    return undefined;
                }
                return (x || 0) + (y || 0);
            }, 0);

        return hedgeTotal;
    }
}


import {Injectable} from '@angular/core';
import {HedgePositionsService} from "../positions-section/hedge-positions/hedge-positions.service";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {
    isVoid, makePortfolioKey,
} from "../../utils";
import {OptionsChainService} from "../../option-chains.service";
import {ApgDataService} from "../../adjustment-pricing-grid/services/apg-data.service";
import {LastQuoteCacheService} from "../../last-quote-cache.service";
import {HedgeMatrixDataService} from "./hedge-matrix-data.service";

@Injectable()
export class HedgeMatrixDataServiceFactory {

    constructor(
        private readonly _hedgePositionsService: HedgePositionsService,
        private readonly _optionChainService: OptionsChainService,
        private readonly _apgDataService: ApgDataService,
        private readonly _lastQuoteCache: LastQuoteCacheService
    ) {
    }

    private _portfolioDataServices: Record<string, HedgeMatrixDataService> = {};

    getPortfolioDataService(portfolio: ApgPortfolio) {
        if (isVoid(portfolio)) {
            return null;
        }

        const pfKey = makePortfolioKey(portfolio);

        let dataService = this._portfolioDataServices[pfKey];

        if (isVoid(dataService)) {
            dataService = new HedgeMatrixDataService(
                portfolio,
                this._optionChainService,
                this._lastQuoteCache,
                this._apgDataService,
                this._hedgePositionsService
            );
            this._portfolioDataServices[pfKey] = dataService;
        }

        return dataService;
    }
}
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {OrdersVerificationDialogConfig} from "./orders-verification-dialog.config";
import {DetectMethodChanges, DetectSetterChanges, findHCF, isVoid} from "../../utils";
import {ComboHighlightedItem} from "../../ui-messages/ui-messages";
import {LastQuoteCacheService} from "../../last-quote-cache.service";
import {MessageBusService} from "../../message-bus.service";
import {Subscription} from "rxjs";
import {QuoteDto} from "../../shell-communication/dtos/quote-dto.class";

@Component({
    selector: 'ets-orders-verification-dialog',
    templateUrl: 'orders-verification-dialog.component.html',
    styleUrls: ['orders-verification-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrdersVerificationDialogComponent implements OnInit {

    constructor(
        private _changeDetector: ChangeDetectorRef,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _messageBus: MessageBusService
    ) {
    }

    private _resolve: (value?: (PromiseLike<unknown> | unknown)) => void;
    private _reject: (reason?: any) => void;
    private _config: OrdersVerificationDialogConfig;
    private readonly _subscriptions: Subscription[] = [];

    title = 'Order Verification Dialog';

    hcf: number = 1;

    transPrice: number;

    transMarketValue: number;

    get height() : number {
        let base = 300;
        const row = this._config?.orderLegs?.length || 0;
        const extraRows = row - 1;

        if (extraRows <= 0) {
            return base;
        }

        let height = base + extraRows * 35;

        if (height > 800) {
            height = 800;
        }
        return height;
    }

    private _isLoading : boolean = false;
    get isLoading() : boolean {
        return this._isLoading;
    }

    @DetectSetterChanges()
    set isLoading(v : boolean) {
        this._isLoading = v;
    }

    isVisible : boolean;

    get ledgerPrice() : number {
        return this._config?.ledgerPrice();
    }

    get orderLegs() : ComboHighlightedItem[] {
        const legs = this._config?.orderLegs || [];
        return legs;
    }

    get defaultQty() : number {
        return this._config?.defaultQty;
    }

    ngOnInit() {
    }

    @DetectMethodChanges()
    show(config: OrdersVerificationDialogConfig) : Promise<void> {

        this._config = config;

        this._config.orderLegs.forEach(leg => {
            this._lastQuoteCache.subscribeIfNotYet(leg.ticker);
            const lastQuote = this._lastQuoteCache.getLastQuote(leg.ticker);
            leg.price = lastQuote?.mid;
        });

        this.hcf = findHCF(this._config.orderLegs.map(x => x.netPosition));

        this._subscriptions.push(
            this._messageBus.of<QuoteDto[]>('QuoteDto')
                .subscribe(msg => this.onQuote(msg.payload))
        );

        this.transPrice = this._config.orderPrice;

        this.updateTransMarketValue();

        this.isVisible = true;

        return new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }

    @DetectMethodChanges()
    onHidden() {
        this.isVisible = false;
        this._subscriptions.forEach(x => x.unsubscribe());
        this._subscriptions.length = 0;
        this._reject = null;
        this._resolve = null;
    }

    @DetectMethodChanges()
    onOkClicked() {
        this._resolve();
        this.isVisible = false;
    }

    @DetectMethodChanges()
    onCancelClicked() {
        this._reject();
        this.isVisible = false;
    }

    private onQuote(payload: QuoteDto[]) {
        let hasMatch = false;
        this._config?.orderLegs?.forEach(leg => {
            payload.forEach(q => {
                if (q.ticker !== leg.ticker) {
                    return;
                }
                leg.price = q.mid;
                hasMatch = true;
            });
        });
        if (!hasMatch) {
            return;
        }
        this.updateTransPrice();
        this._changeDetector.detectChanges();
    }

    @DetectMethodChanges()
    private updateTransPrice() {
        const totalPx = this._config.orderLegs.map(leg =>  leg.netPosition * leg.side * -1 * leg.price )
            .reduce((a,b) => a + b, 0);
        const transPrice = totalPx / this.hcf;
        this.transPrice = transPrice;

        this.updateTransMarketValue();
    }

    @DetectMethodChanges()
    private updateTransMarketValue() {
        this.transMarketValue = this.transPrice * this.hcf * 100;
    }
}
import {AfterViewInit, ChangeDetectorRef, Component} from "@angular/core";
import {ICellEditorAngularComp} from "ag-grid-angular";
import {OptionsChainService} from "../../option-chains.service";
import {OptionExpirationDescriptor} from "../../shell-communication/shell-dto-protocol";
import {
    DetectMethodChanges,
    DxValueChanged,
    isVoid,
} from "../../utils";
import {GridApi} from "ag-grid-community";
import {HgHedgeMatrixComponent} from "./hg-hedge-matrix.component";
import {DateTime} from "luxon-business-days";

@Component({
    selector: 'ets-hedge-expiration-selector',
    template: `
      <div>
        <dx-select-box [items]="this.expirationList"
                       [(value)]="this.selectedExpiration"
                       displayExpr="dateWithDaysToExpiration"
                       (onValueChanged)="this.onExpirationChanged($event)"
        ></dx-select-box>
      </div>

    `,
    styles: [``],
})
export class HedgeExpirationCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _optionsChainService: OptionsChainService,
    ) {
    }

    selectedExpiration: OptionExpirationDescriptor;

    expirationList: OptionExpirationDescriptor[] = [];

    api: GridApi;

    comp: HgHedgeMatrixComponent;

    hedgeId: string;

    // don't use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
    }

    getValue() {
        return undefined;
    }

    @DetectMethodChanges({isAsync: true})
    async agInit(params: any): Promise<void> {
        this.api = params.api;

        this.comp = params.etsComponent;

        this.hedgeId = params.hedgeId;

        const hedgeData = this.comp.hedgeMatrixDataService.getHedge(
            this.hedgeId
        );

        const chain = await this._optionsChainService.getChain('SPX');

        this.expirationList = chain.expirations.slice();

        const hedgeExpirations = this.comp.hedgeMatrixDataService
            .getHedgeExpirations(this.hedgeId)
            .sort();

        const isTemplated = !isVoid(hedgeData.template);

        if (isTemplated) {

            const hasNegativeOffsets = hedgeData.template.strategyLegs
                .filter(x => x.type === 'leg')
                .some(x => x.expirationOffset < 0);

            if (hasNegativeOffsets) {

                const offsets = hedgeData.template.strategyLegs
                    .filter(x => x.type === 'leg')
                    .map(x => x.expirationOffset || 0);

                const minOffset = Math.min(...offsets);

                const closestExpiration = this.expirationList[0];

                const closesExpirationDate = DateTime.fromISO(closestExpiration.optionExpirationDate);

                const thresholdExpirationDate =
                    closesExpirationDate.plusBusiness({days: Math.abs(minOffset)});

                const diff = thresholdExpirationDate
                    .diff(closesExpirationDate, 'days')
                    .toObject().days;

                const adjustedDte = closestExpiration.daysToExpiration + diff;

                this.expirationList = this.expirationList
                    .filter(x => x.daysToExpiration >= adjustedDte);
            }
        }

        let currentExpiration = hedgeExpirations[0];

        if (hedgeData.anchorExpiration) {
            currentExpiration = hedgeData.anchorExpiration.optionExpirationDate;
        }

        if (isVoid(currentExpiration)) {
            return;
        }

        this.selectedExpiration = chain.expirations
            .find(x => x.optionExpirationDate === currentExpiration);
    }

    isPopup(): boolean {
        return true;
    }

    getPopupPosition() {
        return 'under';
    }

    async onExpirationChanged($event: DxValueChanged<OptionExpirationDescriptor>) {
        if (!$event.event) return;

        const isoExpiration = $event.value.optionExpirationDate;

        const hedgeData = this.comp.hedgeMatrixDataService
            .getHedge(this.hedgeId);

        if (hedgeData) {
            hedgeData.initialExpiration = isoExpiration;
        }

        await this.comp.onNewHedgeExpirationChanged(this.hedgeId, isoExpiration);

        this.api.stopEditing();

        setTimeout(() => this.api.refreshCells({force: true}));
    }
}
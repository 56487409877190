import { Component, OnInit, OnDestroy, Inject, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { ToastrService } from 'ngx-toastr';
import { AccessControlService } from 'projects/shared-components/access-control-service.class';
import { Subject } from 'rxjs';
import { environment } from 'projects/shared-components/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { SetShellBaseTime } from 'projects/shared-components/shell-communication/operations/shell/set-shell-base-time.class';
import { formatDate } from '@angular/common';
import { DismissToken } from 'projects/shared-components/authentication/operations/dismiss-token.class';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { AuthServiceClientService } from 'projects/shared-components/authentication/auth-service-client.service';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { TimeZoneFamily } from 'projects/shared-components/timezones/time-zone-family.interface';
import { TimeZoneDescriptor } from 'projects/shared-components/timezones/time-zone-descriptor.interface';
import { TIME_ZONE_FAMILIES } from 'projects/shared-components/timezones/time-zones.const';
import { DateTime } from 'luxon';
import { TimestampsService } from 'projects/shared-components/timestamps.service';
import { ServerTimeMessageDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, DetectSetterChanges } from 'projects/shared-components/utils';


interface ServerTimePanelSecurityContext {
   serverTimeIndicator: boolean;
   canChangeServerTime: boolean;
   loginInfoPanel: boolean;
}

@Component({
   selector: 'ets-server-time-panel',
   templateUrl: 'server-time-panel.component.html',
   styleUrls: ['server-time-panel.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServerTimePanelComponent implements OnInit, OnDestroy, AfterViewInit {

   public constructor(
      private _messageBus: MessageBusService,
      private _shellClient: ShellClientService,
      private _authClient: AuthServiceClientService,
      private _toastr: ToastrService,
      private _accessControlService: AccessControlService,
      private _sessionService: SessionService,
      private _timestampsService: TimestampsService,
      private _changeDetector: ChangeDetectorRef
   ) {
      this._unsubscriber = new Subject<any>();
   }

   public get canAdjustTime(): boolean {
      return !!this.selectedTimeZoneFamily && !!this.selectedTimeZoneDescriptor;
   }

   private readonly _unsubscriber: Subject<any>;

   public dateTime = '-- : -- : --';

   public securityContext: ServerTimePanelSecurityContext;
   public productVersion: string;
   public shellTimeValue: ServerTimeMessageDto;
   public timeZoneFamilies: TimeZoneFamily[];
   public baseDate: string;
   public isBaseDateValid: boolean;
   public loginInfo: { userId?: string, terminal?: string, shell?: string };
   
   private _timeZone: string;
   get timeZone(): string { return this._timeZone; }
   
   @DetectSetterChanges()
   set timeZone(value: string) { 
      this._timeZone = value;
   }
   
   private _selectedTimeZoneFamily: TimeZoneFamily;
   get selectedTimeZoneFamily(): TimeZoneFamily { return this._selectedTimeZoneFamily; }
   
   @DetectSetterChanges()
   set selectedTimeZoneFamily(value: TimeZoneFamily) {
      this._selectedTimeZoneFamily = value;
   }
   


   private _selectedTimeZoneDescriptor: TimeZoneDescriptor;
   get selectedTimeZoneDescriptor(): TimeZoneDescriptor {
      return this._selectedTimeZoneDescriptor;
   }

   @DetectSetterChanges()
   set selectedTimeZoneDescriptor(value: TimeZoneDescriptor) {
      this._selectedTimeZoneDescriptor = value;
    }
   


   private _isLogoutConfirmVisible: boolean;
   get isLogoutConfirmVisible(): boolean { return this._isLogoutConfirmVisible; }
   
   @DetectSetterChanges()
   set isLogoutConfirmVisible(value: boolean) {
      this._isLogoutConfirmVisible = value;
   }
   

   private _isSettingsWindowVisible: boolean;
   get isSettingsWindowVisible(): boolean { return this._isSettingsWindowVisible; }
   
   @DetectSetterChanges()
   set isSettingsWindowVisible(value: boolean) {
      this._isSettingsWindowVisible = value;
   }
   
   private  _isLoading: boolean;
   get isLoading(): boolean { return this._isLoading; }
   
   @DetectSetterChanges()
   set isLoading(value: boolean) {
      this._isLoading = value;
   }

   ngOnInit(): void {
      this.productVersion = environment.productVersion;

      this.loginInfo = {};

      const isAvailable = (id) =>
         this._accessControlService.isSecureElementAvailable(id);

      this.securityContext = {
         canChangeServerTime: isAvailable('b7ba0380-4020-45c6-81db-3d00c67eb207'),
         serverTimeIndicator: isAvailable('3493b0fe-0883-4122-8ff8-a9fdb12f24d2'),
         loginInfoPanel: isAvailable('0567f82d-de9a-4596-9c84-f8046da08e1b')
      };

      this._messageBus
         .of<ServerTimeMessageDto>('ServerTimeMessageDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => this.onServerTimeMessageDto(x.payload));

      this.timeZoneFamilies = TIME_ZONE_FAMILIES.slice();

      this.loginInfo.userId = this._sessionService.sessionData.userEmail;
      this.loginInfo.terminal = this._sessionService.loginResult.ownTerminal.displayName;
      this.loginInfo.shell = this._sessionService.connectedShell.displayName;
   }


   ngAfterViewInit(): void {
      this._changeDetector.detach();
   }


   ngOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }


   @DetectMethodChanges({isAsync: true})
   async onAdjustTimeClicked(): Promise<void> {
      const country = !!this.selectedTimeZoneFamily ? this.selectedTimeZoneFamily.displayName : null;
      const timezone = !!this.selectedTimeZoneDescriptor ? this.selectedTimeZoneDescriptor.nodaTimeZoneId : null;

      let dateTime: Date = new Date('invalid date');
      if (this.isBaseDateValid && this.baseDate) {
         dateTime = new Date(this.baseDate);
      }

      if (!country || !timezone) {
         return;
      }

      const cmd = new SetShellBaseTime(country, timezone, false, isNaN(dateTime as any) ? null : this.baseDate);

      this.isLoading = true;
      
      try {
         
         await this._shellClient.processCommand(cmd);
         this.onSettingsWindowClosed();

      } catch (e) {
         
         const data = e.stack || e;
         console.error('Failed to set server time', data);
         this._toastr.error('"Adjust Server Time" operation completed with errors');

      } finally {
         
         this.isLoading = false;

      }
   }


   @DetectMethodChanges()
   onBaseDateChanged(event) {
   
      const d: any = new Date(event.value);
   
      if (!isNaN(d)) {
         this.baseDate = event.value;
      } else {
         this.baseDate = null;
      }

   }


   @DetectMethodChanges({isAsync: true})
   async onResetTimeClicked(): Promise<void> {
      
      this.isLoading = true;
      
      try {
         
         const cmd = new SetShellBaseTime(null, null, true);
         await this._shellClient.processCommand(cmd);
         this.onSettingsWindowClosed();

      } catch (e) {
         
         const data = e.stack || e;
         console.error('Failed to reset server time', data);
         this._toastr.error('"Reset Time" operation completed with errors');

      } finally {
         
         this.isLoading = false;

      }
   }


   @DetectMethodChanges()
   showSettingsWindow() {
      
      const lastShellTime = this.shellTimeValue;

      if (lastShellTime.country) {
         const tzFamily = this.timeZoneFamilies.find(x => x.displayName === lastShellTime.country);

         if (tzFamily && lastShellTime.timeZone) {
            this.selectedTimeZoneFamily = tzFamily;
            const tzd = tzFamily.timeZoneDescriptors.find(x => x.nodaTimeZoneId === lastShellTime.timeZone);
            if (tzd) {
               this.selectedTimeZoneDescriptor = tzd;
            }
         }
      }

      const time = this.getCorrectedTime(lastShellTime.time, lastShellTime.timeZone);

      this.baseDate = time.toFormat('MM/dd/yyyy hh:mm:ss a');
      this.isSettingsWindowVisible = true;

      this.isBaseDateValid = !isNaN(lastShellTime.time as any);
   }


   @DetectMethodChanges()
   onSettingsWindowClosed() {
      this.isSettingsWindowVisible = false;
      this.baseDate = null;
      this.selectedTimeZoneDescriptor = null;
      this.selectedTimeZoneFamily = null;
   }


   @DetectMethodChanges()
   showLogoutDialog() {
      this.isLogoutConfirmVisible = true;
   }


   logoutUser() {
      
      this.isLoading = true;
   
      setTimeout(async () => {

         try {
            const authToken = this._sessionService.authToken;
            
            this._messageBus.publish({
               topic: 'AuthTokenExpiredUIMessage',
               payload: {
                  doNotShowLogoutScreen: false,
                  source: 'LogoutButton'
               }
            });
            
            if (authToken) {
               const cmd = new DismissToken(authToken);
               await this._authClient.processCommand(cmd);
            } else {
               console.warn('Token was not dismissed at logout: empty token');
            }

         } catch (e) {
            
            console.error('Failed to logout', e);

         } finally {
            
            this.isLoading = false;

         }
      });
   }


   @DetectMethodChanges()
   private onServerTimeMessageDto(message: ServerTimeMessageDto) {
      
      const dz = this._timestampsService.getDefaultTimezone('UTC');

      if (message.isFakeTime) {
         
         const receivedDate: DateTime = this.getCorrectedTime(message.time, message.timeZone);
         this.dateTime = receivedDate.toFormat('ccc dd-MMM-yy hh:mm:ss a');
         this.timeZone = message.timeZone ? `(${message.timeZone}) *` : ` (UTC) *`;
         this.shellTimeValue = message;

      } else {
         
         let zonedDatetime = DateTime.fromJSDate(message.time);
         zonedDatetime = zonedDatetime.setZone(dz);
         this.dateTime = zonedDatetime.toFormat('ccc dd-MMM-yy hh:mm:ss a');
         this.timeZone = ` (${dz})`;
         this.shellTimeValue = message;

      }
   }

   
   private getCorrectedTime(time: Date, timeZone: string): DateTime {
      let theDate = DateTime.fromJSDate(time, { zone: 'UTC' });

      if (timeZone) {
         theDate = theDate.setZone(timeZone, { keepLocalTime: true });
      } else {
         theDate = theDate.setZone('UTC', { keepLocalTime: true });
      }

      return theDate;
   }
}

import {ChangeDetectorRef, EventEmitter} from "@angular/core";
import {DetectSetterChanges, getShortUUID, isValidNumber, isVoid} from "../../utils";
import {HedgePosition} from "../../hedging-grid/data-model/hedge-position";
import {GridSettingsProvider} from "./grid-settings.provider";
import {makeOptionTicker, OptionType} from "../../options-common/options.model";
import {findOffsetExpiration} from "../../hedging-grid/hedge-matrix/hedge-matrix-data.service";

export class CustomHedgeLeg {

    constructor(private readonly _changeDetector: ChangeDetectorRef,
                private readonly _targetSide: 'calls' | 'puts',
                private readonly _gridSettingsProvider: GridSettingsProvider
    ) {
    }

    propertChanged$ = new EventEmitter();

    private _qty: number;
    get qty(): number {
        return this._qty;
    }
    @DetectSetterChanges()
    set qty(value: number) {
        if (this._qty === value) {
            return;
        }
        this._qty = value;
        this.propertChanged$.emit();
    }


    private _side: any;
    get side(): any {
        return this._side;
    }
    @DetectSetterChanges()
    set side(value: any) {
        if (this._side === value) {
            return;
        }
        this._side = value;
        this.propertChanged$.emit();
    }

    private _strike: number;
    get strike(): number {
        return this._strike;
    }
    @DetectSetterChanges()
    set strike(value: number) {
        if (this._strike === value) {
            return;
        }
        this._strike = value;
        this.propertChanged$.emit();
    }

    private _expirationOffset: number | undefined;
    get expirationOffset() : number | undefined {
        return this._expirationOffset;
    }

    @DetectSetterChanges()
    set expirationOffset(value: number | undefined) {
        if (this._expirationOffset === value) return;
        this._expirationOffset = value;
        this.propertChanged$.emit();
    }

    asHedgePosition(): HedgePosition {

        if (!isValidNumber(this.qty, true)) {
            return undefined;
        }

        if (isVoid(this.side)) {
            return undefined;
        }

        if (!isValidNumber(this.strike, true)) {
            return undefined;
        }

        let baseExpiration = this._gridSettingsProvider.expiration;

        if (isValidNumber(this.expirationOffset, true)) {
            const offsetExpiration = findOffsetExpiration(baseExpiration,
                this.expirationOffset,
                this._gridSettingsProvider.optionChain);

            if (isVoid(offsetExpiration)) {
                return null;
            }

            baseExpiration = offsetExpiration;
        }

        const ticker = makeOptionTicker(baseExpiration,
            this._targetSide === 'calls' ? OptionType.Call : OptionType.Put,
            this.strike
        );

        const qty = this.qty * this.side.value;

        const strike = this.strike;

        const groupId = getShortUUID();

        const type = this._targetSide === 'calls' ? 'Call' : 'Put';

        const asset = this._gridSettingsProvider.expiration?.underlyingTicker;

        const expiration = this._gridSettingsProvider.expiration.optionExpirationDate;

        return {
            ticker,
            qty,
            strike,
            groupId,
            type,
            asset,
            expiration
        };
    }
}
import {HedgePositionTransaction} from "./data-model/hedge-position-transaction";
import {HedgePositionGroup} from "./data-model/hedge-position-group";
import {findHCF, isValidNumber, isVoid} from "../utils";
import {HedgeOrderGroup} from "./data-model/hedge-order-group";
import {HedgeOrder} from "./data-model/hedge-order";

export function getSubTotals(transType: 'current' | 'new', transactions: HedgePositionTransaction[], defaultQty?: number): number {

    let selectorFunc: (x: HedgePositionTransaction) => HedgePositionGroup;

    if (transType === 'current') {
        selectorFunc = (x) => x.beforeState;
    } else if (transType === 'new') {
        selectorFunc = (x) => x.afterState;
    } else {
        console.error('unknown trans type: ' + transType);
        return undefined;
    }

    const posGroup = transactions.map(selectorFunc)
        .filter(x => !isVoid(x))
        .filter(x => !isVoid(x.positions));

    let sum = posGroup
        .flatMap(grp => {

            if (isVoid(grp.hcf)) {
                const qtties = grp.positions.map(x => Math.abs(x.qty));
                const hcf = findHCF(qtties);
                grp.hcf = hcf;
            }

            let ratio = 1;

            if (isValidNumber(defaultQty, true)) {
                ratio = grp.hcf / defaultQty;
            }

            let sums = grp.positions.map(pos => {
                if (!isValidNumber(pos.price)) {
                    return undefined;
                }
                const px = pos.price || 0;
                const qx = Math.abs(pos.qty);
                let result = px * qx;
                return result;
            }).reduce((a, b) => a + b, 0);

            sums = sums / grp.hcf;

            if (isValidNumber(ratio, true)) {
                sums = sums * ratio;
            }

            return sums;
        })
        .reduce((a, b) => a + b, 0);

    if (!isValidNumber(sum)) {
        return undefined;
    }

    return sum;

}

export function getGroupTotal(group: HedgePositionGroup, defaultQty?: number): number {

    if (isVoid(group) || isVoid(group.positions)) {
        return undefined;
    }

    if (isVoid(group.hcf)) {
        const qtties = group.positions.map(x => Math.abs(x.qty));
        const hcf = findHCF(qtties);
        group.hcf = hcf;
    }

    let sum = group.positions
        .map(x => {
            if (!isValidNumber(x.price, true)) {
                return undefined;
            }
            const px = x.price;
            const qx = Math.abs(x.qty);
            let result = px * qx;
            return result;
        })
        .reduce((a, b) => a + b, 0);

    sum = sum / group.hcf;

    if (isValidNumber(defaultQty, true)) {
        const ratio = group.hcf / defaultQty;
        console.assert(isValidNumber(ratio, true), 'ratio is not valid');
        sum = sum * ratio;
    }


    if (!isValidNumber(sum)) {
        return undefined;
    }

    return sum;
}

export function getOrderGroupTotal(orderGroup: HedgeOrderGroup, defaultQty?: number) {

    if (isVoid(orderGroup) || isVoid(orderGroup.orders)) {
        return undefined;
    }

    if (isVoid(orderGroup.hcf)) {
        const qtties = orderGroup.orders.map(x => Math.abs(x.qty));
        const hcf = findHCF(qtties);
        orderGroup.hcf = hcf;
    }

    const ratio = orderGroup.hcf / defaultQty;

    let total = orderGroup.orders
        .map(x => {

            if (!isValidNumber(x.price, true)) {
                return undefined;
            }

            const px = x.price;

            const qx = Math.abs(x.qty);

            let result = px * qx;

            // if (isValidNumber(defaultQty, true)) {
            //     console.assert(isValidNumber(ratio, true), 'ratio is not valid');
            //     result = result * ratio;
            // }

            return result;

        })
        .reduce((x, y) => x + y, 0);

    total = total / orderGroup.hcf;

    if (isValidNumber(defaultQty, true)) {
        console.assert(isValidNumber(ratio, true), 'ratio is not valid');
        total = total * ratio;
    }

    if (!isValidNumber(total)) {
        return undefined;
    }

    return total;
}

export function getOrderGroupSubTotal(orders: HedgeOrder[], defaultQty?: number) {

    if (isVoid(orders)) {
        return undefined;
    }

    if (isVoid(orders[0].hcf)) {
        const qtties = orders.map(x => Math.abs(x.qty));
        const hcf = findHCF(qtties);
        orders.forEach(o => o.hcf = hcf);
    }

    let total = orders
        .map(x => {
            if (!isValidNumber(x.price, true)) {
                return undefined;
            }
            const px = x.price || 0;
            const qx = Math.abs(x.qty);
            let result = px * qx;
            return result;
        })
        .reduce((x, y) => (x || 0) + (y || 0), undefined);

    total = total / orders[0].hcf;

    if (isValidNumber(defaultQty, true)) {
        const ratio = orders[0].hcf / defaultQty;
        console.assert(isValidNumber(ratio, true), 'ratio is not valid');
        total = total * ratio;
    }

    if (!isValidNumber(total)) {
        return undefined;
    }

    return total;
}
import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency} from "@angular/common";
import {isNullOrUndefined, isValidNumber} from "../utils";

@Pipe({
    name: 'etsPkgPrice'
})

export class PackageComparisonPricePipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (value === Number.NEGATIVE_INFINITY) {
            return 'N/A';
        }

        if (!isValidNumber(value)) {
            return null;
        }

        const result = formatCurrency(value, 'en-US', '$', 'USD', '1.2-2');

        return result;
    }
}
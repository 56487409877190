import {ICellRendererAngularComp} from "ag-grid-angular";
import {IAfterGuiAttachedParams, ICellRendererParams} from 'ag-grid-community';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from "@angular/core";
import {
    defaultCurrencyFormatter,
    DetectMethodChanges,
    isOptionExpired,
    isValidNumber,
    isVoid,
    makeDayOfWeekDate
} from "../../utils";
import {getExpirationCellDataIndex} from "./hedge-matrix-grid-options";
import {HedgeMatrixRow, HgHedgeMatrixComponent} from "./hg-hedge-matrix.component";
import {HedgeData} from "./hedge-data";

import {HedgeMatrixCellData} from "./hedge-matrix-cell.data";

interface ComponentParams {
    etsComponent: HgHedgeMatrixComponent
    hedge: HedgeData,
    field: keyof HedgeMatrixCellData
}

@Component({
    selector: "ets-multiple-expiration-cell-renderer",
    template: `
      <div class="cell">
        <div class="block">{{ this.value }}</div>
        <div class="block dow">{{this.dayOfWeekString}}</div>
      </div>
    `,
    styles: [`
        .cell {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        .block {
            text-align: center;
        }
        
        .dow {
            font-size: 0.7em;
            position: relative;
            right: -6px;
            top: -6px;
            flex: 0;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiExpirationCellRenderer implements ICellRendererAngularComp {

    constructor(
        private _changeDetector: ChangeDetectorRef,
    ) {
    }


    private _params: (ICellRendererParams & ComponentParams);

    dayOfWeek: string;

    value: string;

    get dayOfWeekString() : string {
        return isVoid(this.value) ? undefined : this.dayOfWeek;
    }

    @DetectMethodChanges()
    agInit(params: (ICellRendererParams & ComponentParams)): void {
        this._params = params;
    }

    @DetectMethodChanges()
    refresh(): boolean {
        try {

            const params = this._params;

            const data: HedgeMatrixRow = params.data;

            const comp = params.etsComponent;

            const strike = data.strike;

            const hedgeId = params.hedge.id;

            const qtyField = params.field;

            const cellData = params.etsComponent.hedgeMatrixDataService.getCellData(
                strike,
                hedgeId
            );

            if (isVoid(cellData)) {
                this.value = undefined;
                this.dayOfWeek = undefined;
                return true;
            }

            const expirationCellDataIndex = getExpirationCellDataIndex(
                strike,
                params.api,
                params.node
            );

            const expirationCellData = cellData[expirationCellDataIndex];

            if (isVoid(expirationCellData)) {
                this.value = undefined;
                this.dayOfWeek = undefined;
                return true;
            }

            this.dayOfWeek = makeDayOfWeekDate(expirationCellData.expiration);

            const qty = expirationCellData[qtyField] as number;

            if (!isValidNumber(qty)) {
                this.value = undefined;
                this.dayOfWeek = undefined;
                return true;
            }

            let ticker = expirationCellData.ticker;

            if (isVoid(ticker)) {

                ticker = comp.hedgeMatrixDataService.getTickerForCell(
                    strike, expirationCellData.expiration,
                    expirationCellData.optionType
                );

                if (isVoid(ticker)) {
                    this.value = qty + '';
                    return;
                }

                expirationCellData.ticker = ticker;
            }

            const lastQuote = comp.lastQuoteCache.getLastQuote(ticker);

            const isExpired = isOptionExpired(ticker);


            if (isVoid(lastQuote)) {
                if (!isExpired) {
                    this.value = qty+'';
                    return true;
                }
            }

            let sign = '';

            if (qty > 0) {
                sign = '+';
            }

            const quote = isExpired ? undefined : defaultCurrencyFormatter(lastQuote.mid);

            this.value =  isExpired ?  `EXP (${sign}${qty}) ` : `${quote} (${sign}${qty}) `;

        } catch {}

        return true;
    }

    @DetectMethodChanges()
    afterGuiAttached(params?: IAfterGuiAttachedParams) {
    }
}
import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {
    DetectMethodChanges,
    DxValueChanged,
    isVoid,
} from "../../../utils";
import {ExpirationDescriptor, HgHedgeMatrixComponent} from "../hg-hedge-matrix.component";

@Component({
    selector: 'ets-hg-hedge-matrix-hedges-selector',
    templateUrl: 'hg-hedge-matrix-hedges-selector.component.html',
    styleUrls: ['hg-hedge-matrix-hedges-selector.component.scss']
})

export class HgHedgeMatrixHedgesSelectorComponent implements OnInit, AfterViewInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef
    ) {
    }

    ngAfterViewInit(): void {
    }

    cmp: HgHedgeMatrixComponent;

    visible: boolean;

    shortView = false;

    height = 480;

    width = 650;

    shortViewSide = 'pricing';

    get allHedgesSelected(): boolean {
        return this.cmp.getColumnSelectorTextHedges() === 'All Hedges Selected';
    }

    get allExpirationsSelected(): boolean {
        return this.cmp.getColumnSelectorTextExpirations() === 'All Expirations Selected';
    }

    get allPnlExpirationsSelected() {
        return this.cmp.pnlExpirationsList.every(x => {
            return this.isPnlExpirationSelected(x, false);
        });
    }

    get callHedgeList(): any[] {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.hedgeList.filter(x => x.side === 'Call');
    }

    get putHedgeList(): any[] {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.hedgeList.filter(x => x.side === 'Put');
    }

    get callExpirationList(): any[] {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.expirationList.filter(x => x.side === 'Call');
    }

    get putExpirationList(): any[] {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.expirationList.filter(x => x.side === 'Put');
    }

    get callPnlExpirationList(): any[] {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.pnlExpirationsList.filter(x => x.side === 'Call');
    }

    get putPnlExpirationList(): any[] {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.pnlExpirationsList.filter(x => x.side === 'Put');
    }

    get expirationList(): Partial<ExpirationDescriptor>[] {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.uniqueExpirationList;
    }

    ngOnInit() {

    }

    @DetectMethodChanges()
    show(p: HgHedgeMatrixComponent) {
        this.cmp = p;
        this.visible = true;
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
    }

    onHedgeSelectionChanged($event: DxValueChanged<boolean>, hedge) {
        if (!$event.event) {
            return;
        }
        this.cmp.onHedgeSelectionChanged(hedge);
    }

    onExpirationSelectionChanged($event: DxValueChanged<boolean>, expiration) {
        if (!$event.event) {
            return;
        }
        this.cmp.onExpirationSelectionChange(expiration);
    }

    @DetectMethodChanges()
    toggleSelectAllHedges($event: DxValueChanged<boolean>) {
        if (isVoid($event.event)) {
            return;
        }
        setTimeout(() => {
            this.cmp.toggleSelectAllHedges($event);
        });
    }

    toggleSelectAllExpirations(event: DxValueChanged<boolean>) {
        if (isVoid(event.event)) {
            return;
        }
        setTimeout(() => {
            this.cmp.toggleSelectAllExpirations(event);
        });
    }

    isHedgeSelected(hedge: any): boolean {
        return this.cmp.isHedgeSelected(hedge);
    }

    isExpirationSelected(hedge: any): boolean {
        return this.cmp.isExpirationSelected(hedge);
    }

    onPnlExpirationSelectionChanged($event: any, expiration: any, allInclusive: boolean) {
        if (!$event.event) {
            return;
        }
        this.cmp.onPnlExpirationSelectionChanged(expiration, null, allInclusive);
    }

    isPnlExpirationSelected(expiration: any, allInclusive: boolean) {
        return this.cmp.isPnlExpirationSelected(expiration, allInclusive);
    }

    toggleSelectAllPnlExpirations($event: any) {
        if (isVoid($event.event)) {
            return;
        }
        setTimeout(() => {
            this.cmp.toggleSelectAllPnlExpirations($event);
        });
    }


    onGrandTotalExpirationSelectionChanged($event: any, item: any) {
        if (isVoid($event.event)) {
            return;
        }
        this.cmp.onGrandTotalExpirationSelectionChanged(item);
    }

    isGrandTotalExpirationSelected(item: any) {
        return this.cmp.isGrandTotalExpirationSelected(item);
    }

    onResizeEnd(args: any) {
        // this.shortView = args.width <= 360;
    }

    @DetectMethodChanges()
    onResize(args: any) {
        const width = args.actionValue[0].width;
        this.shortView = width <= 360;
    }

    @DetectMethodChanges()
    shrinkWindow() {
        this.shortView = true;
        this.width = 315;
    }

    @DetectMethodChanges()
    expandWindow() {
        this.shortView = false;
        this.width = 650;
    }

    @DetectMethodChanges()
    switchView() {
        if (this.shortViewSide === 'pnl') {
            this.shortViewSide = 'pricing';
        } else {
            this.shortViewSide = 'pnl';
        }
    }

    isPnlSideVisible() {
        return !this.shortView || (this.shortViewSide === 'pnl' && this.shortView)
    }

    isPricingSideVisible() {
        return !this.shortView || (this.shortViewSide === 'pricing' && this.shortView)
    }

    @DetectMethodChanges()
    closeWindow() {
        this.visible = false;
    }

    onNormalizedExpirationPnlToggled($event: DxValueChanged<boolean>, item: Partial<ExpirationDescriptor>) {
        this.cmp.onNormalizedExpirationPnlToggled($event.value, item);
    }

    isNormalizedExpirationPnlEnabled(item: Partial<ExpirationDescriptor>): boolean {
        return this.cmp.isNormalizedExpirationPnlEnabled(item);
    }

    onToggleModifications(hedge: any, onOff: boolean) {
        this.cmp.toggleHedgeModifications(hedge, onOff);
    }
}
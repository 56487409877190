import {ChangeDetectorRef} from "@angular/core";
import {DetectMethodChanges, isVoid} from "../utils";
import {ToastrService} from "ngx-toastr";
import {OpgTemplate} from "./option-pricing-grid-templates.service";

export class OpgEditTemplatePopupModel {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _toastr: ToastrService
    ) {
    }

    private _onSave: (value?: (PromiseLike<any> | any)) => void;

    private _onCancel: (reason?: any) => void;

    get canChangeAssignable(): boolean {
        return this.title.indexOf('Edit') < 0;
    }

    visible = false;

    templateName: string;

    title = 'Save As Template';

    isAssignable = false;

    @DetectMethodChanges()
    show(title: string, existingTemplate?: OpgTemplate): Promise<{ templateName: string, isAssignable: boolean }> {

        this.title = title;

        this.visible = true;

        this.templateName = existingTemplate?.templateName;

        this.isAssignable = existingTemplate?.isAssignable || false;

        return new Promise((res, rej) => {
            this._onSave = res;
            this._onCancel = rej;
        });
    }

    @DetectMethodChanges()
    onSaveClicked() {
        if (isVoid(this.templateName)) {
            this._toastr.error('Provide template name');
            return;
        }
        this._onSave({templateName: this.templateName, isAssignable: this.isAssignable});
        this.visible = false;
    }

    @DetectMethodChanges()
    onCancelClicked() {
        this._onCancel();
        this.visible = false;
    }
}
import {
   Component,
   OnInit,
   AfterViewInit,
   OnDestroy,
   ViewChildren,
   QueryList,
   ChangeDetectionStrategy,
   ChangeDetectorRef,
   ViewChild
} from '@angular/core';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { ToastrService } from 'ngx-toastr';
import { AccessControlService } from 'projects/shared-components/access-control-service.class';
import { Subject } from 'rxjs';
import { SettingsStorageService } from '../../../../shared-components/settings-storage-service.service';
import { LayoutComponent } from '../../../../shared-components/layout/layout.component';
import { LayoutTab } from '../../../../shared-components/layout/layout-tabs/layout-tab';
import { WorkspaceComponent } from '../../../../shared-components/workspace/workspace.component';
import { TerminalExposureChangedUIMessage } from 'projects/shared-components/ui-messages/terminal-exposure-changed-ui-message.interface';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { ProtocolCommand } from 'projects/shared-components/service-model/protocol-command.interface';
import { MasterExit } from 'projects/shared-components/shell-communication/operations/group-exits/master-exit.class';
import { ILayoutSection } from '../../../../shared-components/layout/layout-section';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { CloseTerminalPositions } from 'projects/shared-components/shell-communication/operations/terminals/close-terminal-positions.class';
import {
   InitialWorkspaceInitializedUIMessage
} from 'projects/shared-components/ui-messages/initial-workspace-initialized-ui-message.interface';
import { takeUntil } from 'rxjs/operators';
import { ShellStatusPollingUIMessage } from 'projects/shared-components/ui-messages/shell-status-polling-ui-message.interface';
import { TerminalDto } from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import {
   EventStreamConnectionChangedUIMessage
} from 'projects/shared-components/ui-messages/event-stream-connection-changed-message-ui-message.interface';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';
import { LayoutsSortEventArgs } from 'projects/shared-components/layout/layout-sort/layouts-sort-event-args';
import {
   GetQuoteSubscriptionsCount, GetQuoteSubscriptionsCountResponse, GetRecentPivots, GetRecentPivotsReply,
   RequestLoginIssuesReport
} from 'projects/shared-components/shell-communication/shell-operations-protocol';
import {QuoteLimitDto, ServerTimeMessageDto} from 'projects/shared-components/shell-communication/shell-dto-protocol';
import {DetectMethodChanges, isVoid} from 'projects/shared-components/utils';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { DismissToken } from 'projects/shared-components/authentication/operations/dismiss-token.class';
import { AuthServiceClientService } from 'projects/shared-components/authentication/auth-service-client.service';
import { LocationService } from 'projects/shared-components/location.service';
import {
   NormalizingCalculatorComponent
} from "../../../../shared-components/options-pricing-grid/normalizing-calculator/normalizing-calculator.component";
import {EtsLinkClickedMessage} from "../host-menu/host-menu.component";
import {DxPopoverComponent, DxPopupComponent} from "devextreme-angular";

interface HostSecurityContext {
   currentMarketPrices: boolean;
   masterExit: boolean;
   terminalExit: boolean;
   sessionPnL: boolean;
   accumulatedPnL: boolean;
   gatewaysManager: boolean;
   menuButton: boolean;
   tradeButton: boolean;
   serverTimePanel: boolean;
   totalStrategiesIssues: boolean;
   sessionMessages: boolean;
   unaccountedData: boolean;
   jobsTracker: boolean;
}

function playSound() {
   var audio = document.getElementById("myAudio") as any;
   if (!isVoid(audio)) {
      audio.volume = 0.00001;
      audio.play();
   }
}


@Component({
   templateUrl: './host.component.html',
   styleUrls: ['./host.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebtraderHostComponent implements OnInit, AfterViewInit, OnDestroy {
   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _accessControlService: AccessControlService,
      private _shellServiceClient: ShellClientService,
      private _messageBus: MessageBusService,
      private _toastr: ToastrService,
      private _userSettingsService: SettingsStorageService,
      private _sessionService: SessionService,
      private _authClient: AuthServiceClientService,
      private _locationService: LocationService,
      loggerService: LoggerService
   ) {
      this._logger = loggerService.createLogger('HostComponent');
      this._unsubscriber = new Subject();
      this.essConnectionLostMessage =
         'WARNING! EventStream Service connection is lost. Restoring...';
      this.shellOfflineMessage =
         'WARNING! Shell you were logged in to is currently offline. Please, contact your system administrator';
   }

   private _unsubscriber: Subject<any> = new Subject<any>();
   private _logger: Logger;
   private _essDisconnectTimeout;

   get isSuperUser(): boolean {
      return this._sessionService.isSuperUser;
   }

   get isEtsHost(): boolean {
      return this._locationService.isEtsHost;
   }

   get layoutSectionStyle(): { width: string, height: string } {
      let height = 37;

      if (this.isTradingPadVisible) {
         height += 142;
      }
      if (this.isEventStreamConnectionLost) {
         height += 20;
      }
      if (this.isShellOffline) {
         height += 20;
      }
      return { width: '100%', height: `calc(100% - ${height}px` };
   }

   @ViewChildren(WorkspaceComponent) layoutChildren: QueryList<LayoutComponent>;

   layouts: LayoutTab[] = [];

   isTradingPadVisible: boolean;

   essConnectionLostMessage: string;

   shellOfflineMessage: string;

   securityContext: HostSecurityContext;

   @ViewChild(NormalizingCalculatorComponent)
   normalizingCalculator: NormalizingCalculatorComponent;

   //

   private _isEventStreamConnectionLost: boolean;
   
   get isEventStreamConnectionLost(): boolean { 
      return this._isEventStreamConnectionLost; 
   }
   
   set isEventStreamConnectionLost(value: boolean) {
      
      if (this._isEventStreamConnectionLost === value) {
         return;
      }

      this._logger.info(`ESS connection changed. IsConnected: ${!value}`);

      this._isEventStreamConnectionLost = value;
      
      if (value) {

         this._logger.info('ESS connection lost. Starting 20 sec. countdown to end the session');

         this._essDisconnectTimeout = setTimeout(async () => {

            if (!this.isEventStreamConnectionLost) {
               return;
            }

            this._logger.warn('ESS connection lost for more than 20 seconds. Forcibly ending ession');

            try {

               const authToken = this._sessionService.authToken;
               
               this._messageBus.publish({
                  topic: 'AuthTokenExpiredUIMessage',
                  payload: {
                     doNotShowLogoutScreen: false,
                     source: 'LogoutButton'
                  }
               });
               
               if (authToken) {
                  const cmd = new DismissToken(authToken);
                  await this._authClient.processCommand(cmd);
               } else {
                  console.warn('Token was not dismissed at logout: empty token');
               }
   
            } catch (e) {
               
               console.error('Failed to logout', e);
   
            }
         }, 20 * 1000);

      } else {

         clearTimeout(this._essDisconnectTimeout);

      }
   }

   //

   isShellOffline = false;

   terminalSessionPnL: number;

   terminalAccumulatedPnL: number;

   selectedLayout: LayoutTab;

   groupExitDetails: {
      message?: string,
      command?: ProtocolCommand,
      isConfirmationVisible?: boolean
   } = {};

   
   isLayoutsSortDialogVisible: boolean;

   quoteCounter: string;

   ngOnInit(): void {

      this.initSecurityContext();

      const layoutConfiguration = this._userSettingsService
          .getItem<any[]>(EtsConstants.storageKeys.layoutRootKey) || [];

      if (layoutConfiguration) {

         try {

            const lts: LayoutTab[] = layoutConfiguration.map(x => JSON.parse(x) as LayoutTab);
            lts.forEach(x => this.restoreParents(x.layoutRoot));
            this.layouts = lts;

         } catch (e) {

            this._userSettingsService.removeItem(EtsConstants.storageKeys.layoutRootKey);
            const data = { error: e.message, stack: e.stack };
            this._logger.error('Failed to restore layout', data);
            this._toastr.error('Failed to restore layout');

         } finally {
            
            //

         }
         
      }

      this.isEventStreamConnectionLost = true;

      this.subscribeMessages();

      const lastMessage = this._messageBus.getLastMessage<TerminalExposureChangedUIMessage>('TerminalExposureChangedUIMessage');
      
      if (lastMessage) {
         this.onTerminalExposureChanged(lastMessage.payload);
      }

      this._messageBus.of<EtsLinkClickedMessage>('EtsLinkClickedMessage')
          .subscribe((msg) => this.onEtsLinkClicked(msg.payload));
   }


   ngOnDestroy(): void {
      this.unsubscribeMessages();
   }


   @DetectMethodChanges()
   async ngAfterViewInit() {
      
      this._changeDetector.detach();

      try {
         await this._shellServiceClient.processCommand(new RequestLoginIssuesReport());
      } catch {
         this._toastr.error('Logon Report Request completed with errors');
      }

      setInterval(() => playSound(), 3 * 1000);
   }


   @DetectMethodChanges()
   onLayoutSelected(event: LayoutTab) {
      if (!event.isInitialized) {
         this.refreshTabLayoutOnInitialization(event.layoutRoot);
         event.isInitialized = true;
      }
      this.layouts.forEach(lt => lt.isActive = false);
      event.isActive = true;
      this.selectedLayout = event;
      this.onLayoutChanged();
   }


   @DetectMethodChanges()
   onCloseTabRequest(event: LayoutTab) {
      const ix = this.layouts.indexOf(event);
      if (ix >= 0) {
         this._messageBus.publish({
            topic: 'LayoutTabClosed',
            payload: {
               layoutTabId: event.id
            },
            scopeId: event.id
         });
         this.layouts.splice(ix, 1);
      }
      if (this.layouts.length > 0) {
         this.onLayoutSelected(this.layouts[this.layouts.length - 1]);
      } else {
         this.selectedLayout = null;
      }

      this.onLayoutChanged();
   }


   @DetectMethodChanges()
   onAddTabRequest() {
      let layoutTab: LayoutTab;
      const hasRootLayout = !isVoid(this._userSettingsService.getItem(EtsConstants.storageKeys.layoutRootKey));
      if (!hasRootLayout) {
         layoutTab = new LayoutTab('Default', true);
         this.layouts.push(layoutTab);
      } else {
         layoutTab = new LayoutTab();
         this.layouts.forEach(x => x.isActive = false);
         this.layouts.push(layoutTab);
      }
      layoutTab.isActive = true;
      this.selectedLayout = layoutTab;
      this.onLayoutChanged();
   }


   onLayoutChanged() {
      setTimeout(() => {
         const layoutConfiguration = this.layouts.map(x => {
            const str = JSON.stringify(x, (k, v) => {
               if (k === 'parent' || k === 'isInitialized' || k === 'instance') {
                  return null;
               }
               return v;
            });
            return str;
         });
         
         if (layoutConfiguration) {
            this._userSettingsService.setItem(EtsConstants.storageKeys.layoutRootKey, layoutConfiguration);
         }            
      }, 1);
   }


   @DetectMethodChanges()
   onMasterExitPressed() {
      const cmd = new MasterExit();
      this.groupExitDetails.command = cmd;
      this.groupExitDetails.isConfirmationVisible = true;
      this.groupExitDetails.message = 'This will close all open positions on shell. Do you confirm?';
   }


   @DetectMethodChanges()
   onTerminalExitRequestPressed(term: TerminalDto) {
      const cmd = new CloseTerminalPositions(
         term.terminalId
      );
      this.groupExitDetails.command = cmd;
      this.groupExitDetails.isConfirmationVisible = true;
      this.groupExitDetails.message = `This will close all open positions on terminal "${term.displayName}". Do you confirm?`;
   }


   @DetectMethodChanges()
   onSortLayoutsRequest() {
      
      if (this.layouts.length <= 1) {
         return;
      }

      this.isLayoutsSortDialogVisible = !this.isLayoutsSortDialogVisible;
   }


   @DetectMethodChanges()
   onSortLayoutsCompleted(ev: LayoutsSortEventArgs) {
      if (ev) {

         ev.layouts.forEach(layout => {
            const oldLayoutIndex = this.layouts.findIndex((lt) => lt.id === layout.layoutId);
            if (oldLayoutIndex < 0) {
               return;
            }
            const layoutToReplace = this.layouts[layout.newIx];
            const layoutToSet = this.layouts[oldLayoutIndex];
            this.layouts[layout.newIx] = layoutToSet;
            this.layouts[oldLayoutIndex] = layoutToReplace;
         });

         this.onLayoutChanged();
      }

      this.isLayoutsSortDialogVisible = false;
   }


   @DetectMethodChanges()
   toggleTradingPad() {
      this.isTradingPadVisible = !this.isTradingPadVisible;
   }


   private unsubscribeMessages() {
      if (this._unsubscriber) {
         if (!this._unsubscriber) {
            this._unsubscriber.next();
            this._unsubscriber.complete();
         }
      }
   }


   @DetectMethodChanges()
   private onTerminalExposureChanged(message: TerminalExposureChangedUIMessage): void {
      
      if (!message.isOwnTerminal) {
         return;
      }

      this.terminalAccumulatedPnL = message.accumulatedTerminalTotal;
      this.terminalSessionPnL = message.sessionTerminalTotal;
   }


   @DetectMethodChanges()
   private refreshTabLayoutOnInitialization(layoutRoot: ILayoutSection) {
      if (!layoutRoot.subsections) {
         layoutRoot.size += 0.000000001;
         return;
      }

      layoutRoot.subsections.forEach(x => {
         x.size = x.size + 0.0000000001;
         if (x.subsections) {
            x.subsections.forEach(y => {
               this.refreshTabLayoutOnInitialization(y);
            });
         }
      });
   }

   @DetectMethodChanges()
   private restoreParents(layout: ILayoutSection) {
      if (layout.subsections) {
         for (const x of layout.subsections) {
            x.parent = layout;
            if (x.subsections) {
               for (const y of x.subsections) {
                  this.restoreParents(x);
               }
            }
         }
      }
   }

   
   @DetectMethodChanges()
   async confirmGroupExit(response: 'yes' | 'no') {
      if (response === 'no') {
         this.groupExitDetails = {};

         // this._changeDetector.detectChanges();

         return;
      }

      if (!this.groupExitDetails.command) {
         this._toastr.error('Group Exit Command is Not Defined');
         this.groupExitDetails = {};

         // this._changeDetector.detectChanges();

         return;
      }

      try {
         
         await this._shellServiceClient.processCommand(this.groupExitDetails.command);

      } catch (e) {
         
         const data = { command: this.groupExitDetails.command, error: (e.stack || e) };
         this._logger.error('Group exit failed', data);
         this._toastr.error('"Group Exit" command completed with errors');

      } finally {

         this.groupExitDetails = {};
         
      }
   }

   
   private initSecurityContext(): void {
      const isAvailable =
         (id) => this._accessControlService.isSecureElementAvailable(id);

      this.securityContext = {
         currentMarketPrices: isAvailable('465289b5-6930-41e1-8d57-5bd8d0b2ff9f'),
         accumulatedPnL: isAvailable('c387dcb2-913f-4877-8c12-c667e9d0502f'),
         gatewaysManager: isAvailable('f8c8f4b0-6256-4ed3-b5f6-3dcdab11db14'),
         masterExit: isAvailable('d23f2758-4c51-4750-a353-7007f48d9580'),
         menuButton: isAvailable('495f03af-a9d2-4a9f-a461-d4af6517fc3e'),
         sessionPnL: isAvailable('9248099c-6413-47cc-b889-aaea2206f6ad'),
         terminalExit: isAvailable('d3221ada-5b08-40f3-b357-d57ade528c86'),
         tradeButton: isAvailable('e430fec1-40d8-4f8c-aeee-ed61602e6088'),
         serverTimePanel: isAvailable('2d4317e0-f12a-4ec1-987a-0507ea5a56e9'),
         sessionMessages: isAvailable('9fba9be6-f3be-4622-910d-849041f80389'),
         totalStrategiesIssues: isAvailable('36e75f05-8df8-41a6-a52d-691ed89a8e97'),
         unaccountedData: isAvailable('600e90cf-e5c7-4448-9727-78744e0456dd'),
         jobsTracker: isAvailable('5aab5448-8f9b-40f4-b41d-a717bcd6781f')
      };
   }
   
   private subscribeMessages() {
      /* subscribe for event-stream events */
      this.unsubscribeMessages();

      this._unsubscriber = new Subject<any>();

      this._messageBus
          .of<InitialWorkspaceInitializedUIMessage>('InitialWorkspaceInitializedUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => {
            if (this.layouts.length > 0) {
               const layoutTab = this.layouts[0];
               const layoutRoot = layoutTab.layoutRoot;
               if (layoutRoot.subsections.length > 0) {
                  const defaultSection = layoutRoot.subsections[0];
                  defaultSection.isInitial = false;
               }
            }
            this.onLayoutChanged();
         });

      this._messageBus
         .of<EventStreamConnectionChangedUIMessage>(
            'EventStreamConnectionChangedUIMessage'
         )
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => {
            this.isEventStreamConnectionLost = !x.payload.isConnected;
            this._changeDetector.detectChanges();
         });

      this._messageBus
         .of<ShellStatusPollingUIMessage>('ShellStatusPollingUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => {
            this.isShellOffline = !x.payload.isOnline;
            this._changeDetector.detectChanges();
         });

      this._messageBus
         .of<TerminalExposureChangedUIMessage>('TerminalExposureChangedUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(message => this.onTerminalExposureChanged(message.payload));


      this._messageBus.of<ServerTimeMessageDto>('ServerTimeMessageDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(() => {
            if (this.isEventStreamConnectionLost) {
               this.isEventStreamConnectionLost = false;
            }
            this._changeDetector.detectChanges();
         });

      this._messageBus.of<QuoteLimitDto>('QuoteLimitDto')
          .pipe(takeUntil(this._unsubscriber))
          .subscribe(msg => {
             this.quoteCounter = msg.payload.count;
             this._changeDetector.detectChanges();
          });
   }

   private onEtsLinkClicked(payload: EtsLinkClickedMessage) {
      if (payload.address.indexOf('normalizing-calculator') === -1) {
         return;
      }
      this.normalizingCalculator.show();
   }

   @DetectMethodChanges()
   async getPivots() {

      const qry = new GetRecentPivots(
          'SPX',
          '5 min',
          'Up',
          'Up',
          'Close'
      );

      const result = await this._shellServiceClient.processQuery<GetRecentPivotsReply>(qry);

      console.log(result);
   }
}

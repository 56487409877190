import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DetectMethodChanges, DxValueChanged, isValidNumber, isVoid, makeGuiFriendlyExpirationDate} from "../utils";
import {ToastrService} from "ngx-toastr";
import {SpreadLegWrapper} from "./spread-leg.wrapper";
import {OrdersChainDialogConfig} from "./orders-chain-dialog.config";
import {SolutionOrderLegDto} from "../adjustment-pricing-grid/model/SolutionOrderLegDto";

export interface ChainedOrderLeg {
    underlying: string;
    action?: 'Buy' | 'Sell';
    qty?: number;
    expiration?: string;
    type?: string;
    strike?: number;
}

export class ChainedOrder {
    title: string;
    legs: ChainedOrderLeg[];
}


function changeType(oType) {
    if (oType === 'Call') {
        return 'Put'
    }
    if (oType === 'Put') {
        return 'Call'
    }
}

@Component({
    selector: 'ets-orders-chain-dialog',
    templateUrl: 'orders-chain-dialog.component.html',
    styleUrls: ['orders-chain-dialog.component.scss']
})

export class OrdersChainDialogComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _toastr: ToastrService
    ) {
    }

    private _config: OrdersChainDialogConfig;

    private _onDialogFinished: (value?: (PromiseLike<void> | void)) => void;

    isLoading = false;

    visible: boolean;

    limitPrice: number;

    theSpread: SpreadLegWrapper[];

    get spreadName() : string {
        return this._config?.groupName;
    }

    get spreadColor(): string {
        return this._config?.groupColor;
    }

    chainedOrders: ChainedOrder[] = [];

    amountToHarvest: number;

    harvestStep: number;

    qtyOfOrders: number;

    boxMode = false;

    get stepAction() : 'Harvest' | 'Box' {
        return this.boxMode ? 'Box' : 'Harvest';
    }

    ngOnInit() {
    }

    @DetectMethodChanges()
    show(config: OrdersChainDialogConfig) : Promise<void> {
        this._config = config;

        const commonQty = Math.min(...config.legs.map(x => x.absQty));

        this.theSpread = config.legs.map(x => new SpreadLegWrapper(x, commonQty));

        const strikes = this.theSpread.map(x => x.strike);

        const distance = this.getBaseDistance(strikes);

        this.amountToHarvest = this.theSpread.length > 1 ? distance : null;

        this.visible = true;

        return new Promise<void>((res, rej) => {
            this._onDialogFinished = res;
        });
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;

        this.theSpread = [];
        this.chainedOrders = [];
        this.harvestStep = undefined;
        this.amountToHarvest = undefined;
        this.qtyOfOrders = undefined;
        this.boxMode = false;

        this._config = null;

        this._onDialogFinished();
    }

    get canCopyOrders() : boolean {
        return !isVoid(this.chainedOrders);
    }

    async copyAllOrders() {

        if (this.chainedOrders.length === 0) {
            this._toastr.error("No orders to copy");
            return;
        }

        if (isValidNumber(this.limitPrice, true)) {
            if (this.limitPrice <= 0) {
                this._toastr.error("Bad Limit Price");
                return;
            }
        }

        const service = this._config.copyOrdersService;

        const listOfOrders = this.chainedOrders.map( x => {
            const orders = x.legs.map(leg => {
                const order : Partial<SolutionOrderLegDto> = {
                    underlying: leg.underlying,
                    qty : leg.qty,
                    expiration :  makeGuiFriendlyExpirationDate(leg.expiration),
                    strike : leg.strike + '',
                    type: leg.type as any,
                    price: 0.01
                };
                return order;
            });

            return orders;
        });

        await service.copyToTOSDesktopHarvest(listOfOrders, Math.abs(this.limitPrice));

        this._toastr.success('Orders Copied To Clipboard');
    }

    @DetectMethodChanges()
    onApplyClicked() {

        this.chainedOrders = [];

        const error = this.validate();

        if (!isVoid(error)) {
            this._toastr.error(error);
            return;
        }

        this.isLoading = true;

        if (this._config.legs.length > 1) {
            setTimeout(() => this.harvestSpread(), 250);
        } else {
            setTimeout(() => this.harvestLongOption(), 250);
        }
    }

    private harvestLongOption() {
        const longLeg = this.theSpread.find(x => x.qty > 0);
        const direction = longLeg.type === 'Call' ? 1 : -1;
        let groundStrike = longLeg.strike;

        const qty = Math.abs(this.theSpread[0].qty);

        const expiration = this.theSpread[0].expiration;

        const type = this.theSpread[0].type;

        const underlying = this.theSpread[0].underlying;

        const numOfOrders = this.amountToHarvest / this.harvestStep;

        for (let i = 1; i <= numOfOrders; i++) {

            const co: ChainedOrder = {
                title: `Linked #${i - 1}`,
                legs: []
            };

            if (i === 1) {
                co.title = 'Main Order';
            }

            this.chainedOrders.push(co);

            const destStrike = groundStrike + this.harvestStep * direction;

            const leg1: ChainedOrderLeg = {
                strike: groundStrike,
                action: 'Sell',
                qty: -qty,
                type: type,
                expiration: expiration,
                underlying: underlying
            };

            const leg2: ChainedOrderLeg = {
                strike: destStrike,
                action: 'Buy',
                qty: qty,
                type: type,
                expiration: expiration,
                underlying: underlying
            };

            co.legs.push(leg1);
            co.legs.push(leg2);

            if (this.boxMode) {
                co.legs.forEach(l => l.type = changeType(l.type));
            }

            groundStrike = destStrike;
        }

        this.isLoading = false;

        this._changeDetector.detectChanges();

    }

    private harvestSpread() {
        const longLeg = this.theSpread.find(x => x.qty > 0);
        const shortLeg = this.theSpread.find(x => x.qty < 0);
        const direction = Math.sign(shortLeg.strike - longLeg.strike);

        let groundStrike = longLeg.strike;

        const qty = Math.abs(this.theSpread[0].qty);

        const expiration = this.theSpread[0].expiration;

        const type = this.theSpread[0].type;

        const underlying = this.theSpread[0].underlying;

        const numOfOrders = this.amountToHarvest / this.harvestStep;

        for (let i = 1; i <= numOfOrders; i++) {

            const co: ChainedOrder = {
                title: `Linked #${i - 1}`,
                legs: []
            };

            if (i === 1) {
                co.title = 'Main Order';
            }

            this.chainedOrders.push(co);

            const destStrike = groundStrike + this.harvestStep * direction;

            const leg1: ChainedOrderLeg = {
                strike: groundStrike,
                action: 'Sell',
                qty: -qty,
                type: type,
                expiration: expiration,
                underlying: underlying
            };

            const leg2: ChainedOrderLeg = {
                strike: destStrike,
                action: 'Buy',
                qty: qty,
                type: type,
                expiration: expiration,
                underlying: underlying
            };

            co.legs.push(leg1);
            co.legs.push(leg2);

            if (this.boxMode) {
                co.legs.forEach(l => l.type = changeType(l.type));
            }

            groundStrike = destStrike;
        }

        this.isLoading = false;

        this._changeDetector.detectChanges();

    }

    getClassForAction(action: 'Buy' | 'Sell') {
        if (action === 'Buy') return 'buy';
        if (action === 'Sell') return 'sell';
    }

    private validate() : string {

        if (!isValidNumber(this.amountToHarvest, true) || this.amountToHarvest < 0) {
            return 'Amount To Harvest Must Be A Positive Value';
        }

        if (!isValidNumber(this.harvestStep, true) || this.harvestStep < 0) {
            return 'Harvest Step Must Be A Positive Value';
        }

        const numbers = this.theSpread.map(x => x.strike);
        const diff = Math.abs(numbers[0] - numbers[1]);

        if (diff < this.amountToHarvest) {
            return 'Harvest Amount is Bigger Then The Spread Width';
        }

        const remainder = this.amountToHarvest % this.harvestStep;

        if (remainder !== 0) {
            return 'Harvest Amount Must Be A Multiple Of Harvest Step';
        }

        const tick = this.theSpread[0].underlying === 'SPX'  ? 5: 1;
        const underlying = this.theSpread[0].underlying;
        const stepRemainder = this.harvestStep % tick;
        if (stepRemainder !== 0) {
            return `Harvest Step Must Be A Multiple of $${tick}`;
        }
    }

    private getBaseDistance(strikes: number[]) : number {
        const distance = strikes.reduce((pv, cv) => cv - pv, 0);
        return Math.abs(distance);
    }

    @DetectMethodChanges()
    updateQtyOfOrders() {

        const remainder = this.amountToHarvest % this.harvestStep;

        if (remainder !== 0) {
            this.qtyOfOrders  = null;
            return;
        }

        const qty = this.amountToHarvest / this.harvestStep;

        if (!isValidNumber(qty, true)) {
            this.qtyOfOrders = null;
            return;
        }



        this.qtyOfOrders = qty;
    }

    get canApply() : boolean {
        return true;
    }


    onAmountToHarvestChanged(ev: DxValueChanged<any>) {
        if (isVoid(ev.event)) {
            return;
        }

        this.updateQtyOfOrders();
    }

    onHarvestStepChanged(ev: DxValueChanged<any>) {
        if (isVoid(ev.event)) {
            return;
        }

        this.updateQtyOfOrders();
    }

    @DetectMethodChanges()
    onBoxModeChanged(args) {
        this.chainedOrders = [];

        if (args && args.event) {
            this._toastr.info(`Mode Changed to "${this.boxMode ? 'Box' : 'Harvest'}"`);
        }

    }
}
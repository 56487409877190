import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {IAfterGuiAttachedParams, ICellRendererParams} from 'ag-grid-community';
import {MessageBusService} from "../../message-bus.service";
import {LastQuoteCacheService} from "../../last-quote-cache.service";
import {
    OpgCellSelectedMessage,
    OpgExpirationChangeMessage,
    OptionsPricingGridComponentBase
} from "../options-pricing-grid-base.component";
import {PricingGridRow} from "../model/pricing-grid.row";
import {DetectMethodChanges, isValidNumber, isVoid, makeDayOfWeekDate} from "../../utils";
import {makeOptionTicker, OptionType, parseOptionTicker} from "../../options-common/options.model";
import {QuoteDto} from "../../shell-communication/dtos/quote-dto.class";
import {defaultPriceCellFormatterWithDollarSign} from "../../ag-grid-contrib";
import {filter} from "rxjs/operators";
import {PricingGridStrategyColumn} from "../model/pricing-grid-strategy.column";
import {PricingGridLeg} from "../model/pricing-grid.leg";
import {MarketSide} from "../../trading-model/market-side.enum";
import {Subscription} from "rxjs";
import {HedgePosition} from "../../hedging-grid/data-model/hedge-position";
import {matches} from "linq";

interface IPriceCellRendererParams {
    component: OptionsPricingGridComponentBase;
    optionType: 'Call' | 'Put';
    pmdColumn: 'own' | 'trans'
}

function getColor(leg: PricingGridLeg) {
    return leg.side === MarketSide.Buy ? '#00007c' : 'darkred';
}

@Component({
    selector: 'opg-price-column-cell-renderer',
    templateUrl: 'opg-price-column-cell-renderer.component.html',
    styleUrls: ['opg-price-column-cell-renderer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OpgPriceColumnCellRendererComponent implements ICellRendererAngularComp {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _messageBus: MessageBusService,
        private readonly _lastQuoteCache: LastQuoteCacheService
    ) {
    }

    private _params: (ICellRendererParams & IPriceCellRendererParams);

    private _currentTicker: string;
    private _calendarizedTicker: string;
    private _legColor: string;
    private readonly _subscriptions: Subscription[] = [];

    get ticker(): string {
        return this.calendarized ? this._calendarizedTicker : this._currentTicker;
    }

    price: string;

    qty: number;

    get backgroundColor(): string | undefined {
        if (this.isInRange && isVoid(this._legColor)) {
            return 'lemonchiffon';
        }
        return this._legColor;
    }

    get foregroundColor(): string | undefined {
        if (this.isInRange && isVoid(this._legColor)) {
            return 'black';
        }
        return undefined;
    }

    get isPmdColumn(): boolean {
        return !isVoid(this._params.pmdColumn);
    }


    calendarized = false;

    dow: string;

    isInRange: boolean;

    @DetectMethodChanges()
    refresh(params: any): boolean {
        this.onQuote();
        return true;
    }

    @DetectMethodChanges()
    agInit(params: (ICellRendererParams & IPriceCellRendererParams)): void {
        this._params = params;

        this._subscriptions.push(
            this._messageBus.of<QuoteDto[]>('QuoteDto')
                .subscribe(msg => this.onQuote())
        );

        this._subscriptions.push(
            this._messageBus.of<OpgExpirationChangeMessage>('Opg.ExpirationChanged')
                .pipe(
                    filter(msg =>
                        msg.payload.component === this._params.component
                    )
                )
                .subscribe(msg => this.onExpirationChange(msg.payload))
        );

        this._subscriptions.push(
            this._messageBus.of<OpgCellSelectedMessage>('Opg.CellSelected')
                .pipe(
                    filter(msg =>
                        msg.payload.component === this._params.component
                    )
                )
                .subscribe(msg => this.onCellSelected())
        );

        this._subscriptions.push(
            this._messageBus.of<any>('Opg.TargetRangeChanged')
                .pipe(
                    filter(msg =>
                        msg.payload.component === this._params.component
                    )
                )
                .subscribe(msg => this.onRangeHighlightChanged())
        );

        this._subscriptions.push(
            this._messageBus.of<any>('Opg.StrategyApplied')
                .pipe(
                    filter(msg =>
                        msg.payload.component === this._params.component
                    )
                ).subscribe(msg => this.onStrategyApplied())
        );

        this._subscriptions.push(
            this._messageBus.of<any>('Pmd.HedgeSelected')
                .pipe(
                    filter(msg =>
                        msg.payload.component === this._params.component
                    )
                ).subscribe(msg => {
                    // this.highlightOwnLegs();
                    this.onCellSelected();
            })
        )

        this._currentTicker = this.makeCellTicker(params);
        this._lastQuoteCache.subscribeIfNotYet(this._currentTicker);

        this.onQuote();
        this.onRangeHighlightChanged();
        this.onCellSelected();
    }


    @DetectMethodChanges()
    private onStrategyApplied() {
        this._legColor = undefined;
        this.qty = undefined;
        this.onCellSelected();
        this._changeDetector.detectChanges();
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
        //
    }

    private makeCellTicker(params: ICellRendererParams & IPriceCellRendererParams): string {
        const rowData = this._params.data as PricingGridRow;

        let exp = this._params.component.selectedExpiration;

        const strike = rowData.rowStrike;

        const optionType = this._params.optionType;

        const ticker = makeOptionTicker(exp, optionType, strike);

        return ticker;
    }

    @DetectMethodChanges()
    private onQuote() {

        if (isVoid(this.ticker)) {
            this.price = undefined;
            return;
        }

        const mid = this._lastQuoteCache.getLastQuote(this.ticker)?.mid;

        if (!isValidNumber(mid, true)) {
            return;
        }

        this.price = defaultPriceCellFormatterWithDollarSign({value: mid} as any);
    }

    @DetectMethodChanges()
    private onExpirationChange(payload: OpgExpirationChangeMessage) {
        if (!isVoid(this._currentTicker)) {
            this._lastQuoteCache.unsubscribeTicker(this._currentTicker);
        }

        if (!isVoid(this._calendarizedTicker)) {
            this._lastQuoteCache.unsubscribeTicker(this._calendarizedTicker);
        }


        this.price = undefined;

        this._currentTicker = this.makeCellTicker(this._params);

        this._lastQuoteCache.subscribeIfNotYet(this._currentTicker);
    }

    @DetectMethodChanges()
    private onCellSelected(): void {

        this._legColor = undefined;
        this.qty = undefined;
        this.calendarized = false;
        this._calendarizedTicker = undefined;
        this.dow = undefined;

        if (this._params.pmdColumn === 'own') {
            this.highlightOwnLegs();
            return;
        }

        const theCell = this._params.component.selectedCell;

        if (isVoid(theCell)) {
            return;
        }

        const callOrPutCell = theCell.colDef.field.indexOf('-call') >= 0
            ? 'Call'
            : 'Put';

        if (callOrPutCell !== this._params.optionType) {
            return;
        }

        const cellRow = theCell.data as PricingGridRow;

        const objAttribute = theCell.colDef.field.split('.')[0];

        const column = cellRow[objAttribute] as PricingGridStrategyColumn;

        if (!column) {
            return;
        }

        if (!this.isPmdColumn) {
            this.highlightOutcomeLegs(column);
        } else {
            if (this._params.pmdColumn === 'trans') {
                this.highlightTransLegs(column);
            } else if (this._params.pmdColumn === 'own') {
                this.highlightOwnLegs();
            }
        }
    }

    private highlightOutcomeLegs(column: PricingGridStrategyColumn) {
        const matchingPriceOutcomeLeg = column.legs
            .find(x => x.strike === this._params.data.rowStrike);

        if (isVoid(matchingPriceOutcomeLeg)) {
            return;
        }

        this.setCalendarizedState(matchingPriceOutcomeLeg.ticker);

        const legColor = getColor(matchingPriceOutcomeLeg);

        let sign = 1;

        if (isValidNumber(matchingPriceOutcomeLeg.qty, true)) {
            if (!isVoid(matchingPriceOutcomeLeg.side)) {
                sign = matchingPriceOutcomeLeg.side === MarketSide.Buy ? 1 : -1;
            }
        }

        this._legColor = legColor;
        this.qty = matchingPriceOutcomeLeg.qty * sign;
    }

    @DetectMethodChanges()
    private onRangeHighlightChanged() {

        if (this.isPmdColumn) {
            return;
        }

        if (this._params.component.isCustomRange) {
            this.highlightCustomTargetRange();
        } else {
            this.highlightAutoTargetRange();
        }
    }

    private highlightCustomTargetRange() {
        const component = this._params.component;
        const rowStrike = this._params.data.rowStrike;

        if (this._params.optionType === 'Call') {
            const callsStart = component.callsTargetRangeStart;
            const callsEnd = component.callsTargetRangeEnd;

            if (!isVoid(callsStart) && !isVoid(callsEnd)) {
                this.isInRange =
                    rowStrike >= Math.min(callsStart, callsEnd)
                    && rowStrike <= Math.max(callsStart, callsEnd);
            }
        } else {
            const putsStart = component.putsTargetRangeStart;
            const putsEnd = component.putsTargetRangeEnd;

            if (!isVoid(putsStart) && !isVoid(putsEnd)) {
                this.isInRange =
                    rowStrike >= Math.min(putsStart, putsEnd)
                    && rowStrike <= Math.max(putsStart, putsEnd);
            }
        }
    }

    private highlightAutoTargetRange() {
        const component = this._params.component;
        const rowStrike = this._params.data.rowStrike;


        const atmStrike = component.atmStrike || NaN;


        let colorPut = false;
        let colorCall = false;


        if (this._params.optionType === 'Call') {

            const callOffset = component.callOffset || NaN;
            const callRange = component.callRange || NaN;
            const callsStart = atmStrike + callOffset;
            const callsEnd = callsStart + callRange;

            if (
                isValidNumber(callsStart, true)
                && isValidNumber(callsEnd, true)
            ) {
                this.isInRange =
                    rowStrike >= Math.min(callsStart, callsEnd)
                    && rowStrike <= Math.max(callsStart, callsEnd);
            }
        }

        if (this._params.optionType === 'Put') {
            const putOffset = component.putOffset || NaN;
            const putRange = component.putRange || NaN;
            const putsStart = atmStrike - putOffset;
            const putsEnd = putsStart - putRange;

            if (
                isValidNumber(putsStart, true)
                && isValidNumber(putsEnd, true)
            ) {
                this.isInRange =
                    rowStrike >= Math.min(putsStart, putsEnd)
                    && rowStrike <= Math.max(putsStart, putsEnd);
            }
        }
    }

    ngOnDestroy() {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private highlightTransLegs(column: PricingGridStrategyColumn) {
        if (!this.isPmdColumn) {
            return;
        }

        if (this._params.pmdColumn !== 'trans') {
            return;
        }

        this._legColor = undefined;
        this.qty = undefined;
        this.calendarized = false;
        this._calendarizedTicker = undefined;
        this.dow = undefined;

        const legs = column.transLegs;


        const matchingPriceOutcomeLeg = legs
            .find(x => x.strike === this._params.data.rowStrike);

        if (isVoid(matchingPriceOutcomeLeg)) {
            return;
        }

        this.setCalendarizedState(matchingPriceOutcomeLeg.ticker);

        const legColor = getColor(matchingPriceOutcomeLeg);

        let sign = 1;

        if (isValidNumber(matchingPriceOutcomeLeg.qty, true)) {
            if (!isVoid(matchingPriceOutcomeLeg.side)) {
                sign = matchingPriceOutcomeLeg.side === MarketSide.Buy ? 1 : -1;
            }
        }

        this._legColor = legColor;
        this.qty = matchingPriceOutcomeLeg.qty * sign;
    }

    private highlightOwnLegs() {

        if (!this.isPmdColumn) {
            return;
        }

        if (this._params.pmdColumn !== 'own') {
            return;
        }

        this._legColor = undefined;
        this.qty = undefined;
        this.calendarized = false;
        this._calendarizedTicker = undefined;
        this.dow = undefined;

        const component = this._params.component;
        const type = this._params.optionType === 'Call' ? OptionType.Call : OptionType.Put;
        const targetSide = this._params.optionType === 'Call' ? 'calls' : 'puts';

        let hedges: HedgePosition[] = [];

        if (this._params.component.pmdHedgeMode === 'existing') {
            hedges = component.getExistingHedges(type).filter(x => !isVoid(x));
        } else if (component.pmdHedgeMode === 'custom') {
            hedges = component
                .getCustomLegs(targetSide).map(x => x.asHedgePosition())
                .filter(x => !isVoid(x));
        }

        const currentState = hedges
            .map(hp => {
                return {
                    ticker: hp.ticker,
                    qty: hp.qty
                }
            });

        const ownLegs = currentState.map(x => {
            const optionTicker = parseOptionTicker(x.ticker);
            return {
                ticker: x.ticker,
                qty: Math.abs(x.qty),
                side: x.qty > 0 ? MarketSide.Buy : MarketSide.Sell,
                strike: optionTicker?.strike
            } as PricingGridLeg
        });


        const matchingPriceOutcomeLeg = ownLegs
            .find(x => x.strike === this._params.data.rowStrike);

        if (isVoid(matchingPriceOutcomeLeg)) {
            return;
        }

        this.setCalendarizedState(matchingPriceOutcomeLeg.ticker);

        const legColor = getColor(matchingPriceOutcomeLeg);

        let sign = 1;

        if (isValidNumber(matchingPriceOutcomeLeg.qty, true)) {
            if (!isVoid(matchingPriceOutcomeLeg.side)) {
                sign = matchingPriceOutcomeLeg.side === MarketSide.Buy ? 1 : -1;
            }
        }

        this._legColor = legColor;
        this.qty = matchingPriceOutcomeLeg.qty * sign;

    }

    private setCalendarizedState(ticker: string) {
        const optionExpirationDate = this._params.component.selectedExpiration?.optionExpirationDate;
        if (isVoid(optionExpirationDate)) {
            return false;
        }

        this.calendarized = ticker.indexOf(optionExpirationDate) < 0;

        if (!this.calendarized) {
            return;
        }

        const optionTicker = parseOptionTicker(ticker);

        if (!isVoid(optionTicker)) {
            this.dow = makeDayOfWeekDate(optionTicker.expiration);
            this.price = undefined;
            if (!isVoid(this._calendarizedTicker)) {
                this._lastQuoteCache.unsubscribeTicker(this._calendarizedTicker);
            }
            this._calendarizedTicker = optionTicker.ticker;
            this._lastQuoteCache.subscribeIfNotYet(this._calendarizedTicker);
        }
    }
}
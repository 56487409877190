import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {OpgTemplate, OptionPricingGridTemplatesService} from "../option-pricing-grid-templates.service";
import {DetectMethodChanges} from "../../utils";
import {SessionService} from "../../authentication/session-service.service";
import {UserDto} from "../../authentication/dtos/auth-result-dto.inteface";
import {ToastrService} from "ngx-toastr";
import {
    AssignOpgTemplates,
    ClientForOpgTemplate,
    GetOpgTemplatesAssignment, GetOpgTemplatesAssignmentReply,
    OpgTemplateToClientsMap
} from "../../shell-communication/shell-operations-protocol";
import {ShellClientService} from "../../shell-communication/shell-client.service";

class Client {
    constructor(private readonly _client: UserDto) {
    }

    get clientName(): string {
        return this._client.userName;
    }

    get clientId(): string {
        return this._client.userId;
    }

    selected = false;
}

class Template {
    constructor(
        private readonly _template: OpgTemplate,
        private readonly _clients: Client[]
    ) {
    }

    get templateName(): string {
        return this._template.templateName;
    }

    get templateId(): string {
        return this._template.templateId
    };

    get clients(): Client[] {
        return this._clients;
    }
}

@Component({
    selector: 'ets-opg-assign-templates',
    templateUrl: 'opg-assign-templates.component.html',
    styleUrls: ['opg-assign-templates.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OpgAssignTemplatesComponent implements OnInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _opgTemplatesService: OptionPricingGridTemplatesService,
        private readonly _sessionDataService: SessionService,
        private readonly _toastr: ToastrService,
        private readonly _shellService: ShellClientService
    ) {
    }

    visible: boolean;

    isLoading: boolean;

    templateList: Template[] = [];

    selectedTemplate: Template;

    side: 'Call' |'Put';

    ngOnInit() {
    }

    @DetectMethodChanges()
    async show(side: 'Call' | 'Put') {
        this.templateList = [];

        this.side = side;

        this.selectedTemplate = undefined;

        this.isLoading = true;

        this.visible = true;

        const qry = new GetOpgTemplatesAssignment();

        const reply = await this._shellService.processQuery<GetOpgTemplatesAssignmentReply>(qry);

        try {

            const templates = side === 'Call'
                ? this._opgTemplatesService.getCallTemplates()
                : this._opgTemplatesService.getPutTemplates();

            this.templateList = templates
                .filter(x => x.isAssignable)
                .map(x => {
                    const clients = this._sessionDataService.sessionData.users.map(x => new Client(x));
                    const template = new Template(x, clients);
                    return template;
                });

            this.templateList.forEach(tpl => {
                const assignmentsForTpl = reply.mappings.filter(x => x.templateId === tpl.templateId);

                if (assignmentsForTpl.length === 0) {
                    return;
                }

                tpl.clients.forEach(client => {
                    client.selected = assignmentsForTpl
                        .findIndex(x => x.userId === client.clientId) >= 0;
                });
            })

        } catch {
            this._toastr.error('"Load Assigned Templates" operation completed with errors');
        } finally {
            this.isLoading = false;
            this._changeDetector.detectChanges();
        }

    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
        this.templateList = [];
        this.selectedTemplate = undefined;
        this.side = undefined;

    }

    @DetectMethodChanges({isAsync: true})
    async saveChanges() {

        this.isLoading = true;

        try {

            const map = this.templateList.map(item => {
                const r: OpgTemplateToClientsMap = {
                    templateId: item.templateId,
                    templateName: item.templateName,
                    clients: item.clients.filter(x => x.selected).map(client => {
                        return {
                            clientId: client.clientId,
                            clientName: client.clientName,
                        } as ClientForOpgTemplate;
                    })
                };
                return r;
            });

            const cmd = new AssignOpgTemplates(this.side, map);

            await this._shellService.processCommand(cmd);

            this.visible = false;

        } finally {
            this.isLoading = false;
        }
    }

    cancelChanges() {
        this.onHidden();
    }

    @DetectMethodChanges()
    setSelectedTemplate(tpl: Template) {
        this.selectedTemplate = tpl;
    }
}
<dx-popup
  (onHidden)="this.onHidden()"
  [closeOnOutsideClick]="false"
  [dragEnabled]="true"
  [resizeEnabled]="true"
  [shading]="false"
  [showTitle]="true"
  [visible]="this.visible"
  (onResizeEnd)="this.onResizeEnd($event)"
  (onResize)="this.onResize($event)"
  [height]="this.height"
  title="Hedge Matrix Columns"
  [width]="this.width"
  id="hedge-matrix-column-selector"
  titleTemplate="title"
>
  <div *dxTemplate="let data of 'title'" style="background: black; padding: 5px 10px">
    <div class="window-title">
      <div>Hedge Matrix Columns</div>
      <div class="icons">
        <span class="expand-collapse icon">
          <i class="fas fa-compress-arrows-alt" *ngIf="!this.shortView" (click)="this.shrinkWindow()"></i>
          <i class="fas fa-expand-arrows-alt" *ngIf="shortView" (click)="this.expandWindow()"></i>
        </span>
        <span class="close icon">
          <i class="fas fa-times fa-lg" (click)="this.closeWindow()"></i>
        </span>
      </div>
    </div>
  </div>
  
  
  <div *dxTemplate="let data of 'content'">
    <div class="root">
      <dx-scroll-view>
        <div class="container">
          
          <div class="side left" *ngIf="this.isPricingSideVisible()">
            
            <div class="header">
              <span>Pricings</span>
              <span *ngIf="this.shortView" class="icon" (click)="this.switchView()"><i class="fas fa-exchange-alt"></i></span>
            </div>
            
            <div class="section">
              <dx-check-box (onValueChanged)="this.toggleSelectAllHedges($event)"
                            [value]="this.allHedgesSelected"
                            text="Select All Hedges">
              </dx-check-box>
            </div>
            
            <!-- Hedge Tpl -->
            <ng-template #hedge let-hedge>
              <div class="hedge-item">
                <div class="name">
                  <div class="checkbox">
                    <dx-check-box (onValueChanged)="this.onHedgeSelectionChanged($event, hedge)"
                                  [value]="this.isHedgeSelected(hedge)">
                    </dx-check-box>
                  </div>
                  <div class="label">
                    {{ hedge.label }} :: {{ hedge.expirationsString }}, {{ hedge.dayOfWeeksString }}
                  </div>
                </div>
                <div class="modification-toggle">
                  <div>Mods</div>
                  <div>
                    <dx-check-box
                      [value]="!hedge.turnOffModifications"
                      (onValueChanged)="this.onToggleModifications(hedge, $event.value)"
                    ></dx-check-box>
                  </div>
                </div>
              </div>
            </ng-template>
            
            <!-- Expiration Tpl -->
            <ng-template #expiration let-expiration>
              <div class="expiration-item">
                <div class="checkbox">
                  <dx-check-box (onValueChanged)="this.onExpirationSelectionChanged($event, expiration)"
                                [value]="this.isExpirationSelected(expiration)">
                  </dx-check-box>
                </div>
                <div class="label">
                  {{ expiration.dayOfWeekShort }}, {{ expiration.expirationFull }}
                </div>
              </div>
            </ng-template>
            
            <div class="section hedge">
              <div class="title">Call Hedges</div>
              <div class="selector">
                <ng-container *ngFor="let cHedge of this.callHedgeList">
                  <ng-container *ngTemplateOutlet="hedge; context: {$implicit: cHedge}"></ng-container>
                </ng-container>
              </div>
            </div>
            
            <div class="section hedge">
              <div class="title">Put Hedges</div>
              <div class="selector">
                <ng-container *ngFor="let cHedge of this.putHedgeList">
                  <ng-container *ngTemplateOutlet="hedge; context: {$implicit: cHedge}"></ng-container>
                </ng-container>
              </div>
            </div>
            
            <div class="row">
              <hr>
            </div>
            
            <div class="section">
              <dx-check-box (onValueChanged)="this.toggleSelectAllExpirations($event)"
                            [value]="this.allExpirationsSelected"
                            text="Select All Expirations">
              </dx-check-box>
            </div>
            
            <div class="section expiration">
              <div class="title">Call Expirations</div>
              <div class="selector">
                <ng-container *ngFor="let item of this.callExpirationList">
                  <ng-container *ngTemplateOutlet="expiration; context: {$implicit: item}">
                  </ng-container>
                </ng-container>
              </div>
            </div>
            
            <div class="section expiration">
              <div class="title">Put Expirations</div>
              <div class="selector">
                <ng-container *ngFor="let item of this.putExpirationList">
                  <ng-container *ngTemplateOutlet="expiration; context: {$implicit: item}">
                  </ng-container>
                </ng-container>
              </div>
            </div>
          
          </div>
          
          <div class="side right" *ngIf="this.isPnlSideVisible()">
            
            <div class="header">
              <span>Total P&L</span>
              <span *ngIf="this.shortView" class="icon" (click)="this.switchView()"><i class="fas fa-exchange-alt"></i></span>
            </div>
            
            <!-- Expiration Tpl -->
            <ng-template #pnlExpiration let-expiration>
              <div class="expiration-item">
                <div class="checkbox">
                  <dx-check-box
                    (onValueChanged)="this.onPnlExpirationSelectionChanged($event, expiration, false)"
                    [value]="this.isPnlExpirationSelected(expiration, false)">
                  </dx-check-box>
                </div>
                <div class="label">
                  {{ expiration.dayOfWeekShort }} {{ expiration.expirationFull }}
                </div>
                <div class="toggle">
                  <div class="label">
                    Show "All" Column
                  </div>
                  <div class="input">
                    <dx-switch
                      (onValueChanged)="this.onPnlExpirationSelectionChanged($event, expiration, true)"
                      [value]="this.isPnlExpirationSelected(expiration, true)"
                    ></dx-switch>
                  </div>
                </div>
              </div>
            </ng-template>
            
            <div class="section">
              <dx-check-box (onValueChanged)="this.toggleSelectAllPnlExpirations($event)"
                            [value]="this.allPnlExpirationsSelected"
                            text="Select All Expirations">
              </dx-check-box>
            </div>
            
            <div class="section expiration">
              <div class="title">Call Expirations</div>
              <div class="selector">
                <ng-container *ngFor="let item of this.callPnlExpirationList">
                  <ng-container *ngTemplateOutlet="pnlExpiration; context: {$implicit: item}">
                  </ng-container>
                </ng-container>
              </div>
            </div>
            
            <div class="section expiration">
              <div class="title">Put Expirations</div>
              <div class="selector">
                <ng-container *ngFor="let item of this.putPnlExpirationList">
                  <ng-container *ngTemplateOutlet="pnlExpiration; context: {$implicit: item}">
                  </ng-container>
                </ng-container>
              </div>
            </div>
            
            <div class="section expiration grand">
              <div class="title">Grand Totals</div>
              <div class="selector">
                <ng-container *ngFor="let item of this.expirationList">
                  <div class="expiration-item">
                    <div class="checkbox">
                      <dx-check-box
                        (onValueChanged)="this.onGrandTotalExpirationSelectionChanged($event, item)"
                        [value]="this.isGrandTotalExpirationSelected(item)">
                      </dx-check-box>
                    </div>
                    <div class="label">
                      {{ item.dayOfWeekShort}} {{ item.expirationFull }}
                    </div>
                    <div class="checkbox normalized">
                      <dx-check-box
                        text="Normalized"
                        [value]="this.isNormalizedExpirationPnlEnabled(item)"
                        (onValueChanged)="this.onNormalizedExpirationPnlToggled($event, item)"
                      ></dx-check-box>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          
          </div>
          
        </div>
      </dx-scroll-view>
    </div>
  </div>
</dx-popup>